import styled from 'styled-components';

export const TableWrapper = styled.div`
  --currentColor: #2e7d32;
  width: 90vw;
  max-width: 1360px;
  margin: 0 auto;
  border: 3px solid #332e2e;
  border-radius: 5px;
  overflow: auto;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  > table {
    width: 100%;
    background: #fff;
    border-collapse: collapse;

    td,
    th {
      padding: 10px 5px;
      text-align: center;
    }
    td:nth-child(3),
    th:nth-child(3) {
      text-align: left;
    }

    thead {
      padding: 10px 5px;
      tr {
        background: #332e2e;
        color: #fff;
      }
    }

    > tbody {
      > tr {
        background: #fff;
        &:nth-child(even) {
          background: #e6e6e6;
        }
      }

      > td {
        &:nth-child(1),
        &:nth-child(2) {
          width: 150px;
        }
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          width: 70px;
        }
      }
    }
  }
`;

export const InputContainer = styled.span`
  display: flex;
  column-gap: 15px;
  margin: 15px 0;

  p {
    font-weight: 600;
    color: #464646;
  }
`;
