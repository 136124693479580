import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface WrapperProps {
  isfocused: boolean;
  isfilled: boolean;
  haserror: boolean;
  altheight: number;
  labelpresent: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  position: relative;
  padding: 3px 7px;
  background: #f6f6f6;
  height: 25px;
  width: 350px;
  margin: 0;

  color: #c6c6c6;
  border: 2px solid #a6a6a6;
  border-radius: 5px;

  ${(props) =>
    props.labelpresent &&
    css`
      margin: 30px 0;
    `}

  label {
    color: #464646;
    font-weight: 700;
    position: absolute;
    top: -25px;
    left: 0;
  }

  height: ${(props) => props.altheight}px;

  & + div {
    margin-top: 8px;
  }

  ${(props) =>
    props.isfocused &&
    css`
      color: #332e2e;
      border-color: #332e2e;
    `}

  ${(props) =>
    props.isfilled &&
    css`
      color: #332e2e;
    `}

  ${(props) =>
    props.haserror &&
    css`
      border-color: #ff0000;
      background: #ffebee;
    `}


  textarea {
    resize: none;
    flex: 1;
    border: 0;
    background-color: transparent;
    color: #332e2e;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Roboto Slab', serif;

    height: 100%;
    width: 100%;

    &::placeholder {
      color: #c6c6c6;
    }

    &:disabled {
      color: #767676;
    }
  }

  > svg {
    margin-right: 16px;
    width: 20px;
    height: 20px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  svg {
    margin: 0;
  }

  span {
    /* background: #c53030; */
    color: #fff;

    &::before {
      /* border-color: #c53030 transparent; */
    }
  }
`;
