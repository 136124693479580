import React, { useCallback, useEffect, useState } from 'react';
import { useLocalStorage, useLoading } from 'hooks';
import { FormDateInput, FormInput, FormSelect } from 'components';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';

import { ComboProps } from 'interfaces';

import { lsKey } from '../../colaborator';
import ComponentWrapper from '../wrapper';
import { CloseButton } from '../role/styles';

interface PersonalProps {
  onDataChange: (data: any) => any;
  mode?: 'insert' | 'update' | 'show';
  data?: any;
}

function ColaboradorPersonal({ onDataChange, mode, data }: PersonalProps) {
  const { getLocalStorage } = useLocalStorage();
  const { toggleLoading } = useLoading();
  const [religions, setReligions] = useState<ComboProps[]>([]);
  const [maritalStatus, setMaritalStatus] = useState<ComboProps[]>([]);
  const [genders, setGenders] = useState<ComboProps[]>([]);
  const [ethnicities, setEthnicities] = useState<ComboProps[]>([]);
  const [sexualPreferences, setSexualPreferences] = useState<ComboProps[]>([]);
  const [prefixed, setPrefixed] = useState<any>({} as any);
  const navigate = useNavigate();
  const [infos, setInfos] = useState({} as any);

  const handleGetCombos = useCallback(async () => {
    toggleLoading();
    try {
      const response = await api.get('/combos/colaborador_dados_pessoais.php');
      setReligions(response.data.religion);
      setMaritalStatus(response.data.marital_status);
      setGenders(response.data.gender);
      setEthnicities(response.data.ethnicity);
      setSexualPreferences(response.data.sexual_preference);
    } catch (err) {
      console.error(err);
    } finally {
      toggleLoading();
    }
  }, []);

  useEffect(() => {
    handleGetCombos();
    const locallyStored: any = getLocalStorage(lsKey);
    if (Object.keys(data).length > 0) {
      if (mode === 'update') {
        setPrefixed({ ...locallyStored });
      } else {
        setPrefixed({ ...data });
      }
    } else {
      if (locallyStored) {
        setPrefixed({ ...locallyStored });
        setInfos({
          marital_status: locallyStored.marital_status || null,
          children: parseInt(locallyStored.children, 10) || 0,
        });
      }
    }
  }, [handleGetCombos, getLocalStorage]);

  const handleChange = useCallback(
    (ev) => {
      const { name } = ev.target.dataset;
      const obj = { ...infos } as any;
      switch (name) {
        case 'marital_status':
          obj.marital_status = ev.target.value;
          break;
        default:
          break;
      }
      setInfos((state) => ({ ...state, ...obj }));
      onDataChange(obj);
    },
    [infos],
  );

  const customStyle = { maxWidth: '300px' };

  const sairEVoltarParaHome = () => {
    navigate('/');
  };

  return (
    <ComponentWrapper sectionName="Dados Pessoais">
      <div>
        <FormInput
          name="name"
          placeholder="Nome"
          label="Nome"
          defaultValue={prefixed.name || null}
          containerstyle={customStyle}
          disabled={mode === 'show'}
        />
        <FormInput
          name="surname"
          placeholder="Sobrenome"
          label="Sobrenome"
          defaultValue={prefixed.surname || null}
          containerstyle={customStyle}
          disabled={mode === 'show'}
        />
        <FormDateInput
          name="birth"
          label="Data Nascimento"
          picked={prefixed.birth}
          containerstyle={customStyle}
          disabled={mode === 'show'}
        />
      </div>
      <div>
        <FormInput
          name="rg"
          placeholder="RG"
          label="RG"
          defaultValue={prefixed.rg || null}
          containerstyle={customStyle}
          disabled={mode === 'show'}
        />
        <FormInput
          name="org"
          placeholder="Orgão emissor"
          label="Orgão Emissor"
          defaultValue={prefixed.org || null}
          containerstyle={customStyle}
          disabled={mode === 'show'}
        />

        <FormDateInput
          name="rg_expedition"
          label="Data Emissão"
          picked={prefixed.rg_expedition}
          containerstyle={customStyle}
          disabled={mode === 'show'}
        />
        <FormInput
          name="cin"
          placeholder="CIN"
          label="CIN"
          defaultValue={prefixed.cin || null}
          containerstyle={customStyle}
          disabled={mode === 'show'}
        />
        {/* TODO: maybe an autocomplete on CIN */}
      </div>
      <div>
        <FormInput
          name="cpf"
          placeholder="N° CPF"
          label="CPF"
          mask="cpf"
          defaultValue={prefixed.cpf || null}
          containerstyle={customStyle}
          disabled={mode === 'show'}
        />
        <FormSelect
          name="religion"
          label="Religião"
          content={religions}
          initial={prefixed.religion || 'Selecione'}
          containerstyle={customStyle}
          disabled={mode === 'show'}
        />
        <FormSelect
          data-name="marital_status"
          name="marital_status"
          label="Estado Civil"
          content={maritalStatus}
          initial={prefixed.marital_status || 'Selecione'}
          onChange={handleChange}
          containerstyle={customStyle}
          disabled={mode === 'show'}
        />
      </div>
      <div>
        <FormInput
          name="nationality"
          placeholder="Naturalidade / UF"
          label="Naturalidade / UF"
          defaultValue={prefixed.nationality || null}
          containerstyle={customStyle}
          disabled={mode === 'show'}
        />
        {mode !== 'insert' ? (
          <FormInput
            data-name="children"
            name="children"
            type="number"
            mask="allowZero"
            placeholder="0"
            label="Filhos"
            defaultValue={prefixed.children || null}
            disabled
            containerstyle={customStyle}
          />
        ) : null}
      </div>
      <div>
        <FormSelect
          name="gender"
          label="Sexo"
          content={genders}
          initial={prefixed.gender || 'Selecione'}
          containerstyle={customStyle}
          disabled={mode === 'show'}
        />
        <FormSelect
          name="sexual_preference"
          label="Orientação Sexual"
          content={sexualPreferences}
          initial={prefixed.sexual_preference || 'Selecione'}
          containerstyle={customStyle}
          disabled={mode === 'show'}
        />
        <FormSelect
          name="ethnicity"
          label="Etnia"
          content={ethnicities}
          initial={prefixed.ethnicity || 'Selecione'}
          containerstyle={customStyle}
          disabled={mode === 'show'}
        />
        <FormInput
          name="phone"
          placeholder="N° telefone fixo"
          mask="phone"
          label="N° Telefone (Fixo)"
          defaultValue={prefixed.phone || null}
          containerstyle={customStyle}
          disabled={mode === 'show'}
        />
      </div>
      <div>
        <FormInput
          name="mail"
          placeholder="E-Mail"
          type="email"
          label="E-mail"
          defaultValue={prefixed.mail || null}
          containerstyle={customStyle}
          disabled={mode === 'show'}
        />
        <FormInput
          name="whatsapp"
          placeholder="WhatsApp"
          mask="phone"
          label="WhatsApp"
          defaultValue={prefixed.whatsapp || null}
          containerstyle={customStyle}
          disabled={mode === 'show'}
        />
        <FormInput
          name="mobile"
          placeholder="N° celular"
          mask="phone"
          label="N° Telefone (Celular)"
          defaultValue={prefixed.mobile || null}
          containerstyle={customStyle}
          disabled={mode === 'show'}
        />
      </div>
      <CloseButton onClick={sairEVoltarParaHome}>Sair sem salvar</CloseButton>
      {/* <button onClick={sairEVoltarParaHome}>Sair e Voltar para Home</button> */}
    </ComponentWrapper>
  );
}

ColaboradorPersonal.defaultProps = {
  mode: 'insert',
  data: {},
};

export default ColaboradorPersonal;
