import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Wrapper, Error, ViewPass } from './styles';

interface CustomProps {
  name: string;
  label?: string;
  containerstyle?: object;
  inputstyle?: object;
  ishidden?: boolean;
  disableerroricon?: boolean;
  picked?: string;
}

type InputProps = JSX.IntrinsicElements['input'] & CustomProps;

function FormDateInput({
  name,
  label,
  containerstyle,
  inputstyle,
  disableerroricon,
  ishidden,
  picked,
  ...rest
}: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Wrapper
      hasError={!!error}
      style={containerstyle}
      ishidden={ishidden}
      labelpresent={!!label}
    >
      {label && <label htmlFor={fieldName}>{label}</label>}
      <input
        type="date"
        style={inputstyle}
        name={name}
        ref={inputRef}
        defaultValue={picked}
        {...rest}
      />

      {!disableerroricon
        ? error && (
            <Error title={error}>
              <FaExclamationTriangle color="#c53030" size={20} />
            </Error>
          )
        : null}
    </Wrapper>
  );
}

FormDateInput.defaultProps = {
  label: null,
  containerstyle: {},
  inputstyle: {},
  picked: '',
  ishidden: false,
  disableerroricon: false,
};
export default FormDateInput;
