import React from 'react';
import { AppProps } from 'interfaces';

import type { TabProps } from 'react-tabs';
import { Tab } from 'react-tabs';
import { FaExclamationTriangle } from 'react-icons/fa';

import { useSpring } from '@react-spring/web';
import { ErrorSpan } from './styles';

interface CustomTabProps extends AppProps, TabProps {
  errorAmount: number;
}

function CustomTab({ children, errorAmount, ...otherProps }: CustomTabProps) {
  const styledError = useSpring({
    opacity: errorAmount > 0 ? 1 : 0,
    top: errorAmount > 0 ? '-100%' : '0%',
    height: errorAmount > 0 ? '22px' : '0px',
    transform: errorAmount > 0 ? 'translateY(48%)' : 'translateY(0%)',
  });

  return (
    <Tab {...otherProps} style={{ position: 'relative' }}>
      {!!errorAmount && (
        <ErrorSpan style={styledError}>
          <FaExclamationTriangle color="#fefefe" size={20} />

          <p style={{ color: '#fff' }}>
            {errorAmount}&nbsp;erro{errorAmount > 1 ? 's' : ''}
          </p>
        </ErrorSpan>
      )}
      {children}
    </Tab>
  );
}

CustomTab.tabsRole = 'Tab';

export default CustomTab;

export interface CustomTabErrors {
  personalData: number;
  address: number;
  family: number;
  education: number;
  role: number;
}
