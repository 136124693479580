import React, { createContext, useContext, useState, useCallback } from 'react';
import { AppProps } from 'interfaces';

interface LoadingHookData {
  toggleLoading: () => void;
  loading: boolean;
}

const LoadingHook = createContext<LoadingHookData>({} as LoadingHookData);

function LoadingProvider({ children }: AppProps) {
  const [loading, setLoading] = useState(false);

  const toggleLoading = useCallback(() => {
    setLoading((state) => !state);
  }, []);

  return (
    <LoadingHook.Provider value={{ toggleLoading, loading }}>
      {children}
    </LoadingHook.Provider>
  );
}

function useLoading(): LoadingHookData {
  const context = useContext(LoadingHook);

  if (!context) {
    throw new Error('useLoading must be used within an LoadingHookProvider');
  }

  return context;
}
export { LoadingProvider, useLoading };
