import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './style.css';


const ManagmentCreate = ({ onCreateConfirm, idEdit, fromValue: propFromValue, dueValue: propDueValue, editorContent: propEditorContent }) => {

    const [fromValue, setFromValue] = useState(propFromValue || '');
    const [dueValue, setDueValue] = useState(propDueValue || '');
    const [editorContent, setEditorContent] = useState(propEditorContent || '');
    const [buttonText, setButtonText] = useState('Cadastrar');

    const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
        "color",

    ];

    const modules = {
        toolbar: [
            [{ header: "1" }, { header: "2" }, { font: [] }],
            [{ size: [] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
                {
                    color: ["red", "blue", "yellow", "green"],
                },
            ],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
            ],
            ["link", "image", "video"],
            ["clean"],
        ],
        clipboard: {
            matchVisual: true,
        },
    };

    useEffect(() => {
        if (idEdit) {
            setButtonText('Editar');
        }
    }, [idEdit]);

    const handleBlurFrom = (e) => {
        setFromValue(e.target.value);
    };

    const handleBlurDue = (e) => {
        setDueValue(e.target.value);
    };

    const onEditorStateChange = (content) => {
        setEditorContent(content);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const data = {
            from: fromValue,
            due: dueValue,
            editorContent: editorContent,
            id: idEdit
        };
        onCreateConfirm(data);
    };

    return (
        <form onSubmit={onSubmit} className="mx-auto mt-8">
            <div className="mb-4 flex flex-col md:flex-row md:space-x-4">
                <div className="">
                    <label htmlFor="from" className="block mb-1 text-gray-700">Data da Mensagem</label>
                    <input type="datetime-local" id="from" value={fromValue} onChange={handleBlurFrom} className="border rounded-md px-4 py-2 w-full" />
                </div>
                <div>
                    <label htmlFor="due" className="block mb-1 text-gray-700">Data de Término</label>
                    <input type="datetime-local" id="due" value={dueValue} onChange={handleBlurDue} className="border rounded-md px-4 py-2 w-full" />
                </div>
            </div>
            <div className="mb-4">
                <ReactQuill modules={modules} formats={formats} id="editorContent" theme="snow" value={editorContent} onChange={onEditorStateChange} className="bg-white border rounded-md h-64" />
            </div>
            <div className={`btn-cadastrar ${fromValue && dueValue && editorContent ? '' : 'btn-cadastrar-disabled'}`}>
                <button disabled={!fromValue || !dueValue || !editorContent} type="submit" className="bg-green-500 text-white rounded-md px-4 py-2">{buttonText}</button>
            </div>
        </form>
    );
}

export default ManagmentCreate;
