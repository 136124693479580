export const SET_COMBOS_PERSONAL = 'SET_COMBOS_PERSONAL';
export const SET_COMBOS_ADDRESS = 'SET_COMBOS_ADDRESS';
export const SET_COMBOS_EDUCATION = 'SET_COMBOS_EDUCATION';

export const setCombosPersonal = (combos) => ({
  type: SET_COMBOS_PERSONAL,
  payload: combos,
});

export const setCombosAddress = (combos) => ({
  type: SET_COMBOS_ADDRESS,
  payload: combos,
});

export const setCombosEducation = (combos) => ({
  type: SET_COMBOS_EDUCATION,
  payload: combos,
});