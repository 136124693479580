import React, { useEffect, useCallback, useState, useRef } from 'react';

import * as yup from 'yup';

import { Container, Action, Checkbox } from 'components';

import {
  useLocalStorage,
  useLoading,
  useCredentials,
  useModal,
  useToast,
} from 'hooks';
import { useLocation } from 'react-router-dom';
import { MessageProps } from 'interfaces';

import api from 'services/api';
import { FormHandles } from '@unform/core';

import { compareDates, formatDateTimeForDB } from 'utils/formatDate';
import getValidationErrors from 'utils/getValidationErrors';
import { InputContainer, TableWrapper } from './styles';
import ManagmentCreate from './Create';
import ManagmentDelete from './Delete';

function Mensageria() {
  const { addToast } = useToast();
  const { toggleLoading } = useLoading();
  const { toggleModal, modalConfig } = useModal();
  const { handlePermission, errorHandling } = useCredentials();
  const { state } = useLocation();
  const { setLocalStorage, getLocalStorage, deleteLocalStorage } =
    useLocalStorage();
  const [messages, setMessages] = useState<MessageProps[]>([]);
  const [diagContent, setDiagContent] = useState(null);
  const [diagType, setDiagType] = useState<string>(null);
  const [editorError, setEditorError] = useState(false);
  const [displayEditor, setDisplayEditor] = useState(true);

  const handleGetList = useCallback(async () => {
    try {
      setMessages([]);
      const response = await api.get(
        '/system/mensagem_list.php?screen=management',
      );
      setMessages(response.data);
    } catch (err) {
      errorHandling(err);
    } finally {
      toggleLoading();
    }
  }, [errorHandling]);

  useEffect(() => {
    if (state) {
      handlePermission(state);
    }
    toggleLoading();
    handleGetList();
  }, [state, toggleLoading]);

  const handleDelete = useCallback(
    async (ev: any) => {
      const locallyStored = getLocalStorage('message2Delete') as any;
      const messageId = locallyStored.id;

      toggleLoading();
      try {
        const send = new FormData();
        send.append('data', JSON.stringify({ messageId }));

        await api.post('/system/mensagem_delete.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        setMessages((prevState) =>
          prevState.filter((item) => item.id !== messageId),
        );
        setDiagType(null);
        addToast({
          type: 'success',
          title: '',
          description: 'Mensagem removida.',
        });

        deleteLocalStorage('message2Delete');
      } catch (err) {
        console.error(err);
      } finally {
        toggleModal();
        toggleLoading();
      }
    },
    [toggleModal, setMessages, addToast, toggleLoading],
  );

  const showErroMessage = (err: any) => {
    if (err instanceof yup.ValidationError) {
      const errorMessages = err.errors.join('\n');
      addToast({
        type: 'error',
        title: 'Erro de validação',
        description: errorMessages,
      });
    } else {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Ocorreu um erro ao cadastrar a mensagem.',
      });
    }
  };

  const validateManagement = (data: any) => {
    return yup.object().shape({
      from: yup.string().required('Data inválida'),
      due: yup
        .string()
        .test(
          'check-with-dtinc',
          'Data deve ser posterior à data de inclusão',
          (_) => {
            return compareDates(data.from, data.due);
          },
        )
        .required('Data inválida'),
      editorContent: yup.string().required('empty editor'),
    });
  };

  const handleInsert = useCallback(
    async (data) => {
      try {
        setEditorError(false);

        const schema = validateManagement(data);

        await schema.validate(data, {
          abortEarly: false,
        });

        toggleLoading();
        const send = new FormData();
        send.append(
          'data',
          JSON.stringify({ ...data, content: data.editorContent }),
        );

        await api.post('/system/mensagem_insert.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Mensagem cadastrada.',
        });

        toggleModal();

        handleGetList();
        setDisplayEditor(false);
        setTimeout(() => {
          setDisplayEditor(true);
          setDiagType(null);
        }, 10);
      } catch (err) {
        showErroMessage(err);
      }
    },
    [
      setEditorError,
      toggleLoading,
      addToast,
      toggleModal,
      handleGetList,
      setDisplayEditor,
    ],
  );

  const handleUpdate = useCallback(
    async (data) => {
      try {
        setEditorError(false);

        const schema = validateManagement(data);

        await schema.validate(data, {
          abortEarly: false,
        });

        toggleLoading();
        const send = new FormData();
        send.append(
          'data',
          JSON.stringify({ ...data, content: data.editorContent }),
        );

        await api.post('/system/mensagem_update.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Mensagem alterada',
        });

        toggleModal();

        handleGetList();
        setDisplayEditor(false);
        setTimeout(() => {
          setDisplayEditor(true);
        }, 10);
      } catch (err) {
        showErroMessage(err);
      }
    },
    [
      setEditorError,
      toggleLoading,
      addToast,
      toggleModal,
      handleGetList,
      setDisplayEditor,
    ],
  );

  const handleCheckboxToggle = useCallback(async (ev) => {
    const { id } = ev.target.dataset;

    try {
      const send = new FormData();
      send.append(
        'data',
        JSON.stringify({
          messageId: id,
        }),
      );

      await api.post('/system/mensagem_toggle_active.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    } catch (err) {
      console.log(err);
    } finally {
      // toggleLoading();
    }
  }, []);

  const handleActivateInsertDialog = useCallback(() => {
    const customEvent = new CustomEvent('modalConfig', {
      detail: {
        content: (
          <ManagmentCreate
            onCreateConfirm={handleInsert}
            idEdit={''}
            fromValue={''}
            dueValue={''}
            editorContent={''}
          />
        ),
        dialogType: 'insert',
        confirmActionReference: 'insertConfirmAction',
        cancelActionReference: '',
      },
    });

    modalConfig(customEvent);
  }, [handleInsert, modalConfig]);

  const handleActivateUpdateDialog = useCallback((ev: any) => {
    const { from, due, id, content } = ev.detail;

    const customEvent = new CustomEvent('modalConfig', {
      detail: {
        content: (
          <ManagmentCreate
            onCreateConfirm={handleUpdate}
            idEdit={id}
            fromValue={from}
            dueValue={due}
            editorContent={content}
          />
        ),
        dialogType: 'update',
        confirmActionReference: 'updateConfirmAction',
      },
    });
    modalConfig(customEvent);
  }, []);

  const handleActivateDeleteDialog = useCallback((ev) => {
    console.clear();
    const { messageId, content } = ev.detail;
    setLocalStorage('message2Delete', { id: messageId });

    const customEvent = new CustomEvent('modalConfig', {
      detail: {
        content: (
          <ManagmentDelete content={content} onCreateDelete={handleDelete} />
        ),
        dialogType: 'delete',
        confirmActionReference: 'deleteConfirmAction',
      },
    });

    modalConfig(customEvent);
  }, []);

  return (
    <>
      <Container
        showIncludeButton={state.write}
        buttonAction={handleActivateInsertDialog}
        screenBanner={<div>Relação das Mensagens Cadastradas</div>}
      >
        {messages.length > 0 && (
          <TableWrapper>
            <table>
              <thead>
                <tr>
                  <th>Início</th>
                  <th>Validade</th>
                  <th>Mensagem</th>
                  <th>Status</th>
                  {state.write && (
                    <>
                      <th>Editar</th>
                      <th>Excluir</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {messages.map((item) => (
                  <tr key={item.id}>
                    <td>{item.fromFormat}</td>
                    <td>{item.dueFormat}</td>
                    <td>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.message,
                        }}
                      />
                    </td>
                    <td>
                      <Checkbox
                        checkboxId={item.id}
                        defaultChecked={item.status}
                        ontoggle={handleCheckboxToggle}
                        isdisabled={!state.write}
                      />
                    </td>
                    {state.write && (
                      <>
                        <td>
                          <Action
                            type="edit"
                            button={{
                              action: handleActivateUpdateDialog,
                              dataset: {
                                from: item.from,
                                due: item.due,
                                content: item.message,
                                id: item.id,
                              },
                            }}
                          />
                        </td>
                        <td>
                          <Action
                            type="delete"
                            button={{
                              action: handleActivateDeleteDialog,
                              dataset: {
                                messageId: item.id,
                                content: (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.message,
                                    }}
                                  />
                                ),
                              },
                            }}
                          />
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </TableWrapper>
        )}
      </Container>
    </>
  );
}

export default Mensageria;
