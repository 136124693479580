import styled from 'styled-components';
import { shade, lighten } from 'polished';
import { Link } from 'react-router-dom';

interface ActionProps {
  actiontype: 'edit' | 'show' | 'delete';
}

const palette = {
  edit: {
    background: '#007acc',
    color: '#efefef',
  },
  show: {
    background: '#786d6d',
    color: '#efefef',
  },
  delete: {
    background: '#c53030',
    color: '#efefef',
  },
};

export const OptionLink = styled(Link)<ActionProps>`
  all: revert;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  margin: 0 auto;

  border-radius: 3px;
  padding: 1px;

  width: 25px;
  height: 25px;

  svg {
    width: 17px;
    height: 17px;
  }

  color: ${(props) => lighten(0.2, `${palette[props.actiontype].color}`)};
  background-color: ${(props) =>
    shade(0.2, `${palette[props.actiontype].background}`)};

  transition: opacity 0.35s, filter 0.35s, color 0.35s, background-color 0.35s,
    box-shadow 0.35s;

  opacity: 0.85;
  box-shadow: 1px 2.5px 2.5px 0px rgba(0, 0, 0, 0.5);

  &:hover {
    opacity: 1;
    filter: brightness(1.1);
    background-color: ${(props) => palette[props.actiontype].background};
    color: ${(props) => palette[props.actiontype].color};
    box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);
  }
`;

export const OptionButton = styled.button<ActionProps>`
  all: revert;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  margin: 0 auto;

  border-radius: 3px;
  padding: 1px;

  width: 27px;
  height: 27px;

  svg {
    width: 17px;
    height: 17px;
  }

  color: ${(props) => lighten(0.2, `${palette[props.actiontype].color}`)};
  background-color: ${(props) =>
    shade(0.2, `${palette[props.actiontype].background}`)};

  transition: opacity 0.35s, filter 0.35s, color 0.35s, background-color 0.35s,
    box-shadow 0.35s;

  opacity: 0.85;
  box-shadow: 1px 2.5px 2.5px 0px rgba(0, 0, 0, 0.5);

  &:hover {
    opacity: 1;
    filter: brightness(1.1);
    background-color: ${(props) => palette[props.actiontype].background};
    color: ${(props) => palette[props.actiontype].color};
    box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);
  }
`;
