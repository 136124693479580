import { SET_COMBOS_PERSONAL, SET_COMBOS_ADDRESS, SET_COMBOS_EDUCATION } from './commonAction';

const initialState = {
  marital_status: [],
  ethnicity: [],
  sexual_preference: [],
  religion: [],
  gender: [],
  address_type: [],
  habitation: [],
  uf: [],
  zone: [],
  educationLevel: [],
  educationStatus: [],
  educationPeriod: []
}

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMBOS_PERSONAL:
      return {
        ...state,
        marital_status: action?.payload?.marital_status,
        ethnicity: action?.payload?.ethnicity,
        sexual_preference: action?.payload?.sexual_preference,
        religion: action?.payload?.religion,
        gender: action?.payload?.gender,
      };
    case SET_COMBOS_ADDRESS:
      return {
        ...state,
        address_type: action?.payload?.address_type,
        habitation: action?.payload?.habitation,
        uf: action.payload?.uf,
        zone: action?.payload?.zone,
      };
      case SET_COMBOS_EDUCATION:
        return {
          ...state,
          educationLevel: action?.payload?.educationLevel,
          educationStatus: action?.payload?.educationStatus,
          educationPeriod: action.payload?.educationPeriod,
        };
    default:
      return state;
  }
};

export default commonReducer;
