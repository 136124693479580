import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const CreateFormComplement = ({
  action,
  formData,
  setFormData,
  onUpdateFormStatus,
  handleTabChange,
}) => {
  const formik = useFormik({
    initialValues: formData,
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      descricaoConflitosLei: Yup.string().when('conflitosLei', {
        is: 'S',
        then: (schema) => schema.required('Descrição é obrigatória'),
        otherwise: (schema) => schema.notRequired(),
      }),
      descricaoMedidasSocioeducativas: Yup.string().when(
        'medidasSocioeducativas',
        {
          is: 'S',
          then: (schema) => schema.required('Descrição é obrigatória'),
          otherwise: (schema) => schema.notRequired(),
        },
      ),
      descricaoViolenciaDomestica: Yup.string().when('violenciaDomestica', {
        is: 'S',
        then: (schema) => schema.required('Descrição é obrigatória'),
        otherwise: (schema) => schema.notRequired(),
      }),
      descricaoProblemasSaude: Yup.string().when('problemasSaude', {
        is: 'S',
        then: (schema) => schema.required('Descrição é obrigatória'),
        otherwise: (schema) => schema.notRequired(),
      }),
      descricaoTratamentoMedico: Yup.string().when('tratamentoMedico', {
        is: 'S',
        then: (schema) => schema.required('Descrição é obrigatória'),
        otherwise: (schema) => schema.notRequired(),
      }),
      descricaoUsoContMedicament: Yup.string().when('usoContMedicament', {
        is: 'S',
        then: (schema) => schema.required('Descrição é obrigatória'),
        otherwise: (schema) => schema.notRequired(),
      }),
      descricaoDependenteQuimico: Yup.string().when('dependenteQuimico', {
        is: 'S',
        then: (schema) => schema.required('Descrição é obrigatória'),
        otherwise: (schema) => schema.notRequired(),
      }),
      descricaoUsoSubstanciaQuimica: Yup.string().when('usoSubstanciaQuimica', {
        is: 'S',
        then: (schema) => schema.required('Descrição é obrigatória'),
        otherwise: (schema) => schema.notRequired(),
      }),
      descricaoHistoricoPensamentoAbortivo: Yup.string().when(
        'historicoPensamentoAbortivo',
        {
          is: 'S',
          then: (schema) => schema.required('Descrição é obrigatória'),
          otherwise: (schema) => schema.notRequired(),
        },
      ),
      descricaoObservacoesGerais: Yup.string().when('observacoesGerais', {
        is: 'S',
        then: (schema) => schema.required('Descrição é obrigatória'),
        otherwise: (schema) => schema.notRequired(),
      }),
    }),
    onSubmit: (values) => {
      setFormData((prevData: any) => ({
        ...prevData,
        complemento: values,
      }));
    },
  });

  React.useEffect(() => {
    onUpdateFormStatus(formik.isValid, formik);
    setFormData(formik.values);
  }, [formik.isValid, formik.touched]);

  const handleCheckboxChange = (name: string, value: 'S' | 'N') => {
    formik.setFieldValue(name, value);
  };

  const handleComplementDataValidation = () => {
    // Campos que são obrigatórios apenas se a checkbox associada estiver marcada como 'S'
    const conditionalRequiredFields = [
      { field: 'conflitosLei', descField: 'descricaoConflitosLei' },
      {
        field: 'medidasSocioeducativas',
        descField: 'descricaoMedidasSocioeducativas',
      },
      { field: 'violenciaDomestica', descField: 'descricaoViolenciaDomestica' },
      { field: 'problemasSaude', descField: 'descricaoProblemasSaude' },
      { field: 'tratamentoMedico', descField: 'descricaoTratamentoMedico' },
      { field: 'usoContMedicament', descField: 'descricaoUsoContMedicament' },
      { field: 'dependenteQuimico', descField: 'descricaoDependenteQuimico' },
      {
        field: 'usoSubstanciaQuimica',
        descField: 'descricaoUsoSubstanciaQuimica',
      },
      {
        field: 'historicoPensamentoAbortivo',
        descField: 'descricaoHistoricoPensamentoAbortivo',
      },
      { field: 'observacoesGerais', descField: 'descricaoObservacoesGerais' },
    ];

    // Campos condicionalmente obrigatórios que estão vazios
    const conditionalEmptyFields = conditionalRequiredFields
      ?.filter(
        ({ field, descField }) =>
          formik.values[field] === 'S' && !formik.values[descField],
      )
      ?.map(({ descField }) => descField);

    if (conditionalEmptyFields.length > 0) {
      const errors = {};
      conditionalEmptyFields.forEach((field) => {
        errors[field] = 'Campo obrigatório';
        formik.setFieldTouched(field, true);
      });
      formik.setErrors(errors);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        complemento: formik.values,
      }));
      setTimeout(() => {
        handleTabChange(6);
      }, 1000);
    }
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      id="complementForm"
      className="container-fadeIn space-y-4"
    >
      {[
        { label: 'Conflitos com a Lei', name: 'indlei', descName: 'detlei' },
        {
          label: 'Medidas Socioeducativas',
          name: 'indmed',
          descName: 'detmed',
        },
        {
          label: 'Passou por violência doméstica',
          name: 'indvio',
          descName: 'detvio',
        },
        {
          label: 'Há problemas com sua saúde',
          name: 'indsaude',
          descName: 'detsaude',
        },
        {
          label: 'Faz tratamento médico',
          name: 'indtrat',
          descName: 'dettrat',
        },
        {
          label: 'Faz uso contínuo de medicamentos',
          name: 'indcont',
          descName: 'detcont',
        },
        {
          label: 'Alguém é dependente químico',
          name: 'inddep',
          descName: 'detdep',
        },
        {
          label: 'Faz uso de substância química',
          name: 'indquim',
          descName: 'detquim',
        },
        {
          label: 'Histórico de pensamento abortivo',
          name: 'indabort',
          descName: 'detabort',
        },
      ].map(({ label, name, descName }) => (
        <div key={name} className="grid grid-cols-1 gap-4">
          <div className="col-span-1">
            <label className="block mb-1 text-gray-700">{label}?</label>
            <div className="flex items-center mt-1">
              <input
                disabled={action === 'show'}
                type="checkbox"
                name={name}
                value={formik.values[name]}
                checked={formik.values[name] === 'S'}
                onChange={() => handleCheckboxChange(name, 'S')}
                onBlur={formik.handleBlur}
                className="mr-2 h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
              />
              <span className="mr-4">Sim</span>
              <input
                disabled={action === 'show'}
                type="checkbox"
                name={name}
                value={formik.values[name]}
                checked={formik.values[name] === 'N'}
                onChange={() => handleCheckboxChange(name, 'N')}
                onBlur={formik.handleBlur}
                className="mr-2 h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
              />
              <span>Não</span>
            </div>
            {formik.values[name] === 'S' && (
              <textarea
                disabled={action === 'show'}
                rows={4}
                name={descName}
                placeholder="Descrição"
                value={formik.values[descName]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="resize-none border rounded-md p-2 w-full"
              />
            )}
            {formik.touched[descName] && formik.errors[descName] && (
              <div className="text-red-500">
                {String(formik.errors[descName])}
              </div>
            )}
          </div>
        </div>
      ))}

      <div>
        <label className="block mb-1 text-gray-700">Observações Gerais</label>
        <textarea
          disabled={action === 'show'}
          rows={6}
          name="detobs"
          placeholder="Descrição"
          value={formik.values.detobs}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className="resize-none border rounded-md p-2 w-full"
        />
      </div>

      {(action === 'edit' || action === 'new') && (
        <div className="w-full px-3">
          <div className="flex justify-end">
            <button
              type="submit"
              className="mt-4 bg-blue-500 text-white font-bold py-2 px-4 rounded"
              onClick={handleComplementDataValidation}
            >
              Validar Dados de Complemento
            </button>
          </div>
          {!formik.isValid && (
            <div className="text-red-500 font-bold mt-2 flex justify-end">
              Preencha os campos obrigatórios.
            </div>
          )}
        </div>
      )}
    </form>
  );
};

export default CreateFormComplement;
