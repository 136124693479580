import { styled, createGlobalStyle } from 'styled-components';
import { lighten } from 'polished';



export default createGlobalStyle`
*{
  margin: 0;
  outline: none;
  padding: 0;
  border: 0;
  font-family: 'Roboto Slab', serif;
  font-size: 16px;

  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;

  /* width */
  ::-webkit-scrollbar {
    /* width: 10px; */
    width: 0px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    /* border-radius: 5px 0px 0px 5px; */
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${lighten('0.05', '#9E9E9E')};
    background: #464646;
    /* border-radius: 5px 0px 0px 5px; */
    transition: background-color 0.25s;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #565656;
  }

}
html{
    font-size: 62.5%;
  }

button {
  cursor: pointer;
}

h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

h1{
  font-size: 32px;
}
h2{
  font-size: 24px;
}
h3{
  font-size: 18.72px;
}
h4{
  font-size: 16px;
}
h5{
  font-size: 13.28px;
}
h6{
  font-size: 12px;
}

`;

export const Actions = styled.span`
  all: unset;
  position: absolute;
  bottom: 5px;
  right: 5px;
  /* left: 50%; */
  /* transform: translateX(-50%); */
  display: flex;
  flex-direction: row;
  /* width: 100%; */
  padding: 2px 0;

  align-items: center;
  justify-content: flex-end;
  column-gap: 10px;
`;

export const TableWrapper = styled.div`
  width: 100vw;
  max-width: 800px;
  margin: 0 auto;

  border: 3px solid #332e2e;
  border-radius: 5px;
  /* margin: 20px 0px 20px 0px; */
  /* width: 800px; */
`;

export const Table = styled.table`
  width: 100%;
  background: #fff;
  border-collapse: collapse;
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);
  table-layout: fixed;

  thead {
    font-weight: bold;
    background: #332e2e;
    color: #fff;
  }

  tr {
    &:nth-child(even) {
      background: #e6e6e6;
    }
  }

  td {
    padding: 2px 5px;
    /* border: 1px solid black; */

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      text-align: center;
    }
  }
`;
