import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  z-index: 149;
  span {
    color: #efefef;
    width: 160px;
    background: #c53030;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    opacity: 0;
    transition: all 0.4s;
    visibility: hidden;
    text-align: center;

    position: absolute;
    bottom: calc(100% + 12px);
    left: 50%;
    transform: translateX(-50%);

    color: #312e38;

    bottom: 0;
    transform: translateX(-110%);

    &::before {
      content: '';
      border-style: solid;
      border-color: #c53030 transparent;
      border-width: 6px 6px 0 6px;
      top: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      border-color: transparent #c53030;
      border-width: 6px 0 6px 6px;
      left: 100%;
      transform: translateX(-105%);
      transform: translateY(-130%);
    }
  }
  &:hover span {
    opacity: 1;
    visibility: visible;
  }

  @media screen and (max-width: 540px) {
    span {
      bottom: 0;
      transform: translateX(-110%);

      &::before {
        border-color: transparent #c53030;
        border-width: 6px 0 6px 6px;
        left: 100%;
        transform: translateX(-105%);
        transform: translateY(-130%);
      }
    }
  }
`;
