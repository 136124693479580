import styled from 'styled-components';

export const FooterWrapper = styled.footer`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100vw;
  height: 25px;

  p,
  a {
    font-size: 12px;
  }
  background: #2d517a;
  background: #0054a6;
  span {
  }
`;

export const CompanyInformation = styled.span`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  color: #efefef;
  a {
    color: #efefef;

    svg {
      height: 35px;
      width: 35px;
    }

    & + a {
      margin-left: 15px;
    }
  }

  & + span {
    margin-top: 10px;
  }
`;
