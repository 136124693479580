export interface Colaborator {
  id: string;
  // personal
  name: string;
  surname: string;
  birth: string;
  rg: string;
  org: string;
  rg_expedition: string;
  cin: string;
  cpf: string;
  religion: string;
  marital_status: string;
  nationality: string;
  children: number;
  gender: string;
  sexual_preference: string;
  ethnicity: string;
  phone: string;
  mail: string;
  whatsapp: string;
  mobile: string;
  // address
  cep: string;
  address_type: string;
  address: string;
  number: string;
  complement: string;
  district: string;
  city: string;
  uf: string;
  zone: string;
  referral: string;
  habitation: string;
  // family
  companion_name: string;
  companion_surname: string;
  companion_birth: string;
  companion_rg: string;
  companion_cpf: string;
  companion_cin: string;
  companion_gender: string;
  companion_phone: string;
  companion_mobile: string;
  companion_mail: string;
  children_info: ColaboratorChildren[];
  // education
  education: string;
  education_status: string;
  last_school: string;
  period: string;
  course: string;
  course_info: string;
  // role
  cbo: string;
  role: string;
  area: string;
  activities: string;
  bond_type: string;
  register_num: string;
  job_start: string;
  job_end: string;
  job_status: string;
  person_type: string;
}

export interface ColaboratorChildren {
  id: string;
  name: string;
  surname: string;
  birth: string;
  gender: string;
}

export interface ColaboratorFamilyMembers {
  maritalStatus: string;
  childrenAmount: number;
}

export const lsKey = `${process.env.REACT_APP_ENVPREFIX}::colaborator`;

export const fields = {
  personal: [
    'name',
    'surname',
    'birth',
    'rg',
    'org',
    'rg_expedition',
    'cin',
    'cpf',
    'religion',
    'marital_status',
    'nationality',
    'children',
    'gender',
    'sexual_preference',
    'ethnicity',
    'phone',
    'mail',
    'whatsapp',
    'mobile',
  ],
  address: [
    'cep',
    'address_type',
    'address',
    'number',
    'complement',
    'district',
    'city',
    'uf',
    'zone',
    'referral',
    'habitation',
  ],
  family: [
    'companion_name',
    'companion_surname',
    'companion_birth',
    'companion_rg',
    'companion_cpf',
    'companion_cin',
    'companion_gender',
    'companion_phone',
    'companion_mobile',
    'companion_mail',
    'children_info',
  ],
  education: [
    'education',
    'education_status',
    'last_school',
    'period',
    'course',
    'course_info',
  ],
  role: [
    'cbo',
    'role',
    'area',
    'activities',
    'bond_type',
    'register_num',
    'job_start',
    'job_end',
    'job_status',
    'person_type',
  ],
};

export function generateColaborator(obj: any): any {
  const personalInfo = {
    id: obj.person.id_pessoa,
    // person
    name: obj.person.nome,
    surname: obj.person.sobrenome,
    birth: obj.person.dtnasc,
    rg: obj.person.rg,
    org: obj.person.orgemiss,
    rg_expedition: obj.person.dtexpedrg,
    cin: obj.person.cin,
    cpf: obj.person.cpf,
    religion: obj.person.religiao,
    marital_status: obj.person.estcivil,
    nationality: obj.person.naturalidade,
    children: parseInt(obj.person.qtfilhos, 10),
    gender: obj.person.sexo,
    sexual_preference: obj.person.orientsex,
    ethnicity: obj.person.etnia,
    phone: obj.person.fonefixo,
    mail: obj.person.email,
    whatsapp: obj.person.whatsapp,
    mobile: obj.person.celular,
  };

  const addressInfo = {
    cep: obj.address.cep,
    // address_type: obj.address.???,
    address: obj.address.endereco,
    number: obj.address.num,
    complement: obj.address.compl,
    district: obj.address.bairro,
    city: obj.address.cidade,
    uf: obj.address.uf,
    zone: obj.address.zona,
    referral: obj.address.ptrefer,
    habitation: obj.address.moradia,
  };
  console.table(obj.companion);
  const familyInfo = obj.companion
    ? {
        companion_name: obj.companion.nome,
        companion_surname: obj.companion.sobrenome,
        companion_birth: obj.companion.dtnasc,
        companion_rg: obj.companion.rg,
        companion_cpf: obj.companion.cpf,
        companion_cin: obj.companion.cin,
        companion_gender: obj.companion.sexo,
        companion_phone: obj.companion.fonefixo,
        companion_mobile: obj.companion.celular,
        companion_mail: obj.companion.email,
        children: [...obj.children],
      }
    : {
        companion_name: '',
        companion_surname: '',
        companion_birth: '',
        companion_rg: '',
        companion_cpf: '',
        companion_cin: '',
        companion_gender: '',
        companion_phone: '',
        companion_mobile: '',
        companion_mail: '',
        children: [...obj.children],
      };

  const educationInfo = {
    education: obj.education.nivel,
    education_status: obj.education.situacao,
    last_school: obj.education.ultinstit,
    period: obj.education.periodo,
    course: obj.education.extra,
    course_info: obj.education.dadosextra,
  };

  const roleInfo = {
    cbo: obj.function.cbo,
    role: obj.function.funcao,
    area: obj.function.area,
    activities: obj.function.atividade,
    bond_type: obj.function.vinculo,
    bond: obj.function.vinculo,
    register_num: obj.function.registro,
    job_start: obj.function.admiss,
    job_end: obj.function.demiss,
    // job_status: obj.function.???,
    person_type: obj.person.tipo,
  };

  // const generated = {
  //   ...personalInfo,
  //   ...addressInfo,
  //   ...familyInfo,
  //   ...educationInfo,
  //   ...roleInfo,
  // } as Colaborator;

  return {
    person: { ...personalInfo },
    address: { ...addressInfo },
    family: { ...familyInfo },
    education: { ...educationInfo },
    role: { ...roleInfo },
  };
}
