import React, { useEffect, useCallback, useRef } from 'react';

import { FaPencilAlt, FaTimesCircle, FaSearch } from 'react-icons/fa';

import { OptionLink, OptionButton } from './styles';

interface CustomProps {
  type: 'edit' | 'show' | 'delete';
  link?: {
    pathname?: string;
    newtab?: boolean;
    state?: object;
  };
  button?: {
    action?: (ev: any) => void;
    dataset?: object;
  };
}

type ActionProps = JSX.IntrinsicElements['div'] & CustomProps;

function Action({ type, button, link }: ActionProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleButtonAction = useCallback((ev: any) => {
    const customEvent = new CustomEvent('actionButton', {
      detail: { ...button.dataset },
    });

    button.action(customEvent);
  }, []);

  useEffect(() => {
    if (button && button.dataset) {
      Object.keys(button.dataset).forEach((keyName) => {
        if (buttonRef.current) {
          buttonRef.current.setAttribute(
            `data-${keyName}`,
            button.dataset[`${keyName}`],
          );
        }
      });
    }
  }, []);

  return link ? (
    <OptionLink
      to={{ pathname: `${link.pathname}/${type}` }}
      state={link.state}
      actiontype={type}
      target={link.newtab ? '_blank' : '_self'}
    >
      {type === 'edit' ? (
        <FaPencilAlt />
      ) : type === 'delete' ? (
        <FaTimesCircle />
      ) : (
        <FaSearch />
      )}
    </OptionLink>
  ) : (
    <OptionButton
      type="button"
      actiontype={type}
      onClick={handleButtonAction}
      ref={buttonRef}
    >
      {type === 'edit' ? (
        <FaPencilAlt />
      ) : type === 'delete' ? (
        <FaTimesCircle />
      ) : (
        <FaSearch />
      )}
    </OptionButton>
  );
}

Action.defaultProps = {
  button: null,
  link: null,
};

export default Action;
