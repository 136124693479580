import React, { useEffect, useState, useCallback } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { FaPlusCircle } from 'react-icons/fa';
import { AppProps } from 'interfaces';
import { useScrolling } from 'hooks';
import { Wrapper } from './styles';

interface CustomProps extends AppProps {
  path?: string;
  options?: object;
  isVisible?: boolean;
  buttonAction?: () => void;
}
type IncludeButtonProps = JSX.IntrinsicElements['div'] & CustomProps;

function IncludeButton({
  children,
  isVisible,
  path,
  options,
  buttonAction,
}: IncludeButtonProps) {
  const { scrolled } = useScrolling();
  const location = useLocation();
  const [position, setPosition] = useState<number>(scrolled);

  useEffect(() => {
    if (scrolled >= 100) {
      setPosition(100);
      return;
    }
    setPosition(scrolled);
  }, [scrolled]);

  const handleAction = useCallback(() => {
    buttonAction();
  }, [buttonAction]);

  return isVisible ? (
    <Wrapper>
      {!buttonAction ? (
        <Link
          title="Incluir"
          to={{
            pathname: path || `${location.pathname}/new`,
          }}
          state={options}
        >
          <FaPlusCircle />
        </Link>
      ) : (
        <button type="button" onClick={handleAction}>
          <FaPlusCircle />
        </button>
      )}
      {/* TODO: add own Dialog, into hooks. (similar to loading) */}
    </Wrapper>
  ) : null;
}

IncludeButton.defaultProps = {
  path: null,
  options: {},

  isVisible: false,
  buttonAction: null,
};

// const IncludeButton: React.FC<IncludeButtonProps> = ({
//   children,
//   path,
//   isButton = false,
//   options = {},
// }) => {
//   const { scrolled } = useScrolling();
//   const location = useLocation();
//   const [position, setPosition] = useState<number>(scrolled);

//   useEffect(() => {
//     if (scrolled > 100) {
//       setPosition(100);
//       return;
//     }

//     setPosition(scrolled);
//   }, [scrolled]);

//   return (
//     <Container amountScrolled={position}>
//       {!isButton ? (
//         <Link
//           title="Incluir"
//           to={{
//             pathname: path || `${location.pathname}/insert`,
//             state: { options },
//           }}
//         >
//           <FaPlusCircle />
//           {children}
//         </Link>
//       ) : (
//         children
//       )}
//     </Container>
//   );
// };

export default IncludeButton;
