import api from './api';
import { deleteItem, getAllItems, getItem, setItem } from './indexedDB';

export default {
  api,
  indexedDB: {
    setItem,
    getItem,
    deleteItem,
    getAllItems,
  },
};
