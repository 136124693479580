import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  registro: Yup.string()
    .min(2, 'Nome deve ter no mínimo 2 caracteres')
    .required('Campo obrigatório'),
});



const CreateFormRegister = ({ action, id, formData,
  setFormData,
  onUpdateFormStatus,
  onSave,
  canSave, }) => {
  const formik = useFormik({
    initialValues: formData,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: values => {
      setFormData((prevData) => ({
        ...prevData,
        personalData: values,
      }));

    },
  });
  const handlePersonalDataValidation = () => {

    const requiredFields = ['registro']

    const emptyFields = requiredFields.filter(field => !formik.values[field]);

    if (emptyFields.length > 0) {
      emptyFields.forEach(field => {
        formik.setFieldError(field, 'Campo obrigatório');
        formik.setFieldTouched(field, true);
      });
    } else {

      setFormData((prevData) => ({
        ...prevData,
        pessoa: formik.values,
      }));

    }
  };

  useEffect(() => {
    onUpdateFormStatus(formik.isValid, formik);
  }, [formik.isValid,]);


  return (
    <div className="container-fadeIn">
      <form id={id} onSubmit={formik.handleSubmit} className="space-y-4">
        <div className="flex space-x-4">
          <div className="w-1/4">
            <label htmlFor="registro" className="block mb-1 text-gray-700">
              Registro
            </label>
            <input
              disabled={action === 'show'}
              id="registro"
              name="registro"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.registro}
              className={`border ${formik.touched.registro && formik.errors.registro ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.registro && formik.errors.registro && (
              <div className="text-red-500 text-sm">{String(formik.errors.registro)}</div>
            )}
          </div>
          <div className="w-1/4">
            <label htmlFor="dataEntrada" className="block mb-1 text-gray-700">
              Data Entrada
            </label>
            <input
              disabled={action === 'show'}
              id="dataEntrada"
              name="dataEntrada"
              type="date"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.dataEntrada}
              className={`border ${formik.touched.dataEntrada && formik.errors.dataEntrada ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}

            />
            {formik.touched.dataEntrada && formik.errors.dataEntrada && (
              <div className="text-red-500 text-sm">{String(formik.errors.dataEntrada)}</div>
            )}
          </div>
          <div className="w-1/4">
            <label htmlFor="dataSaida" className="block mb-1 text-gray-700">
              Data Saída
            </label>
            <input
              disabled={action === 'show'}
              id="dataSaida"
              name="dataSaida"
              type="date"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.dataSaida}
              className={`border ${formik.touched.dataSaida && formik.errors.dataSaida ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}

            />
            {formik.touched.dataSaida && formik.errors.dataSaida && (
              <div className="text-red-500 text-sm">{String(formik.errors.dataSaida)}</div>
            )}
          </div>
          <div className="w-1/4">
            <label htmlFor="situacao" className="block mb-1 text-gray-700">
              Situação
            </label>
            <select
              disabled={action === 'show'}
              id="situacao"
              name="situacao"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.situacao}
              className={`border ${formik.touched.situacao && formik.errors.situacao ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            >
              <option value="A">Ativa</option>
              <option value="I">Inativa</option>
            </select>
            {formik.touched.situacao && formik.errors.situacao && (
              <div className="text-red-500 text-sm">{String(formik.errors.situacao)}</div>
            )}
          </div>
        </div>
        {(action === 'edit' || action === 'new') && (
          <div className="flex justify-end mt-4">
            <button
              type="submit"
              className="mt-4 bg-blue-500 text-white font-bold py-2 px-4 rounded mr-3"
              onClick={handlePersonalDataValidation}
            >
              Validar Dados Pessoais
            </button>

            <button
              type="submit"
              onClick={onSave}
              className={`mt-4 bg-green-500 text-white font-bold py-2 px-4 rounded mr-4 ${!canSave ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              disabled={!canSave}
            >
             {action === 'edit' ? 'Editar dados' : 'Salvar dados' }
            </button>
          </div>
        )}
      </form>
    </div>
  )
}

export default CreateFormRegister