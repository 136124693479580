import styled from 'styled-components';

export const MessagesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  row-gap: 15px;

  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

export const Message = styled.div`
  --mainColor: #0d47a1;
  display: flex;
  flex-direction: column;
  padding: 3px 7px;

  border-radius: 5px;

  h3 {
    width: 175px;
    text-align: center;
    background: var(--mainColor);
    border: 3px solid var(--mainColor);
    border-radius: 5px 5px 0 0;
    border-bottom-width: 0px;
    color: #fff;
  }
  > span {
    padding: 10px 15px 5px 15px;
    border: 3px solid var(--mainColor);
    border-radius: 0px 5px 5px 5px;
    background: #fff;
  }
`;
