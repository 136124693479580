import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';

const validationSchema = Yup.object().shape({
  nome: Yup.string()
    .min(2, 'Nome deve ter no mínimo 2 caracteres')
    .required('Campo obrigatório'),
  sobrenome: Yup.string()
    .min(2, 'Sobrenome deve ter no mínimo 2 caracteres')
    .required('Campo obrigatório'),
  dtnasc: Yup.date().required('Campo obrigatório'),
  rg: Yup.string()
    .min(5, 'RG deve ter no mínimo 5 caracteres')
    .required('Campo obrigatório'),
  orgemiss: Yup.string()
    .min(3, 'Órgão Emissor deve ter no mínimo 3 caracteres')
    .required('Campo obrigatório'),
  dtexpedrg: Yup.date().required('Campo obrigatório'),
  cpf: Yup.string().required('Campo obrigatório'),
  sexo: Yup.string().required('Campo obrigatório'),
  religiao: Yup.string().required('Campo obrigatório'),
  estcivil: Yup.string().required('Campo obrigatório'),
  orientsex: Yup.string().required('Campo obrigatório'),
  etnia: Yup.string().required('Campo obrigatório'),
  dtprovparto: Yup.string().when('gestante', {
    is: 'S',
    then: (schema) => schema.required('Data provável do parto é obrigatória'),
    otherwise: (schema) => schema.notRequired(),
  }),
  dtcadastramento: Yup.string().required('Campo obrigatório'),
});

const CreateFormPersonal = ({
  action,
  id,
  formData,
  setFormData,
  marital_status,
  ethnicity,
  sexual_preference,
  religion,
  gender,
  handleTabChange,
  onUpdateFormStatus,
}) => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: formData,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setFormData((prevData) => ({
        ...prevData,
        personalData: values,
      }));
    },
  });
  const handlePersonalDataValidation = () => {
    const requiredFields = [
      'nome',
      'sobrenome',
      'dtnasc',
      'rg',
      'orgemiss',
      'dtexpedrg',
      'cpf',
      'sexo',
      'religiao',
      'estcivil',
      'orientsex',
      'etnia',
      'dtcadastramento',
    ];

    const emptyFields = requiredFields.filter((field) => !formik.values[field]);

    if (emptyFields.length > 0) {
      emptyFields.forEach((field) => {
        formik.setFieldError(field, 'Campo obrigatório');
      });
    } else {
      setFormData((prevData) => ({
        ...prevData,
        pessoa: formik.values,
      }));
      setTimeout(() => {
        handleTabChange(2);
      }, 1000);
    }
  };

  useEffect(() => {
    onUpdateFormStatus(formik.isValid, formik);
    setFormData(formik.values);
  }, [formik.isValid, formik.touched]);

  const handleExitWithoutSaving = (e) => {
    e.preventDefault();
    window.close();
  };

  return (
    <div className="container-fadeIn">
      <form id={id} onSubmit={formik.handleSubmit}>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label htmlFor="nome" className="block mb-1 text-gray-700">
              Nome
            </label>
            <input
              disabled={action === 'show'}
              type="text"
              id="nome"
              name="nome"
              value={formik.values.nome}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.nome && formik.errors.nome ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.nome && formik.errors.nome ? (
              <div className="text-red-500 text">
                {String(formik.errors.nome)}
              </div>
            ) : null}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label htmlFor="sobrenome" className="block mb-1 text-gray-700">
              Sobrenome
            </label>
            <input
              disabled={action === 'show'}
              type="text"
              id="sobrenome"
              name="sobrenome"
              value={formik.values.sobrenome}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.sobrenome && formik.errors.sobrenome ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.sobrenome && formik.errors.sobrenome ? (
              <div className="text-red-500 text">
                {String(formik.errors.sobrenome)}
              </div>
            ) : null}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label htmlFor="dtnasc" className="block mb-1 text-gray-700">
              Data de Nascimento
            </label>
            <input
              disabled={action === 'show'}
              type="date"
              id="dtnasc"
              name="dtnasc"
              value={formik.values.dtnasc}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.dtnasc && formik.errors.dtnasc ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.dtnasc && formik.errors.dtnasc ? (
              <div className="text-red-500 text">
                {String(formik.errors.dtnasc)}
              </div>
            ) : null}
          </div>
        </div>

        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label htmlFor="rg" className="block mb-1 text-gray-700">
              RG
            </label>
            <input
              disabled={action === 'show'}
              type="text"
              id="rg"
              name="rg"
              value={formik.values.rg}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.rg && formik.errors.rg ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.rg && formik.errors.rg ? (
              <div className="text-red-500 text">
                {String(formik.errors.rg)}
              </div>
            ) : null}
          </div>
          <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label htmlFor="orgemiss" className="block mb-1 text-gray-700">
              Orgão Emissor
            </label>
            <input
              disabled={action === 'show'}
              type="text"
              id="orgemiss"
              name="orgemiss"
              value={formik.values.orgemiss}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.orgemiss && formik.errors.orgemiss ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.orgemiss && formik.errors.orgemiss ? (
              <div className="text-red-500 text">
                {String(formik.errors.orgemiss)}
              </div>
            ) : null}
          </div>
          <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label htmlFor="dtexpedrg" className="block mb-1 text-gray-700">
              Data Emissão
            </label>
            <input
              disabled={action === 'show'}
              type="date"
              id="dtexpedrg"
              name="dtexpedrg"
              value={formik.values.dtexpedrg}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.dtexpedrg && formik.errors.dtexpedrg ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.dtexpedrg && formik.errors.dtexpedrg ? (
              <div className="text-red-500 text">
                {String(formik.errors.dtexpedrg)}
              </div>
            ) : null}
          </div>
          <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label htmlFor="cin" className="block mb-1 text-gray-700">
              CIN
            </label>
            <input
              disabled={action === 'show'}
              type="text"
              id="cin"
              name="cin"
              value={formik.values.cin}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.cin && formik.errors.cin ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.cin && formik.errors.cin ? (
              <div className="text-red-500 text">
                {String(formik.errors.cin)}
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label htmlFor="cpf" className="block mb-1 text-gray-700">
              CPF
            </label>

            <InputMask
              disabled={action === 'show'}
              mask="999.999.999-99"
              value={formik.values.cpf}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              {() => (
                <input
                  type="text"
                  name="cpf"
                  className={`border ${formik.touched?.cpf && formik.errors?.cpf ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
                />
              )}
            </InputMask>
            {formik.touched.cpf && formik.errors.cpf ? (
              <div className="text-red-500 text">
                {String(formik.errors.cpf)}
              </div>
            ) : null}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label htmlFor="religiao" className="block mb-1 text-gray-700">
              Religião
            </label>
            <select
              disabled={action === 'show'}
              id="religiao"
              name="religiao"
              value={formik.values.religiao}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.religiao && formik.errors.religiao ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            >
              <option value="">Selecione...</option>
              {religion?.map((rel, index) => (
                <option key={index} value={rel.value}>
                  {rel.label}
                </option>
              ))}
            </select>
            {formik.touched.religiao && formik.errors.religiao ? (
              <div className="text-red-500 text">
                {String(formik.errors.religiao)}
              </div>
            ) : null}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label htmlFor="estcivil" className="block mb-1 text-gray-700">
              Estado Civil
            </label>
            <select
              disabled={action === 'show'}
              id="estcivil"
              name="estcivil"
              value={formik.values.estcivil}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.estcivil && formik.errors.estcivil ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            >
              <option value="">Selecione...</option>
              {marital_status?.map((eth, index) => (
                <option key={index} value={eth.value}>
                  {eth.label}
                </option>
              ))}
            </select>
            {formik.touched.estcivil && formik.errors.estcivil ? (
              <div className="text-red-500 text">
                {String(formik.errors.estcivil)}
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label htmlFor="naturalidade" className="block mb-1 text-gray-700">
              Naturalidade/UF
            </label>
            <input
              disabled={action === 'show'}
              type="text"
              id="naturalidade"
              name="naturalidade"
              value={formik.values.naturalidade}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.naturalidade && formik.errors.naturalidade ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.naturalidade && formik.errors.naturalidade ? (
              <div className="text-red-500 text">
                {String(formik.errors.naturalidade)}
              </div>
            ) : null}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              htmlFor="dtcadastramento"
              className="block mb-1 text-gray-700"
            >
              Data Cadastramento
            </label>
            <input
              disabled={action === 'show'}
              type="date"
              id="dtcadastramento"
              name="dtcadastramento"
              value={formik.values.dtcadastramento}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.dtcadastramento && formik.errors.dtcadastramento ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.dtcadastramento && formik.errors.dtcadastramento ? (
              <div className="text-red-500 text">
                {String(formik.errors.dtcadastramento)}
              </div>
            ) : null}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label htmlFor="dtinativacao" className="block mb-1 text-gray-700">
              Data Inativação
            </label>
            <input
              disabled={action === 'show'}
              type="date"
              id="dtinativacao"
              name="dtinativacao"
              value={formik.values.dtinativacao}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.dtinativacao && formik.errors.dtinativacao ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.dtinativacao && formik.errors.dtinativacao ? (
              <div className="text-red-500 text">
                {String(formik.errors.dtinativacao)}
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label htmlFor="sexo" className="block mb-1 text-gray-700">
              Sexo
            </label>
            <select
              disabled={action === 'show'}
              id="sexo"
              name="sexo"
              value={formik.values.sexo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.sexo && formik.errors.sexo ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            >
              <option value="">Selecione...</option>
              {gender?.map((eth, index) => (
                <option key={index} value={eth.value}>
                  {eth.label}
                </option>
              ))}
            </select>
            {formik.touched.sexo && formik.errors.sexo ? (
              <div className="text-red-500 text">
                {String(formik.errors.sexo)}
              </div>
            ) : null}
          </div>
          <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label htmlFor="orientsex" className="block mb-1 text-gray-700">
              Oreintação Sexual
            </label>
            <select
              disabled={action === 'show'}
              id="orientsex"
              name="orientsex"
              value={formik.values.orientsex}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.orientsex && formik.errors.orientsex ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            >
              <option value="">Selecione...</option>
              {sexual_preference?.map((eth, index) => (
                <option key={index} value={eth.value}>
                  {eth.label}
                </option>
              ))}
            </select>
            {formik.touched.orientsex && formik.errors.orientsex ? (
              <div className="text-red-500 text">
                {String(formik.errors.orientsex)}
              </div>
            ) : null}
          </div>
          <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label htmlFor="etnia" className="block mb-1 text-gray-700">
              Etnia
            </label>
            <select
              disabled={action === 'show'}
              id="etnia"
              name="etnia"
              value={formik.values.etnia}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.etnia && formik.errors.etnia ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            >
              <option value="">Selecione...</option>
              {ethnicity?.map((eth, index) => (
                <option key={index} value={eth.value}>
                  {eth.label}
                </option>
              ))}
            </select>
            {formik.touched.etnia && formik.errors.etnia ? (
              <div className="text-red-500 text">
                {String(formik.errors.etnia)}
              </div>
            ) : null}
          </div>
          <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label htmlFor="fonefixo" className="block mb-1 text-gray-700">
              NºTelefone(Fixo)
            </label>
            <input
              disabled={action === 'show'}
              type="text"
              id="fonefixo"
              name="fonefixo"
              value={formik.values.fonefixo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.fonefixo && formik.errors.fonefixo ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.fonefixo && formik.errors.fonefixo ? (
              <div className="text-red-500 text">
                {String(formik.errors.fonefixo)}
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label htmlFor="email" className="block mb-1 text-gray-700">
              E-mail
            </label>
            <input
              disabled={action === 'show'}
              type="text"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.email && formik.errors.email ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500 text">
                {String(formik.errors.email)}
              </div>
            ) : null}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label htmlFor="whatsapp" className="block mb-1 text-gray-700">
              WhatsApp
            </label>
            <input
              disabled={action === 'show'}
              type="text"
              id="whatsapp"
              name="whatsapp"
              value={formik.values.whatsapp}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.whatsapp && formik.errors.whatsapp ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.whatsapp && formik.errors.whatsapp ? (
              <div className="text-red-500 text">
                {String(formik.errors.whatsapp)}
              </div>
            ) : null}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label htmlFor="celular" className="block mb-1 text-gray-700">
              Celular
            </label>
            <input
              disabled={action === 'show'}
              type="text"
              id="celular"
              name="celular"
              value={formik.values.celular}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.celular && formik.errors.celular ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.celular && formik.errors.celular ? (
              <div className="text-red-500 text">
                {String(formik.errors.celular)}
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label htmlFor="cra" className="block mb-1 text-gray-700">
              CRA
            </label>
            <input
              disabled={action === 'show'}
              type="text"
              id="cra"
              name="cra"
              value={formik.values.cra}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.cra && formik.errors.cra ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.cra && formik.errors.cra ? (
              <div className="text-red-500 text">
                {String(formik.errors.cra)}
              </div>
            ) : null}
          </div>
          <div className="w-full md:w-1/6 px-3 mb-6 md:mb-0">
            <label htmlFor="qtfilhos" className="block mb-1 text-gray-700">
              Qtde. Filhos
            </label>
            <input
              disabled={action === 'show'}
              type="number"
              id="qtfilhos"
              name="qtfilhos"
              value={formik.values.qtfilhos}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.qtfilhos && formik.errors.qtfilhos ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.qtfilhos && formik.errors.qtfilhos ? (
              <div className="text-red-500 text">
                {String(formik.errors.qtfilhos)}
              </div>
            ) : null}
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label htmlFor="indicacao" className="block mb-1 text-gray-700">
              Indicação
            </label>
            <input
              disabled={action === 'show'}
              type="text"
              id="indicacao"
              name="indicacao"
              value={formik.values.indicacao}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.indicacao && formik.errors.indicacao ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.indicacao && formik.errors.indicacao ? (
              <div className="text-red-500 text">
                {String(formik.errors.indicacao)}
              </div>
            ) : null}
          </div>
          <div className="ml-5">
            <label htmlFor="gestante" className="block mb-1 text-gray-700">
              Gestante
            </label>
            <input
              disabled={action === 'show'}
              type="checkbox"
              id="gestante"
              value={formik.values.gestante}
              name="gestante"
              checked={formik.values.gestante === 'S'}
              onChange={(e) =>
                formik.setFieldValue('gestante', e.target.checked ? 'S' : 'N')
              }
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.gestante && formik.errors.gestante ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.gestante && formik.errors.gestante ? (
              <div className="text-red-500 text">
                {String(formik.errors.gestante)}
              </div>
            ) : null}
          </div>
        </div>

        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label htmlFor="dtnasc" className="block mb-1 text-gray-700">
              DT| Provável Parto
            </label>
            <input
              disabled={action === 'show'}
              type="date"
              id="dtprovparto"
              name="dtprovparto"
              value={formik.values.dtprovparto}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.dtprovparto && formik.errors.dtprovparto ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.dtprovparto && formik.errors.dtprovparto ? (
              <div className="text-red-500 text">
                {String(formik.errors.dtprovparto)}
              </div>
            ) : null}
          </div>
          <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label htmlFor="sexo1o" className="block mb-1 text-gray-700">
              Sexo primerio feto
            </label>
            <select
              disabled={action === 'show'}
              id="sexo1o"
              name="sexo1o"
              value={formik.values.sexo1o}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.sexo1o && formik.errors.sexo1o ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            >
              <option value="">Selecione...</option>
              {gender?.map((eth, index) => (
                <option key={index} value={eth.value}>
                  {eth.label}
                </option>
              ))}
            </select>
            {formik.touched.sexo1o && formik.errors.sexo1o ? (
              <div className="text-red-500 text">
                {String(formik.errors.sexo1o)}
              </div>
            ) : null}
          </div>
          <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label htmlFor="sexo2o" className="block mb-1 text-gray-700">
              Sexo segundo feto
            </label>
            <select
              disabled={action === 'show'}
              id="sexo2o"
              name="sexo2o"
              value={formik.values.sexo2o}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.sexo2o && formik.errors.sexo2o ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            >
              <option value="">Selecione...</option>
              {gender?.map((eth, index) => (
                <option key={index} value={eth.value}>
                  {eth.label}
                </option>
              ))}
            </select>
            {formik.touched.sexo2o && formik.errors.sexo2o ? (
              <div className="text-red-500 text">
                {String(formik.errors.sexo2o)}
              </div>
            ) : null}
          </div>
          <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label htmlFor="sexo3o" className="block mb-1 text-gray-700">
              Sexo terceiro feto
            </label>
            <select
              disabled={action === 'show'}
              id="sexo3o"
              name="sexo3o"
              value={formik.values.sexo3o}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.sexo3o && formik.errors.sexo3o ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            >
              <option value="">Selecione...</option>
              {gender?.map((eth, index) => (
                <option key={index} value={eth.value}>
                  {eth.label}
                </option>
              ))}
            </select>
            {formik.touched.sexo3o && formik.errors.sexo3o ? (
              <div className="text-red-500 text">
                {String(formik.errors.sexo3o)}
              </div>
            ) : null}
          </div>
        </div>
        {(action === 'edit' || action === 'new') && (
          <div className="w-full px-3">
            <div className="flex justify-end">
              <button
                type="submit"
                onClick={handleExitWithoutSaving}
                className="mt-4 bg-red-500 text-white font-bold py-2 px-4 rounded mr-3"
              >
                Sair sem salvar
              </button>
              <button
                type="submit"
                className="mt-4 bg-blue-500 text-white font-bold py-2 px-4 rounded"
                onClick={handlePersonalDataValidation}
              >
                Validar Dados do Registro
              </button>
            </div>
            {!formik.isValid && (
              <div className="text-red-500 font-bold mt-2 flex justify-end">
                Preencha os campos obrigatórios.
              </div>
            )}
          </div>
        )}
        {action === 'show' && (
          <div className="w-full px-3">
            <div className="flex justify-end">
              <button
                type="submit"
                onClick={handleExitWithoutSaving}
                className="mt-4 bg-red-500 text-white font-bold py-2 px-4 rounded mr-3"
              >
                Sair
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default CreateFormPersonal;
