import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth, useToast } from 'hooks';

import api from 'services/api';
import { AppProps } from 'interfaces';

interface ErrorProps {
  code: string | number;
  message: string;
}

interface PermissionProps {
  read: boolean;
  write: boolean;
  adminOnly?: boolean;
}

interface CredentialContextData {
  errorHandling(err: Error, altMessage?: string): ErrorProps;
  checkCredentials(): void;
  handlePermission(permission: PermissionProps): void;
  handleReadPermission(permission: PermissionProps): void;
  // checkVersion(): void;
}

const CredentialContext = createContext<CredentialContextData>(
  {} as CredentialContextData,
);

function CredentialsProvider({ children }: AppProps) {
  const navigate = useNavigate();

  const { signOut, user } = useAuth();
  const { addToast } = useToast();

  const errorHandling = useCallback(
    (err: Error, altMessage?: string) => {
      const code = err.message.substr(-3);
      let message = '';
      let title = 'Falha na requisição';

      if (code === '400') {
        message = 'Combinação usuário / senha inválida';
      } else if (code === '401') {
        message = 'Acesso expirado';
      } else if (code === '403') {
        message = 'Usuário não autorizado';
      } else if (code === '404') {
        message = 'Token não encontrado';
      } else if (code === '422') {
        message = 'Acesso inválido';
      } else if (code === '500') {
        message = 'Erro interno. Acione o suporte.';
      } else if (code === '409') {
        message = 'Conflito de informações.';
      } else if (code === '429') {
        title = 'Nova versão disponível';
        message = 'A página será atualizada.';
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        message = 'Erro interno. Acione o suporte.';
      }

      if (altMessage) {
        message = altMessage;
      }

      addToast(
        {
          type: code === '429' ? 'info' : 'error',
          title,
          description: message,
        },
        'version_handler',
      );

      if (['500', '400', '409', '429'].indexOf(code) < 0) {
        signOut();
      }

      return { code, message };
    },
    [signOut, addToast],
  );

  const checkCredentials = useCallback(async () => {
    try {
      await api.get('/token/jwt_validate.php');
    } catch (err) {
      errorHandling(err, err.response.data.message);
    }
  }, [errorHandling]);

  const handlePermission = useCallback(async (permission: PermissionProps) => {
    const { read, write } = permission;
    if (!read && !write) {
      addToast({
        type: 'error',
        title: 'Ação não permitida',
        description: 'Área restrita.',
      });
      navigate('/', { replace: true });
    }
  }, []);

  const handleReadPermission = useCallback(
    async (permission: PermissionProps) => {
      const { read } = permission;
      if (!read) {
        addToast({
          type: 'error',
          title: 'Ação não permitida',
          description: 'Área restrita.',
        });
        navigate('/', { replace: true });
      }
    },
    [],
  );

  // const checkVersion = useCallback(async () => {
  //   try {
  //     const response = await api.get('/version/version.php');
  //   } catch (err) {
  //     errorHandling(err);
  //   }
  // }, []);

  useEffect(() => {
    checkCredentials();
  }, []);

  return (
    <CredentialContext.Provider
      value={{
        errorHandling,
        checkCredentials,
        handlePermission,
        handleReadPermission,
        // checkVersion,
      }}
    >
      {children}
    </CredentialContext.Provider>
  );
}

function useCredentials(): CredentialContextData {
  const context = useContext(CredentialContext);

  if (!context) {
    throw new Error('useCredentials must be used within an CredentialProvider');
  }

  return context;
}

export { CredentialsProvider, useCredentials };
