import React, { useEffect, useCallback, useState } from 'react';

import { useLocation, Link } from 'react-router-dom';
import { useCredentials, useLoading, useLocalStorage } from 'hooks';
import { Action, Container } from 'components';

import api from 'services/api';
import { ComboProps, PersonalProps } from 'interfaces';

import { Actions } from 'styles';

import { removeAcento } from 'utils/specialChars';

import { lsKey } from '../colaborator';

import {
  FilterWrapper,
  Wrapper,
  Child,
  ChildAvatar,
  ChildInfo,
} from './styles';

interface FilterProps {
  name?: string;
  type?: string;
  status?: string;
}

function ColaboradorMain() {
  const { state, pathname } = useLocation();
  const { handlePermission, errorHandling } = useCredentials();
  const { toggleLoading } = useLoading();
  const { deleteLocalStorage } = useLocalStorage();

  const [allPersonal, setAllPersonal] = useState<PersonalProps[]>([]);
  const [personal, setPersonal] = useState<PersonalProps[]>([]);
  const [types, setTypes] = useState<ComboProps[]>([]);
  const [filters, setFilters] = useState<FilterProps>({
    status: 'A',
  } as FilterProps);

  const applyFilters = useCallback(
    (filter: FilterProps) => {
      setFilters(filter);
      let temp = [...allPersonal];
      if (filter.status) {
        temp = temp.filter((item) => item.status === filter.status);
      }
      if (filter.type) {
        temp = temp.filter((item) => item.type === filter.type);
      }
      if (filter.name) {
        temp = temp.filter((item) =>
          removeAcento(item.fullname)
            .toLowerCase()
            .includes(removeAcento(filter.name).toLowerCase()),
        );
      }

      setPersonal(temp);
    },
    [allPersonal],
  );

  const handleGetPersonal = useCallback(async () => {
    toggleLoading();
    try {
      const response = await api.get('/system/colaborador_list.php');
      setAllPersonal(response.data.xpersonal);
      setPersonal(
        response.data.xpersonal.filter((item) => item.status === 'A'),
      );
      setTypes(response.data.xtypes);
    } catch (err) {
      errorHandling(err);
    } finally {
      toggleLoading();
    }
  }, []);

  useEffect(() => {
    console.table(state);
    if (state) {
      handlePermission(state);
    }
    handleGetPersonal();
    deleteLocalStorage(lsKey);
  }, [handlePermission, handleGetPersonal]);

  const handleFilter = useCallback(
    (ev) => {
      const { filterby } = ev.currentTarget.dataset;
      let picked = null;
      if (filterby !== 'name') {
        const select = document.querySelector<HTMLSelectElement>(
          `[data-filterby="${filterby}"]`,
        );
        const { value, text } = select.options[select.selectedIndex];
        picked = value;
      } else {
        picked = ev.target.value;
      }

      const object = { ...filters } as any;
      switch (filterby) {
        case 'type':
          object.type = picked;
          if (picked.length === 0) {
            delete object.type;
          }
          break;
        case 'status':
          object.status = picked;
          break;
        case 'name':
          object.name = picked;
          if (picked.length === 0) {
            delete object.name;
          }
          break;
        default:
          break;
      }
      applyFilters(object);
    },
    [filters, applyFilters],
  );

  return (
    <Container
      screenBanner={<div>Relação dos Colaboradores</div>}
      showIncludeButton={state.write}
    >
      <FilterWrapper>
        Pesquisar:
        <input
          type="search"
          placeholder="Digite nome"
          onChange={handleFilter}
          data-filterby="name"
        />
        Filtro:
        <select
          style={{ width: '120px' }}
          onChange={handleFilter}
          data-filterby="type"
        >
          <option value="">Todos</option>
          {types.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        Situação:
        <select
          style={{ width: '120px' }}
          onChange={handleFilter}
          data-filterby="status"
        >
          <option value="A">Ativos</option>
          <option value="I">Inativos</option>
        </select>
      </FilterWrapper>
      <Wrapper>
        {personal.map((item) => (
          <Child key={item.id}>
            <ChildAvatar>
              <Link to={`/${item.id}/avatar`}>
                <img
                  src={`${process.env.REACT_APP_AVATAR}/${item.avatar}`}
                  alt={item.fullname}
                  style={{ height: '25px' }}
                />
              </Link>
            </ChildAvatar>
            <ChildInfo>
              <p>
                <strong>
                  {item.name}&nbsp;{item.surname}
                </strong>
              </p>
              <p>
                <strong>CPF:</strong>
                &nbsp;{item.cpf}
              </p>
              <p>{item.area}</p>
            </ChildInfo>
            <Actions>
              <Action
                type="show"
                link={{
                  pathname: `${pathname}/${item.id}`,
                  state: { ...state },
                  newtab: true,
                }}
              />
              {state.write ? (
                <Action
                  type="edit"
                  link={{
                    pathname: `${pathname}/${item.id}`,
                    state: { ...state },
                  }}
                />
              ) : null}
            </Actions>
          </Child>
        ))}
      </Wrapper>
    </Container>
  );
}
export default ColaboradorMain;
