import styled from 'styled-components';
import { parseToRgb } from 'polished';
import { animated } from '@react-spring/web';

export const ScreenBannerWrapper = styled(animated.h3)`
  --shadowColor: #0d47a1;
  --bgColor: var(--shadowColor);
  --bgCR: ${parseToRgb('#01579B').red};
  --bgCG: ${parseToRgb('#01579B').green};
  --bgCB: ${parseToRgb('#01579B').blue};
  margin: 15px auto 15px auto;
  width: 90vw;
  max-width: 1366px;

  padding: 5px;
  text-align: center;
  border-radius: 5px;

  text-shadow: 0px 0px 5px var(--shadowColor);
  color: #efefef;

  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(var(--bgCR), var(--bgCG), var(--bgCB), 0.25),
    rgba(var(--bgCR), var(--bgCG), var(--bgCB), 0.5),
    rgba(var(--bgCR), var(--bgCG), var(--bgCB), 1),
    rgba(var(--bgCR), var(--bgCG), var(--bgCB), 1),
    rgba(var(--bgCR), var(--bgCG), var(--bgCB), 1),
    rgba(var(--bgCR), var(--bgCG), var(--bgCB), 1),
    rgba(var(--bgCR), var(--bgCG), var(--bgCB), 1),
    rgba(var(--bgCR), var(--bgCG), var(--bgCB), 1),
    rgba(var(--bgCR), var(--bgCG), var(--bgCB), 1),
    rgba(var(--bgCR), var(--bgCG), var(--bgCB), 0.5),
    rgba(var(--bgCR), var(--bgCG), var(--bgCB), 0.25),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
`;
