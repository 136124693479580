import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";

import AppProvider from 'hooks';
import Routes from 'routes';
import { Modal } from 'components';
import store from 'store';

import "./styles/global.css"

// import { useServiceWorker } from './serviceWorkerRegistrationHandler';
import GlobalStyle from './styles';

function App() {
  // const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const titles = [
    `${process.env.REACT_APP_WEBSITE_NAME}`,
    `v${process.env.REACT_APP_VERSION}`,
  ];

  const [title, setTitle] = useState(0);

  // useEffect(() => {
  //   if (showReload && waitingWorker) {
  //     alert('Uma nova versão está disponível.\nA página será atualizada.');
  //     reloadPage();
  //   }
  // }, [waitingWorker, showReload]);

  useEffect(() => {
    document.title = titles[title];

    setTimeout(() => {
      document.title = titles[title];
      setTitle((state) => (state >= titles.length - 1 ? 0 : state + 1));
    }, 2000);
  }, [title]);
  return (
    <Provider store={store}>
    <BrowserRouter basename={`${process.env.REACT_APP_DEFAULT_DIR}`}>
      <GlobalStyle />
      <AppProvider>
        <Routes />
        <Modal />
      </AppProvider>
    </BrowserRouter>
    </Provider>
  );
}

export default App;
