import React, { ButtonHTMLAttributes, useEffect } from 'react';

import { AppProps } from 'interfaces';
import { Wrapper } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  containerStyle?: object;
  bgcolor?: string;
  animated?: boolean;
  delay?: number;
} & AppProps;

function Button({
  loading,
  containerStyle,
  bgcolor,
  animated,
  delay,
  children,
  ...rest
}: ButtonProps) {
  return (
    <Wrapper
      type="button"
      style={containerStyle}
      {...rest}
      bgcolor={bgcolor}
      animated={animated || undefined}
      delay={delay}
    >
      {loading ? 'Aguarde' : children}
    </Wrapper>
  );
}

Button.defaultProps = {
  loading: false,
  containerStyle: {},
  bgcolor: '#2d517a',
  // bgcolor: '#283593',
  animated: true,
  delay: 0,
};

/*
children,
  loading,

*/
export default Button;
