import styled, { css } from 'styled-components';

export const Container = styled.div`
  z-index: 350;
  position: fixed;
  top: 0;
  right: 0;
  padding: 1rem;
  overflow: hidden;

  transition: top 0.35s, right 0.35s;
`;
