import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AiOutlineDelete } from 'react-icons/ai';
import { useToast } from 'hooks/toast';

const ChildForm = ({
  action,
  index,
  child,
  onRemove,
  onUpdate,
  ethnicity,
  gender,
}) => {
  const { addToast } = useToast();

  const formik = useFormik({
    initialValues: child,
    validationSchema: Yup.object({
      nome: Yup.string().required('Nome é obrigatório'),
      sobrenome: Yup.string().required('Sobrenome é obrigatório'),
    }),
    onSubmit: (values) => {
      addToast({
        type: 'success',
        title: 'Criança Salva!',
        description: 'Criança adicionada com sucesso.',
        seconds: 3,
      });
      onUpdate(values);
    },
  });

  const handleAddVaccine = () => {
    formik.setFieldValue('vacinas', [
      ...formik.values.vacinas,
      {
        dose: '',
        desc: '',
        data: '',
        lote: '',
        laboratorio: '',
        unidade: '',
        aplicador: '',
      },
    ]);
  };

  const handleRemoveVaccine = (index) => {
    const newVacinas = formik.values.vacinas.filter((_, i) => i !== index);
    formik.setFieldValue('vacinas', newVacinas);
  };

  return (
    <div className="card bg-gray-100 p-4 mb-4 rounded shadow container-fadeIn">
      <h3>Criança {index + 1}</h3>
      <form
        id="childrenForm"
        onSubmit={formik.handleSubmit}
        className="container-fadeIn"
      >
        <div className="flex space-x-4">
          <div className="w-1/3">
            <label className="block mb-1 text-gray-700">Nome</label>
            <input
              disabled={action === 'show'}
              type="text"
              name="nome"
              onChange={formik.handleChange}
              value={formik.values.nome}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.nome && formik.errors.nome ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.nome && formik.errors.nome ? (
              <div className="text-red-500">{String(formik.errors.nome)}</div>
            ) : null}
          </div>
          <div className="w-1/3">
            <label className="block mb-1 text-gray-700">Sobrenome</label>
            <input
              disabled={action === 'show'}
              type="text"
              name="sobrenome"
              onChange={formik.handleChange}
              value={formik.values.sobrenome}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.sobrenome && formik.errors.sobrenome ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.sobrenome && formik.errors.sobrenome ? (
              <div className="text-red-500">
                {String(formik.errors.sobrenome)}
              </div>
            ) : null}
          </div>
          <div>
            <label className="block mb-1 text-gray-700">
              Data de Nascimento
            </label>
            <input
              disabled={action === 'show'}
              type="date"
              name="dataNascimento"
              onChange={formik.handleChange}
              value={formik.values.dataNascimento}
              className={`border ${formik.touched.dataNascimento && formik.errors.dataNascimento ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
          </div>
          <div>
            <label className="block mb-1 text-gray-700">Hora</label>
            <input
              disabled={action === 'show'}
              type="time"
              name="hora"
              onChange={formik.handleChange}
              value={formik.values.hora}
              className={`border ${formik.touched.hora && formik.errors.hora ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
          </div>
          <div className="w-1/4">
            <label className="block mb-1 text-gray-700">Sexo</label>
            <select
              disabled={action === 'show'}
              id="sexo"
              name="sexo"
              value={formik.values.sexo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.sexo && formik.errors.sexo ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            >
              <option value="">Selecione...</option>
              {gender?.map((eth, index) => (
                <option key={index} value={eth.value}>
                  {eth.label}
                </option>
              ))}
            </select>
          </div>
          <div className="w-1/4">
            <label className="block mb-1 text-gray-700">Etnia</label>

            <select
              disabled={action === 'show'}
              id="etnia"
              name="etnia"
              value={formik.values.etnia}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.etnia && formik.errors.etnia ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            >
              <option value="">Selecione...</option>
              {ethnicity?.map((eth, index) => (
                <option key={index} value={eth.value}>
                  {eth.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex space-x-4 mt-2 mb-2">
          <div className="w-1/4">
            <label className="block mb-1 text-gray-700">RG</label>
            <input
              disabled={action === 'show'}
              type="text"
              name="rg"
              onChange={formik.handleChange}
              value={formik.values.rg}
              className={`border ${formik.touched.rg && formik.errors.rg ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
          </div>
          <div className="">
            <label className="block mb-1 text-gray-700">CPF</label>
            <input
              disabled={action === 'show'}
              type="text"
              name="cpf"
              onChange={formik.handleChange}
              value={formik.values.cpf}
              className={`border ${formik.touched.cpf && formik.errors.cpf ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
          </div>
          <div>
            <label className="block mb-1 text-gray-700">CIN</label>
            <input
              disabled={action === 'show'}
              type="text"
              name="cin"
              onChange={formik.handleChange}
              value={formik.values.cin}
              className={`border ${formik.touched.cin && formik.errors.cin ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
          </div>
          <div>
            <label className="block mb-1 text-gray-700">
              Tamanho ao Nascer
            </label>
            <input
              disabled={action === 'show'}
              type="text"
              name="tamNas"
              onChange={formik.handleChange}
              value={formik.values.tamNas}
              className={`border ${formik.touched.tamNas && formik.errors.tamNas ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
          </div>
          <div>
            <label className="block mb-1 text-gray-700">Peso ao Nascer</label>
            <input
              disabled={action === 'show'}
              type="text"
              name="pesoNasc"
              onChange={formik.handleChange}
              value={formik.values.pesoNasc}
              className={`border ${formik.touched.pesoNasc && formik.errors.pesoNasc ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
          </div>
          <div>
            <label className="block mb-1 text-gray-700">Tamanho Atual</label>
            <input
              disabled={action === 'show'}
              type="text"
              name="tamAtual"
              onChange={formik.handleChange}
              value={formik.values.tamAtual}
              className={`border ${formik.touched.tamAtual && formik.errors.tamAtual ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
          </div>
          <div>
            <label className="block mb-1 text-gray-700">Peso Atual</label>
            <input
              disabled={action === 'show'}
              type="text"
              name="pesoAtual"
              onChange={formik.handleChange}
              value={formik.values.pesoAtual}
              className={`border ${formik.touched.pesoAtual && formik.errors.pesoAtual ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
          </div>
        </div>
        <div>
          <label className="block mb-1 text-gray-700">
            Ocorrências durante o parto
          </label>
          <textarea
            disabled={action === 'show'}
            rows={6}
            name="ocorrencias"
            onChange={formik.handleChange}
            value={formik.values.ocorrencias}
            className="resize-none border rounded-md p-2 w-full"
          />
        </div>
        <h3>Vacinas</h3>
        {formik.values.vacinas?.map((vacina, index) => (
          <div className="flex space-x-4" key={index}>
            <div className="w-1/6">
              <label className="block mb-1 text-gray-700">Dose</label>
              <input
                disabled={action === 'show'}
                type="text"
                name={`vacinas[${index}].dose`}
                onChange={formik.handleChange}
                value={vacina.dose}
                className={`border ${formik.touched.dose && formik.errors.dose ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
              />
            </div>
            <div className="w-1/6">
              <label className="block mb-1 text-gray-700">Descrição</label>
              <input
                disabled={action === 'show'}
                type="text"
                name={`vacinas[${index}].desc`}
                onChange={formik.handleChange}
                value={vacina.desc}
                className={`border ${formik.touched.desc && formik.errors.desc ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
              />
            </div>
            <div className="w-1/6">
              <label className="block mb-1 text-gray-700">Data</label>
              <input
                disabled={action === 'show'}
                type="date"
                name={`vacinas[${index}].data`}
                onChange={formik.handleChange}
                value={vacina.data}
                className={`border ${formik.touched.data && formik.errors.data ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
              />
            </div>
            <div className="w-1/6">
              <label className="block mb-1 text-gray-700">Lote</label>
              <input
                disabled={action === 'show'}
                type="text"
                name={`vacinas[${index}].lote`}
                onChange={formik.handleChange}
                value={vacina.lote}
                className={`border ${formik.touched.lote && formik.errors.lote ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
              />
            </div>
            <div className="w-1/6">
              <label className="block mb-1 text-gray-700">Laboratório</label>
              <input
                disabled={action === 'show'}
                type="text"
                name={`vacinas[${index}].laboratorio`}
                onChange={formik.handleChange}
                value={vacina.laboratorio}
                className={`border ${formik.touched.laboratorio && formik.errors.laboratorio ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
              />
            </div>
            <div className="w-1/6">
              <label className="block mb-1 text-gray-700">Unidade</label>
              <input
                disabled={action === 'show'}
                type="text"
                name={`vacinas[${index}].unidade`}
                onChange={formik.handleChange}
                value={vacina.unidade}
                className={`border ${formik.touched.unidade && formik.errors.unidade ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
              />
            </div>
            <div className="w-1/6">
              <label className="block mb-1 text-gray-700">Aplicador</label>
              <input
                disabled={action === 'show'}
                type="text"
                name={`vacinas[${index}].aplicador`}
                onChange={formik.handleChange}
                value={vacina.aplicador}
                className={`border ${formik.touched.aplicador && formik.errors.aplicador ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
              />
            </div>
            <div className="adjust-btn-remove-vacina mt-4">
              <AiOutlineDelete
                size={20}
                onClick={() => handleRemoveVaccine(index)}
                className="bg-red-500 text-white px-2 py-1 rounded cursor-pointer"
                title="Remover Vacina"
              />
            </div>
          </div>
        ))}
        {(action === 'edit' || action === 'new') && (
          <div>
            <button
              type="submit"
              className={`mt-4 bg-green-500 text-white font-bold py-2 px-4 rounded mr-4 
                    }`}
            >
              Salvar
            </button>
            <button
              type="button"
              onClick={onRemove}
              className="bg-red-500 text-white px-4 py-2 rounded mt-2 ml-4"
            >
              Remover
            </button>
            <button
              type="button"
              onClick={handleAddVaccine}
              className="bg-blue-500 text-white px-4 py-2 rounded mt-2 ml-4"
            >
              Adicionar Vacina
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default ChildForm;
