import React, { useEffect, useState } from 'react';
import { Container } from 'components';
import { Wrapper } from 'components/Container/styles';
import CreateFormPersonal from 'pages/Assist/Create-Form-Personal';
import CreateFormAddress from 'pages/Assist/Create-Form-Address';

import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCombosAddress,
  fetchCombosEducation,
  fetchCombosPersonal,
} from 'pages/common/store/commonThunk';
import { useLocation, useParams } from 'react-router-dom';
import CreateFormRegister from 'pages/Assist/Create-Form-Register';
import CreateFormComplement from 'pages/Assist/Create-Form-Complement';
import CreateFormEducation from 'pages/Assist/Create-Form-Education';
import CreateFormChildren from 'pages/Assist/Create-Form-Children';
import CreateFormFamily from 'pages/Assist/Create-Form-Family';
import {
  changeAssistById,
  fetchAssistById,
  saveAssist,
} from 'pages/Assist/shared/state/assistThunk';
import { useLoading } from 'hooks';
import { useToast } from 'hooks/toast';

const initialFormData: any = {
  pessoa: {
    nome: '',
    sobrenome: '',
    dtnasc: '',
    rg: '',
    orgemiss: '',
    dtexpedrg: '',
    cin: '',
    cpf: '',
    religiao: '',
    estcivil: '',
    naturalidade: '',
    dtcadastramento: '',
    dtinativacao: '',
    sexo: '',
    orientsex: '',
    etnia: '',
    fonefixo: '',
    email: '',
    whatsapp: '',
    celular: '',
    cra: '',
    qtfilhos: 0,
    indicacao: '',
    gestante: '',
    dtprovparto: '',
    sexo1o: '',
    sexo2o: '',
    sexo3o: '',
    registro: '',
    dataEntrada: '',
    dataSaida: '',
    situacao: '',
    tipo: '',
    avatar: '',
  },
  endereco: {
    cep: '',
    logradouro: '',
    endereco: '',
    num: '',
    moradia: '',
    compl: '',
    bairro: '',
    cidade: '',
    uf: '',
    zona: '',
    ptrefer: '',
    residentes: '',
    comodos: '',
    moraconj: '',
    moramae: '',
    morapai: '',
    morairm: '',
    moraavom: '',
    moraavop: '',
    morafilho: '',
    moramad: '',
    morapad: '',
    moraout: '',
  },
  complemento: {
    indlei: 'N',
    detlei: '',
    indmed: 'N',
    detmed: '',
    indvio: 'N',
    detvio: '',
    indsaude: 'N',
    detsaude: '',
    indtrat: 'N',
    dettrat: '',
    indcont: 'N',
    detcont: '',
    inddep: 'N',
    detdep: '',
    indquim: 'N',
    detquim: '',
    indabort: 'N',
    detabort: '',
    detobs: '',
  },
  escolaridade: {
    nivel: '',
    situacao: '',
    ultinstit: '',
    periodo: '',
    extra: '',
    dadosextra: '',
  },
  criancas: [
    {
      nome: '',
      sobrenome: '',
      dataNascimento: '',
      hora: '',
      sexo: '',
      etnia: '',
      rg: '',
      cpf: '',
      cin: '',
      tamNas: '',
      pesoNasc: '',
      tamAtual: '',
      pesoAtual: '',
      ocorrencias: '',
      vacinas: [
        {
          dose: '',
        },
      ],
    },
  ],
  conjuge: {
    nome: '',
    sobrenome: '',
    dtnasc: '',
    rg: '',
    cpf: '',
    cin: '',
    conjpai: '',
  },
  parentes: [
    {
      tipo: '', //filho, pai, etc...
      nome: '',
      idade: '',
      dataNascimento: '',
      escolaridade: '',
      renda: 0,
      profissao: '',
    },
  ],
  familiar: {
    refcras: '',
    refcreas: '',
    benbol: '',
    benloas: '',
    benalu: '',
    benpen: '',
    benrec: '',
    bencesta: '',
    benout: '',
    vt: '',
    cesta: '',
    detoutben: '',
    local: '',
    despalim: '',
    despagua: '',
    despener: '',
    despout: '',
    totbenfam: '',
    totrendafam: '',
  },
};

const CreateAssist: React.FC = () => {
  const [activeTab, setActiveTab] = useState(7);
  const location = useLocation();

  const pathSegments = location.pathname.split('/');
  const action = pathSegments[pathSegments.length - 1];
  const [formStatus, setFormStatus] = useState({
    addressForm: { isValid: false, formik: null },
    personalForm: { isValid: false, formik: null },
    educationForm: { isValid: false, formik: null },
  });
  const { id } = useParams();
  const assistida = useSelector((state: any) => state.assist.individual);
  const [formData, setFormData] = useState<any>(initialFormData);

  const dispatch = useDispatch();
  const { toggleLoading } = useLoading();
  const { addToast } = useToast();

  useEffect(() => {
    dispatch(fetchCombosPersonal());
    dispatch(fetchCombosAddress());
    dispatch(fetchCombosEducation());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      toggleLoading();
      dispatch(fetchAssistById(id) as any)
        .then(() => {
          handleTabChange(1);
          return toggleLoading();
        })
        .catch((error) => {
          console.error('Error fetching assistida:', error);
          toggleLoading();
        });
    } else {
      setFormData(initialFormData);
    }
  }, [id, dispatch, toggleLoading]);

  useEffect(() => {
    if (assistida && id) {
      setFormData(assistida);
      handleTabChange(1);
    } else {
      if (action === 'new') handleTabChange(1);
    }
  }, [assistida, id]);

  const {
    marital_status,
    ethnicity,
    sexual_preference,
    religion,
    gender,
    address_type,
    habitation,
    uf,
    zone,
    educationLevel,
    educationStatus,
    educationPeriod,
  } = useSelector((state: any) => state?.common);

  const getBannerText = (action) => {
    switch (action) {
      case 'new':
        return 'Nova Assistida';
      case 'edit':
        return 'Editar Assistida';
      case 'show':
        return 'Visualizar Assistida';
      default:
        return 'Formulário Assistidas';
    }
  };

  const handleTabChange = (tab) => {
    if (activeTab === 1) {
      setFormData((prevData: any) => {
        return {
          ...prevData,
          pessoa: {
            ...prevData.pessoa,
            ...formData.pessoa,
          },
        };
      });
    } else if (activeTab === 2) {
      setFormData((prevData: any) => {
        return {
          ...prevData,
          endereco: {
            ...prevData.endereco,
            ...formData.endereco,
          },
        };
      });
    } else if (activeTab === 3) {
      setFormData((prevData: any) => {
        return {
          ...prevData,
          escolaridade: formData.escolaridade,
        };
      });
    } else if (activeTab === 4) {
      setFormData((prevData: any) => {
        return {
          ...prevData,
          conjuge: formData.conjuge,
          parentes: formData.parentes,
          familiar: formData.familiar,
        };
      });
    } else if (activeTab === 5) {
      setFormData((prevData: any) => {
        return {
          ...prevData,
          complemento: formData.complemento,
        };
      });
    } else if (activeTab === 6) {
      setFormData((prevData: any) => {
        return {
          ...prevData,
          criancas: formData.criancas,
        };
      });
    } else if (activeTab === 7) {
      const values = getFormValues('registerForm');

      setFormData((prevData: any) => ({
        ...prevData,
        pessoa: {
          ...prevData.pessoa,
          ...values,
        },
      }));
    }

    setActiveTab(tab);
  };

  const getFormValues = (formId) => {
    const form = document.getElementById(formId);
    if (!form) return {};
    const inputs = form.querySelectorAll('input, select, textarea');
    const values = {};
    inputs.forEach((input: any) => {
      values[input.name] = input.value;
    });
    return values;
  };

  const canTabFamiliy = () => {
    const { estcivil, qtfilhos } = formData?.pessoa || {};
    const validEstadosCivis = ['C', 'D', 'E'];
    return validEstadosCivis.includes(estcivil) || qtfilhos > 0;
  };

  const areAllFormsValid = Object.values(formStatus).every(
    (form) => form.isValid,
  );

  const handleFormStatusUpdate = (formName, isValid, formik) => {
    setFormStatus((prevStatus) => ({
      ...prevStatus,
      [formName]: { isValid, formik },
    }));
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (areAllFormsValid) {
      if (action === 'edit') {
        dispatch(changeAssistById({ id, assistida: formData }) as any)
          .then(() => {
            return addToast({
              type: 'info',
              title: 'Assistida!',
              description: 'Alteração realizada com sucesso!',
              seconds: 3,
            });
          })
          .catch((err) => console.error(err));
      } else {
        dispatch(saveAssist(formData) as any)
          .then(() => {
            return addToast({
              type: 'info',
              title: 'Assistida!',
              description: 'Salvo com sucesso!',
              seconds: 3,
            });
          })
          .catch((err) => console.error(err));
      }
    }
  };

  const TabItem = ({ activeTab, tab, title, onClick, disabled }) => {
    const isActive = activeTab === tab;

    return (
      <li className="me-2">
        <a
          href="#"
          className={`inline-block p-6 border-b-2 border-transparent rounded-t-lg ${
            isActive
              ? 'text-white bg-blue-600 border-blue-600'
              : 'text-gray-500 hover:text-gray-600 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300 dark:hover:border-gray-300'
          } ${disabled ? 'pointer-events-none opacity-50' : ''}`}
          onClick={() => !disabled && onClick(tab)}
        >
          {title}
        </a>
      </li>
    );
  };

  return (
    <>
      <Container screenBanner={<div>{getBannerText(action)}</div>} hideNavbar>
        <Wrapper>
          <div className="container-center min-h-screen flex align-center">
            <div className="w-full mt-8">
              <div className="mx-8">
                <div className="card-tab bg-white shadow-md rounded-lg">
                  <div className="tab-top text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                    <ul className="flex flex-wrap -mb-px">
                      <TabItem
                        disabled={false}
                        activeTab={activeTab}
                        tab={1}
                        title="Dados Pessoais"
                        onClick={() => handleTabChange(1)}
                      />
                      <TabItem
                        disabled={false}
                        activeTab={activeTab}
                        tab={2}
                        title="Endereço"
                        onClick={() => handleTabChange(2)}
                      />
                      <TabItem
                        disabled={false}
                        activeTab={activeTab}
                        tab={3}
                        title="Escolaridade"
                        onClick={() => handleTabChange(3)}
                      />
                      <TabItem
                        disabled={!canTabFamiliy()}
                        activeTab={activeTab}
                        tab={4}
                        title="Familiar"
                        onClick={() => handleTabChange(4)}
                      />
                      <TabItem
                        disabled={false}
                        activeTab={activeTab}
                        tab={5}
                        title="Complemento"
                        onClick={() => handleTabChange(5)}
                      />
                      <TabItem
                        disabled={false}
                        activeTab={activeTab}
                        tab={6}
                        title="Criança"
                        onClick={() => handleTabChange(6)}
                      />
                      <TabItem
                        disabled={false}
                        activeTab={activeTab}
                        tab={7}
                        title="Registro"
                        onClick={() => handleTabChange(7)}
                      />
                    </ul>
                  </div>

                  <div className="p-6">
                    <div className="border-forms border border-gray-400 rounded-lg p-4 overflow-y-auto max-h-screen">
                      {activeTab === 1 && (
                        <CreateFormPersonal
                          id="personalForm"
                          action={action}
                          formData={formData.pessoa}
                          onUpdateFormStatus={(isValid, formik) =>
                            handleFormStatusUpdate(
                              'personalForm',
                              isValid,
                              formik,
                            )
                          }
                          setFormData={(data) =>
                            setFormData({ ...formData, pessoa: data })
                          }
                          marital_status={marital_status}
                          ethnicity={ethnicity}
                          sexual_preference={sexual_preference}
                          religion={religion}
                          gender={gender}
                          handleTabChange={handleTabChange}
                        />
                      )}
                      {activeTab === 2 && (
                        <CreateFormAddress
                          id="addressForm"
                          action={action}
                          onUpdateFormStatus={(isValid, formik) =>
                            handleFormStatusUpdate(
                              'addressForm',
                              isValid,
                              formik,
                            )
                          }
                          formData={formData.endereco}
                          setFormData={(data) =>
                            setFormData({ ...formData, endereco: data })
                          }
                          address_type={address_type}
                          habitation={habitation}
                          uf={uf}
                          zone={zone}
                          handleTabChange={handleTabChange}
                        />
                      )}
                      {activeTab === 3 && (
                        <CreateFormEducation
                          action={action}
                          onUpdateFormStatus={(isValid, formik) =>
                            handleFormStatusUpdate(
                              'educationForm',
                              isValid,
                              formik,
                            )
                          }
                          formData={formData.escolaridade}
                          setFormData={(data) => {
                            return setFormData({
                              ...formData,
                              escolaridade: data,
                            });
                          }}
                          educationLevel={educationLevel}
                          educationStatus={educationStatus}
                          educationPeriod={educationPeriod}
                          handleTabChange={handleTabChange}
                        />
                      )}
                      {activeTab === 4 && canTabFamiliy() && (
                        <CreateFormFamily
                          action={action}
                          formData={formData}
                          setFormData={(data) => {
                            return setFormData({
                              ...formData,
                              conjuge: data.conjuge,
                              parentes: data.parentes,
                              familiar: data.familiar,
                            });
                          }}
                        />
                      )}

                      {activeTab === 5 && (
                        <CreateFormComplement
                          action={action}
                          handleTabChange={handleTabChange}
                          formData={formData.complemento}
                          setFormData={(data) =>
                            setFormData({ ...formData, complemento: data })
                          }
                          onUpdateFormStatus={(isValid, formik) =>
                            handleFormStatusUpdate(
                              'complementForm',
                              isValid,
                              formik,
                            )
                          }
                        />
                      )}
                      {activeTab === 6 && (
                        <CreateFormChildren
                          action={action}
                          handleTabChange={handleTabChange}
                          formData={formData.criancas}
                          setFormData={(data) => {
                            return setFormData({ ...formData, criancas: data });
                          }}
                          onUpdateFormStatus={(isValid, formik) =>
                            handleFormStatusUpdate(
                              'childrenForm',
                              isValid,
                              formik,
                            )
                          }
                        />
                      )}
                      {activeTab === 7 && (
                        <CreateFormRegister
                          action={action}
                          id="registerForm"
                          formData={formData.pessoa}
                          onUpdateFormStatus={(isValid, formik) =>
                            handleFormStatusUpdate(
                              'registerForm',
                              isValid,
                              formik,
                            )
                          }
                          setFormData={(data) =>
                            setFormData({ ...formData, pessoa: data })
                          }
                          onSave={handleSave}
                          canSave={areAllFormsValid}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      </Container>
    </>
  );
};

export default CreateAssist;
