import React, { useCallback, useEffect, useState } from 'react';
import { useLocalStorage, useLoading } from 'hooks';
import { FormDateInput, FormInput, FormSelect } from 'components';
import api from 'services/api';
import { ComboProps } from 'interfaces';
import { FaUserPlus, FaUserTimes } from 'react-icons/fa';
import { MdSaveAs } from 'react-icons/md';
import ComponentWrapper from '../wrapper';
import { ColaboratorFamilyMembers, lsKey } from '../../colaborator';
import { AddButton, RemoveButton, UpdateButton } from './styles';

interface FamilyChildren {
  seq: string;
  name: string;
  surname: string;
  birth: string;
  gender: string;
}

interface FamilyProps {
  familyInfo: ColaboratorFamilyMembers;
  mode?: 'insert' | 'update' | 'show';
  data?: any;
}

function ColaboradorFamily({ familyInfo, mode, data }: FamilyProps) {
  const { getLocalStorage, setLocalStorage, deleteLocalStorageItemKey } =
    useLocalStorage();
  const { toggleLoading } = useLoading();
  const [children, setChildren] = useState<FamilyChildren[]>(() => {
    const locallyStored = getLocalStorage(lsKey) as any;
    if (locallyStored.children_info) {
      return JSON.parse(locallyStored.children_info);
    }
    return [];
  });

  const [genders, setGenders] = useState<ComboProps[]>([]);
  const [prefixed, setPrefixed] = useState<any>({} as any);

  const [enableCompanion, setEnableCompanion] = useState(false);

  const handleGetCombos = useCallback(async () => {
    toggleLoading();
    try {
      const response = await api.get('/combos/sexo.php');
      setGenders(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      toggleLoading();
    }
  }, [toggleLoading]);

  useEffect(() => {
    handleGetCombos();
    const locallyStored: any = getLocalStorage(lsKey);
    if (Object.keys(data).length > 0) {
      if (mode === 'update') {
        setPrefixed({ ...locallyStored });
      } else {
        setPrefixed({ ...data });
        setChildren(data.children);
      }
    } else {
      if (locallyStored) {
        setPrefixed({ ...locallyStored });
      }
    }

    setEnableCompanion(['C', 'D', 'E'].indexOf(familyInfo.maritalStatus) > -1);
  }, [handleGetCombos, mode, data, familyInfo.maritalStatus]);

  const addRow = useCallback(() => {
    const locallyStored = getLocalStorage(lsKey) as any;
    let existentChildren = [];
    if (locallyStored.children_info) {
      existentChildren = JSON.parse(locallyStored.children_info);
    }

    const cname = document.querySelector<HTMLInputElement>(
      '[data-input="child_name"]',
    );
    const csurname = document.querySelector<HTMLInputElement>(
      '[data-input="child_surname"]',
    );
    const cbirth = document.querySelector<HTMLInputElement>(
      '[data-input="child_birth"]',
    );
    const cgender = document.querySelector<HTMLInputElement>(
      '[data-input="child_gender"]',
    );

    if (!!cname?.value && csurname?.value && cbirth?.value && cgender?.value) {
      const newChild = {
        seq: `${existentChildren.length + 1}`,
        name: cname.value,
        surname: csurname.value,
        birth: cbirth.value,
        gender: cgender.value,
      } as FamilyChildren;
      setChildren((existent) => [...existent, newChild]);
      existentChildren.push(newChild);

      setTimeout(() => {
        cname.value = '';
        csurname.value = '';
        cbirth.value = '';
        cgender.value = '';
        cname.focus();

        setLocalStorage(lsKey, {
          ...locallyStored,
          children_info: JSON.stringify(existentChildren),
        });
      }, 25);
    }
  }, [getLocalStorage, setLocalStorage]);

  const removeRow = useCallback(
    (ev) => {
      const { child } = ev.currentTarget.dataset;
      const locallyStored = getLocalStorage(lsKey) as any;
      let existentChildren = [];
      if (locallyStored.children_info) {
        existentChildren = JSON.parse(locallyStored.children_info).filter(
          (item) => item.seq !== child,
        );
      }

      setChildren((existent) => existent.filter((item) => item.seq !== child));

      if (existentChildren.length === 0) {
        deleteLocalStorageItemKey(lsKey, ['children_info']);
      } else {
        setLocalStorage(lsKey, {
          ...locallyStored,
          children_info: JSON.stringify(existentChildren),
        });
      }

      // TODO: understand how to solve child removal
    },
    [getLocalStorage, setLocalStorage],
  );

  const saveChanges = useCallback(
    (ev) => {
      const { child } = ev.currentTarget.dataset;

      const locallyStored = getLocalStorage(lsKey) as any;
      let existentChildren = [];
      if (locallyStored.children_info) {
        existentChildren = JSON.parse(locallyStored.children_info);
      }

      const cname = document.querySelector<HTMLInputElement>(
        `input[name="child_name-${child}"]`,
      );
      const csurname = document.querySelector<HTMLInputElement>(
        `input[name="child_surname-${child}"]`,
      );
      const cbirth = document.querySelector<HTMLInputElement>(
        `input[name="child_birth-${child}"]`,
      );
      const cgender = document.querySelector<HTMLInputElement>(
        `select[name="child_gender-${child}"]`,
      );

      const updatedValues = {
        name: cname?.value || '',
        surname: csurname?.value || '',
        birth: cbirth?.value || '',
        gender: cgender?.value || '',
      };

      if (
        !!cname?.value &&
        csurname?.value &&
        cbirth?.value &&
        cgender?.value
      ) {
        const index = existentChildren.map((item) => item.seq).indexOf(child);
        existentChildren[index] = {
          ...existentChildren[index],
          ...updatedValues,
        };

        setChildren(existentChildren);

        setLocalStorage(lsKey, {
          ...locallyStored,
          children_info: JSON.stringify(existentChildren),
        });
      }
    },
    [getLocalStorage, setLocalStorage],
  );

  const customStyle = { width: '100%' };
  const fieldsetStyle = { width: '100%' };
  const customAllStyle = { width: '85%' };
  const customNameStyle = { width: '90%' };
  const customSurNameStyle = { width: '90%' };
  const customGenderStyle = { width: '100%' };

  return (
    <ComponentWrapper sectionName="Familiares">
      <div>
        <fieldset disabled={!enableCompanion} style={fieldsetStyle}>
          <legend>Dados do Cônjuge</legend>
          <div>
            <FormInput
              name="companion_name"
              placeholder="Nome"
              label="Nome"
              defaultValue={prefixed.companion_name || null}
              containerstyle={customStyle}
              disabled={mode === 'show'}
            />
            <FormInput
              name="companion_surname"
              placeholder="Sobrenome"
              label="Sobrenome"
              defaultValue={prefixed.companion_surname || null}
              containerstyle={customStyle}
              disabled={mode === 'show'}
            />

            <FormDateInput
              name="companion_birth"
              label="Data Nascimento"
              picked={prefixed.companion_birth}
              containerstyle={customStyle}
              disabled={mode === 'show'}
            />
          </div>
          <div>
            <FormInput
              name="companion_rg"
              placeholder="RG"
              label="RG"
              defaultValue={prefixed.companion_rg || null}
              containerstyle={customStyle}
              disabled={mode === 'show'}
            />
            <FormInput
              name="companion_cpf"
              placeholder="CPF"
              label="CPF"
              mask="cpf"
              defaultValue={prefixed.companion_cpf || null}
              containerstyle={customStyle}
              disabled={mode === 'show'}
            />
            <FormInput
              name="companion_cin"
              placeholder="CIN"
              label="CIN"
              defaultValue={prefixed.companion_cin || null}
              containerstyle={customStyle}
              disabled={mode === 'show'}
            />
            <FormSelect
              name="companion_gender"
              label="Sexo"
              content={genders}
              initial={prefixed.companion_gender || null}
              containerstyle={customStyle}
              disabled={mode === 'show'}
            />
          </div>
          <div>
            <FormInput
              name="companion_phone"
              placeholder="N° telefone"
              mask="phone"
              label="N° Telefone (Fixo)"
              defaultValue={prefixed.companion_phone || null}
              containerstyle={customStyle}
              disabled={mode === 'show'}
            />
            <FormInput
              name="companion_mobile"
              placeholder="N° telefone"
              mask="phone"
              label="N° Telefone (Celular)"
              defaultValue={prefixed.companion_mobile || null}
              containerstyle={customStyle}
              disabled={mode === 'show'}
            />
            <FormInput
              name="companion_mail"
              placeholder="E-Mail"
              type="email"
              label="E-mail"
              defaultValue={prefixed.companion_mail || null}
              containerstyle={customStyle}
              disabled={mode === 'show'}
            />
          </div>
        </fieldset>
      </div>

      <div>
        <fieldset style={fieldsetStyle}>
          <legend>Filhos do colaborador</legend>
          <table>
            <thead>
              <tr style={{ textAlign: 'left' }}>
                <th>Nome</th>
                <th>Sobrenome</th>
                <th>Nascimento</th>
                <th>Sexo</th>
              </tr>
            </thead>
            <tbody>
              {children.map((item) => (
                <tr key={item.seq}>
                  <td>
                    <FormInput
                      name={`child_name-${item.seq}`}
                      defaultValue={item.name}
                      placeholder="Nome"
                      containerstyle={customNameStyle}
                      disabled={mode === 'show'}
                    />
                  </td>
                  <td>
                    <FormInput
                      name={`child_surname-${item.seq}`}
                      defaultValue={item.surname}
                      placeholder="Sobrenome"
                      containerstyle={customSurNameStyle}
                      disabled={mode === 'show'}
                    />
                  </td>
                  <td>
                    <FormDateInput
                      name={`child_birth-${item.seq}`}
                      picked={item.birth}
                      containerstyle={customAllStyle}
                      disabled={mode === 'show'}
                    />
                  </td>
                  <td>
                    <FormSelect
                      name={`child_gender-${item.seq}`}
                      content={genders}
                      initial={item.gender}
                      containerstyle={{ ...customGenderStyle, padding: '0px' }}
                      disabled={mode === 'show'}
                    />
                  </td>
                  {mode !== 'show' ? (
                    <td
                      style={{
                        display: 'flex',
                        columnGap: '7px',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                      }}
                    >
                      <UpdateButton
                        type="button"
                        onClick={saveChanges}
                        data-child={item.seq}
                      >
                        <MdSaveAs />
                      </UpdateButton>
                      <RemoveButton
                        type="button"
                        onClick={removeRow}
                        data-child={item.seq}
                      >
                        <FaUserTimes />
                      </RemoveButton>
                    </td>
                  ) : null}
                </tr>
              ))}

              {mode !== 'show' ? (
                <tr>
                  <td>
                    <FormInput
                      name="child_name"
                      type="text"
                      placeholder="Nome"
                      data-input="child_name"
                      containerstyle={customNameStyle}
                    />
                  </td>
                  <td>
                    <FormInput
                      name="child_surname"
                      type="text"
                      placeholder="Sobrenome"
                      data-input="child_surname"
                      containerstyle={customSurNameStyle}
                    />
                  </td>
                  <td>
                    <FormDateInput
                      name="child_birth"
                      data-input="child_birth"
                      containerstyle={customAllStyle}
                    />
                  </td>
                  <td>
                    <FormSelect
                      data-input="child_gender"
                      name="child_gender"
                      content={genders}
                      initial="Selecione"
                      containerstyle={{ ...customGenderStyle, padding: '0px' }}
                    />
                  </td>
                  <td>
                    <AddButton type="button" onClick={addRow}>
                      <FaUserPlus />
                    </AddButton>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </fieldset>
      </div>
    </ComponentWrapper>
  );
}

ColaboradorFamily.defaultProps = {
  mode: 'insert',
  data: {},
};

export default ColaboradorFamily;
