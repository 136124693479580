import React, { useCallback, useEffect, useState } from 'react';
import { useLocalStorage, useLoading } from 'hooks';

import { FormInput, FormSelect } from 'components';

import api from 'services/api';

import { ComboProps } from 'interfaces';
import { FaSearch } from 'react-icons/fa';
import { SearchButton } from './styles';
import ComponentWrapper from '../wrapper';
import { lsKey } from '../../colaborator';

interface AddressProps {
  mode?: 'insert' | 'update' | 'show';
  data?: any;
}

function ColaboradorAddress({ mode, data }: AddressProps) {
  const { getLocalStorage } = useLocalStorage();
  const { toggleLoading } = useLoading();
  const [addressTypes, setAddressTypes] = useState<ComboProps[]>([]);
  const [federalUnits, setFederalUnits] = useState<ComboProps[]>([]);
  const [habitations, setHabitations] = useState<ComboProps[]>([]);
  const [zones, setZones] = useState<ComboProps[]>([]);
  const [prefixed, setPrefixed] = useState<any>({} as any);

  const handleGetCombos = useCallback(async () => {
    toggleLoading();
    try {
      const response = await api.get('/combos/colaborador_endereco.php');
      setAddressTypes(response.data.address_type);
      setHabitations(response.data.habitation);
      setFederalUnits(response.data.uf);
      setZones(response.data.zone);
    } catch (err) {
      console.error(err);
    } finally {
      toggleLoading();
    }
  }, []);

  useEffect(() => {
    handleGetCombos();
    const locallyStored: any = getLocalStorage(lsKey);
    if (Object.keys(data).length > 0) {
      if (mode === 'update') {
        setPrefixed({ ...locallyStored });
      } else {
        setPrefixed({ ...data });
      }
    } else {
      if (locallyStored) {
        setPrefixed({ ...locallyStored });
      }
    }
  }, [handleGetCombos]);

  const handleGetCEP = useCallback(async () => {
    toggleLoading();
    try {
      const cepInput = document
        .querySelector<HTMLInputElement>('input[name="cep"]')
        .value.replace(/[-]/g, '');

      const addressInput = document.querySelector<HTMLInputElement>(
        'input[name="address"]',
      );
      const districtInput = document.querySelector<HTMLInputElement>(
        'input[name="district"]',
      );
      const cityInput =
        document.querySelector<HTMLInputElement>('input[name="city"]');

      if (cepInput.length < 8) {
        return;
      }

      const response = await api.get(
        `https://brasilapi.com.br/api/cep/v1/${cepInput}`,
      );

      // const { cep, state, city, neighborhood, street } = response.data;

      addressInput.value = response.data.street;
      districtInput.value = response.data.neighborhood;
      cityInput.value = response.data.city;

      setPrefixed((state) => ({ ...state, uf: response.data.state }));
    } catch (err) {
      console.log(err);
    } finally {
      toggleLoading();
    }
  }, []);

  return (
    <ComponentWrapper sectionName="Endereço">
      <div>
        <FormInput
          name="cep"
          placeholder="CEP"
          label="CEP"
          mask="cep"
          defaultValue={prefixed.cep || null}
          disabled={mode === 'show'}
        />
        {mode !== 'show' ? (
          <>
            <SearchButton type="button" onClick={handleGetCEP}>
              <FaSearch />
            </SearchButton>
            <p
              style={{
                margin: 'auto 0',
                fontSize: '12px',
                fontStyle: 'italic',
                color: '#464646',
              }}
            >
              Escreva o CEP e clique no botão para pesquisar
            </p>
          </>
        ) : null}
      </div>
      <div>
        {/* <FormSelect
          name="address_type"
          label="Logradouro"
          content={addressTypes}
          initial={prefixed.address_type || null}
        /> */}
        <FormInput
          name="address"
          placeholder="Endereço"
          label="Endereço"
          defaultValue={prefixed.address || null}
          disabled={mode === 'show'}
        />
        <FormInput
          name="number"
          placeholder="Número"
          label="Número"
          defaultValue={prefixed.number || null}
          disabled={mode === 'show'}
        />
        <FormSelect
          name="habitation"
          label="Tipo moradia"
          content={habitations}
          initial={prefixed.habitation || null}
          disabled={mode === 'show'}
        />
      </div>
      <div>
        <FormInput
          name="complement"
          placeholder="Complemento"
          label="Complemento"
          defaultValue={prefixed.complement || null}
          disabled={mode === 'show'}
        />
        <FormInput
          name="district"
          placeholder="Bairro"
          label="Bairro"
          defaultValue={prefixed.district || null}
          disabled={mode === 'show'}
        />
      </div>
      <div>
        <FormInput
          name="city"
          placeholder="Cidade"
          label="Cidade"
          defaultValue={prefixed.city || null}
          disabled={mode === 'show'}
        />
        <FormSelect
          name="uf"
          label="UF"
          content={federalUnits}
          initial={prefixed.uf || null}
          disabled={mode === 'show'}
        />
        <FormSelect
          name="zone"
          label="Zona"
          content={zones}
          initial={prefixed.zone || null}
          disabled={mode === 'show'}
        />
      </div>
      <div>
        <FormInput
          name="referral"
          placeholder="Ponto de referência"
          label="Ponto de Referência"
          defaultValue={prefixed.referral || null}
          disabled={mode === 'show'}
        />
      </div>
    </ComponentWrapper>
  );
}

ColaboradorAddress.defaultProps = {
  mode: 'insert',
  data: {},
};

export default ColaboradorAddress;
