import styled from 'styled-components';

export const ComponentWrapper = styled.section`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    flex-direction: row;
    column-gap: 7px;
    padding: 5px 10px;
  }

  fieldset {
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    border: 2px solid #a6a6a6;
    border-radius: 5px;
    margin: 0px 5px;

    > legend {
      color: #464646;
      font-weight: bold;
      font-style: italic;
    }

    > div {
      display: flex;
      flex-direction: row;
      column-gap: 7px;
    }
  }

  @media screen and (max-width: 768px) {
    fieldset {
      margin: 0px;
    }
  }
`;
