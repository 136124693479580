import React, { useEffect, useRef, useState, useCallback } from 'react';
import { IconBaseProps } from 'react-icons';
import { FaExclamationTriangle } from 'react-icons/fa';
import { useField } from '@unform/core';

import { Wrapper, Error } from './styles';

interface CustomProps {
  name: string;
  label?: string;
  icon?: React.ComponentType<IconBaseProps>;
  containerstyle?: object;
  altHeight?: number;
}

type TextareaProps = JSX.IntrinsicElements['textarea'] & CustomProps;
function Textarea({
  name,
  label,
  icon: Icon,
  containerstyle,
  altHeight,
  ...rest
}: TextareaProps) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!textareaRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textareaRef.current,
      path: 'value',
    });
  }, [registerField, fieldName]);

  return (
    <Wrapper
      altheight={altHeight}
      haserror={!!error}
      isfocused={isFocused}
      isfilled={isFilled}
      style={containerstyle}
      labelpresent={!!label}
    >
      {label && <label htmlFor={fieldName}>{label}</label>}
      <textarea
        cols={10}
        rows={2}
        name={name}
        onFocus={handleFocus}
        onBlur={handleBlur}
        defaultValue={defaultValue}
        ref={textareaRef}
        {...rest}
      />
      {error && (
        <Error title={error}>
          <FaExclamationTriangle color="#c53030" size={20} />
        </Error>
      )}
    </Wrapper>
  );
}
// const Textarea: React.FC<TextareaProps> = ({
//   name,
//   icon: Icon,
//   containerstyle = {},
//   altHeight = 125,
//   ...rest
// }) => {
//   const textareaRef = useRef<HTMLTextAreaElement>(null);
//   const [isFocused, setIsFocused] = useState(false);
//   const [isFilled, setIsFilled] = useState(false);

//   const { fieldName, defaultValue, error, registerField } = useField(name);

// const handleFocus = useCallback(() => {
//   setIsFocused(true);
// }, []);

// const handleBlur = useCallback(() => {
//   setIsFocused(false);

//   setIsFilled(!!textareaRef.current?.value);
// }, []);

//   useEffect(() => {
//     registerField({
//       name: fieldName,
//       ref: textareaRef.current,
//       path: 'value',
//     });
//   }, [registerField, fieldName]);

//   return (
//     <Container
// altHeight={altHeight}
// hasError={!!error}
// isFocused={isFocused}
// isFilled={isFilled}
// style={containerstyle}
//     >
//       {Icon && <Icon />}
// <textarea
//   cols={10}
//   rows={2}
//   name={name}
//   onFocus={handleFocus}
//   onBlur={handleBlur}
//   defaultValue={defaultValue}
//   ref={textareaRef}
//   {...rest}
// />
// {error && (
//   <Error title={error}>
//     <FaExclamationTriangle color="#c53030" size={20} />
//   </Error>
// )}
//     </Container>
//   );
// };

Textarea.defaultProps = {
  label: null,
  icon: null,
  containerstyle: {},
  altHeight: null,
};

export default Textarea;
