import React from 'react';

import { AppProps } from 'interfaces';
import { LocalStorageProvider, useLocalStorage } from './localStorage';
import { EncoderProvider, useEncoder } from './encoder';
import { ToastProvider, useToast } from './toast';
import { LoadingProvider, useLoading } from './loading';
import { ModalProvider, useModal } from './modal';
import { ScrollingProvider, useScrolling } from './scrolling';
import { ChangeProvider, useChanges } from './changes';
import { AuthProvider, useAuth } from './auth';
import { WindowProvider, useWindow } from './window';
import { CredentialsProvider, useCredentials } from './credentials';
import { VersionProvider, useVersion } from './version';

function AppProvider({ children }: AppProps) {
  return (
    <LocalStorageProvider>
      <EncoderProvider>
        <ToastProvider>
          <LoadingProvider>
            <ModalProvider>
              <ScrollingProvider>
                <ChangeProvider>
                  <AuthProvider>
                    <WindowProvider>
                      <CredentialsProvider>
                        <VersionProvider>{children}</VersionProvider>
                      </CredentialsProvider>
                    </WindowProvider>
                  </AuthProvider>
                </ChangeProvider>
              </ScrollingProvider>
            </ModalProvider>
          </LoadingProvider>
        </ToastProvider>
      </EncoderProvider>
    </LocalStorageProvider>
  );
}

export default AppProvider;
export {
  useLocalStorage,
  useEncoder,
  useToast,
  useLoading,
  useModal,
  useScrolling,
  useChanges,
  useAuth,
  useWindow,
  useCredentials,
  useVersion,
};
