import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AiOutlineSearch } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import { useLoading } from 'hooks';
import InputMask from 'react-input-mask';
import './style.css';

const validationSchemaAddress = Yup.object().shape({
  cep: Yup.string()
    .required('CEP é obrigatório')
    .matches(/^\d{5}-\d{3}$/, 'CEP inválido'),
  logradouro: Yup.string().required('Campo obrigatório'),
  endereco: Yup.string().required('Campo obrigatório'),
  num: Yup.string().required('Campo obrigatório'),
  moradia: Yup.string().required('Campo obrigatório'),
  bairro: Yup.string().required('Campo obrigatório'),
  cidade: Yup.string().required('Campo obrigatório'),
  uf: Yup.string().required('Campo obrigatório'),
  zona: Yup.string().required('Campo obrigatório'),
});

const CreateFormAddress = ({
  action,
  id,
  formData,
  setFormData,
  address_type,
  habitation,
  uf,
  zone,
  handleTabChange,
  onUpdateFormStatus,
}) => {
  const { toggleLoading } = useLoading();

  const formik = useFormik({
    initialValues: formData,
    validateOnMount: true,
    validationSchema: validationSchemaAddress,
    onSubmit: (values) => {
      setFormData((prevData) => ({
        ...prevData,
        endereco: values,
      }));
    },
  });

  useEffect(() => {
    onUpdateFormStatus(formik.isValid, formik);
    setFormData(formik.values);
  }, [formik.isValid, formik.touched]);

  const handleAddressDataValidation = () => {
    const requiredFields = [
      'cep',
      'logradouro',
      'endereco',
      'num',
      'moradia',
      'bairro',
      'cidade',
      'uf',
      'zona',
    ];
    const emptyFields = requiredFields.filter((field) => !formik.values[field]);

    if (emptyFields.length > 0) {
      const errors = {};
      emptyFields.forEach((field) => {
        errors[field] = 'Campo obrigatório';
        formik.setFieldTouched(field, true);
      });
      formik.setErrors(errors);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        endereco: formik.values,
      }));
      setTimeout(() => {
        handleTabChange(3);
      }, 1000);
    }
  };

  const fetchAddressByCep = async (cep) => {
    try {
      const response = await fetch(
        `https://brasilapi.com.br/api/cep/v1/${cep}`,
      );

      const data = await response.json();
      if (data.erro) {
        return null;
      }
      return data;
    } catch (error) {
      console.error('Erro ao buscar o CEP:', error);
      return null;
    } finally {
      toggleLoading();
    }
  };

  const handleCepSearch = async () => {
    toggleLoading();
    const cep = formik.values.cep;
    if (cep) {
      const addressData = await fetchAddressByCep(cep);

      if (addressData) {
        formik.setValues({
          ...formik.values,
          endereco: addressData.street || '',
          bairro: addressData.neighborhood || '',
          cidade: addressData.city || '',
          uf: addressData.state || '',
        });
      } else {
        formik.resetForm();
      }
    }
  };

  return (
    <div className="container-fadeIn">
      <form id={id} onSubmit={formik.handleSubmit}>
        <div className="flex flex-wrap -mx-3 mb-6 items-center">
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 relative">
            <label htmlFor="cep" className="block mb-1 text-gray-700">
              CEP
            </label>
            <div className="flex items-center">
              <div className="relative w-full">
                <InputMask
                  disabled={action === 'show'}
                  mask="99999-999"
                  value={formik.values.cep}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {() => (
                    <input
                      type="text"
                      name="cep"
                      className={`border ${formik.touched?.cep && formik.errors?.cep ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
                    />
                  )}
                </InputMask>
                <button
                  type="button"
                  onClick={handleCepSearch}
                  className="absolute right-0 top-0 mt-2 mr-2"
                  disabled={formik.values.cep === '' || action === 'show'}
                >
                  <AiOutlineSearch size={20} />
                </button>
              </div>
              <div
                className="w-full text-gray-600 font-bold text-sm ml-3"
                style={{ fontSize: '1.1rem' }}
              >
                Escreva seu CEP
              </div>
            </div>
            {formik.touched.cep && formik.errors.cep ? (
              <div className="text-red-500 text">
                {String(formik.errors.cep)}
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/6 px-3 mb-6 md:mb-0">
            <label htmlFor="logradouro" className="block mb-1 text-gray-700">
              Logradouro
            </label>
            <select
              disabled={action === 'show'}
              id="logradouro"
              name="logradouro"
              value={formik.values.logradouro}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.logradouro && formik.errors.logradouro ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            >
              <option value="">Selecione...</option>
              {address_type?.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            {formik.touched.logradouro && formik.errors.logradouro ? (
              <div className="text-red-500 text">
                {String(formik.errors.logradouro)}
              </div>
            ) : null}
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label htmlFor="endereco" className="block mb-1 text-gray-700">
              Endereço
            </label>
            <input
              disabled={action === 'show'}
              type="text"
              id="endereco"
              name="endereco"
              value={formik.values.endereco}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.endereco && formik.errors.endereco ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.endereco && formik.errors.endereco ? (
              <div className="text-red-500 text">
                {String(formik.errors.endereco)}
              </div>
            ) : null}
          </div>
          <div className="w-full md:w-1/6 px-3 mb-6 md:mb-0">
            <label htmlFor="num" className="block mb-1 text-gray-700">
              Número
            </label>
            <input
              disabled={action === 'show'}
              type="text"
              id="num"
              name="num"
              value={formik.values.num}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.num && formik.errors.num ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.num && formik.errors.num ? (
              <div className="text-red-500 text">
                {String(formik.errors.num)}
              </div>
            ) : null}
          </div>
          <div className="w-full md:w-1/6 px-3 mb-6 md:mb-0">
            <label htmlFor="moradia" className="block mb-1 text-gray-700">
              Tipo de Moradia
            </label>
            <select
              disabled={action === 'show'}
              id="moradia"
              name="moradia"
              value={formik.values.moradia}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.moradia && formik.errors.moradia ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            >
              <option value="">Selecione...</option>
              {habitation?.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            {formik.touched.moradia && formik.errors.moradia ? (
              <div className="text-red-500 text">
                {String(formik.errors.moradia)}
              </div>
            ) : null}
          </div>
        </div>

        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label htmlFor="compl" className="block mb-1 text-gray-700">
              Complemento
            </label>
            <input
              disabled={action === 'show'}
              type="text"
              id="compl"
              name="compl"
              value={formik.values.compl}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.compl && formik.errors.compl ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.compl && formik.errors.compl ? (
              <div className="text-red-500 text">
                {String(formik.errors.compl)}
              </div>
            ) : null}
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label htmlFor="bairro" className="block mb-1 text-gray-700">
              Bairro
            </label>
            <input
              disabled={action === 'show'}
              type="text"
              id="bairro"
              name="bairro"
              value={formik.values.bairro}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.bairro && formik.errors.bairro ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.bairro && formik.errors.bairro ? (
              <div className="text-red-500 text">
                {String(formik.errors.bairro)}
              </div>
            ) : null}
          </div>
        </div>

        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label htmlFor="cidade" className="block mb-1 text-gray-700">
              Cidade
            </label>
            <input
              disabled={action === 'show'}
              type="text"
              id="cidade"
              name="cidade"
              value={formik.values.cidade}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.cidade && formik.errors.cidade ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.cidade && formik.errors.cidade ? (
              <div className="text-red-500 text">
                {String(formik.errors.cidade)}
              </div>
            ) : null}
          </div>
          <div className="w-full md:w-1/6 px-3 mb-6 md:mb-0">
            <label htmlFor="uf" className="block mb-1 text-gray-700">
              UF
            </label>
            <select
              disabled={action === 'show'}
              id="uf"
              name="uf"
              value={formik.values.uf}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.uf && formik.errors.uf ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            >
              <option value="">Selecione...</option>
              {uf?.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            {formik.touched.uf && formik.errors.uf ? (
              <div className="text-red-500 text">
                {String(formik.errors.uf)}
              </div>
            ) : null}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label htmlFor="zona" className="block mb-1 text-gray-700">
              Zona
            </label>
            <select
              disabled={action === 'show'}
              id="zona"
              name="zona"
              value={formik.values.zona}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.zona && formik.errors.zona ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            >
              <option value="">Selecione...</option>
              {zone.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            {formik.touched.zona && formik.errors.zona ? (
              <div className="text-red-500 text">
                {String(formik.errors.zona)}
              </div>
            ) : null}
          </div>
        </div>

        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label htmlFor="ptrefer" className="block mb-1 text-gray-700">
              Ponto de Referência
            </label>
            <input
              disabled={action === 'show'}
              type="text"
              id="ptrefer"
              name="ptrefer"
              value={formik.values.ptrefer}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.ptrefer && formik.errors.ptrefer ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.ptrefer && formik.errors.ptrefer ? (
              <div className="text-red-500 text">
                {String(formik.errors.ptrefer)}
              </div>
            ) : null}
          </div>
          <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label htmlFor="comodos" className="block mb-1 text-gray-700">
              Cômodos
            </label>
            <select
              disabled={action === 'show'}
              id="comodos"
              name="comodos"
              value={formik.values.comodos}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.comodos && formik.errors.comodos ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            >
              {[...(Array(9).keys() as any)]?.map((num) => (
                <option key={num + 1} value={num + 1}>
                  {num + 1}
                </option>
              ))}
            </select>
            {formik.touched.comodos && formik.errors.comodos ? (
              <div className="text-red-500 text">
                {String(formik.errors.comodos)}
              </div>
            ) : null}
          </div>
          <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label htmlFor="residentes" className="block mb-1 text-gray-700">
              Residentes
            </label>
            <select
              disabled={action === 'show'}
              id="residentes"
              name="residentes"
              value={formik.values.residentes}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.residentes && formik.errors.residentes ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            >
              {[...(Array(9).keys() as any)]?.map((num) => (
                <option key={num + 1} value={num + 1}>
                  {num + 1}
                </option>
              ))}
            </select>
            {formik.touched.residentes && formik.errors.residentes ? (
              <div className="text-red-500 text">
                {String(formik.errors.residentes)}
              </div>
            ) : null}
          </div>
        </div>

        <label htmlFor="residentes" className="block mb-1 text-gray-700">
          Mora com quem?
        </label>

        <div className="bg-gray-100 rounded-md p-4 shadow-md border border-black">
          <div className="mt-6 flex flex-wrap -mx-2">
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 px-2 mb-4">
              <input
                type="checkbox"
                id="moraconj"
                name="moraconj"
                className="mr-2"
                disabled={action === 'show'}
                checked={formik.values.moraconj === 'S'}
                onChange={(e) =>
                  formik.setFieldValue('moraconj', e.target.checked ? 'S' : 'N')
                }
                onBlur={formik.handleBlur}
              />
              <label htmlFor="moraconj">Cônjugue</label>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 px-2 mb-4">
              <input
                disabled={action === 'show'}
                type="checkbox"
                id="moramae"
                name="moramae"
                className="mr-2"
                checked={formik.values.moramae === 'S'}
                onChange={(e) =>
                  formik.setFieldValue('moramae', e.target.checked ? 'S' : 'N')
                }
                onBlur={formik.handleBlur}
              />
              <label htmlFor="moramae">Mãe</label>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 px-2 mb-4">
              <input
                type="checkbox"
                id="morafilho"
                name="morafilho"
                className="mr-2"
                disabled={action === 'show'}
                checked={formik.values.morafilho === 'S'}
                onChange={(e) =>
                  formik.setFieldValue(
                    'morafilho',
                    e.target.checked ? 'S' : 'N',
                  )
                }
                onBlur={formik.handleBlur}
              />
              <label htmlFor="morafilho">Filhos</label>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 px-2 mb-4">
              <input
                type="checkbox"
                id="moraavom"
                name="moraavom"
                disabled={action === 'show'}
                className="mr-2"
                checked={formik.values.moraavom === 'S'}
                onChange={(e) =>
                  formik.setFieldValue('moraavom', e.target.checked ? 'S' : 'N')
                }
                onBlur={formik.handleBlur}
              />
              <label htmlFor="moraavom">Avó</label>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 px-2 mb-4">
              <input
                type="checkbox"
                id="moramad"
                name="moramad"
                disabled={action === 'show'}
                className="mr-2"
                checked={formik.values.moramad === 'S'}
                onChange={(e) =>
                  formik.setFieldValue('moramad', e.target.checked ? 'S' : 'N')
                }
                onBlur={formik.handleBlur}
              />
              <label htmlFor="moramad">Madrasta</label>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 px-2 mb-4">
              <input
                type="checkbox"
                id="moraout"
                name="moraout"
                disabled={action === 'show'}
                className="mr-2"
                checked={formik.values.moraout === 'S'}
                onChange={(e) =>
                  formik.setFieldValue('moraout', e.target.checked ? 'S' : 'N')
                }
                onBlur={formik.handleBlur}
              />
              <label htmlFor="moraout">Outros</label>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 px-2 mb-4">
              <input
                type="checkbox"
                id="moraavop"
                name="moraavop"
                disabled={action === 'show'}
                className="mr-2"
                checked={formik.values.moraavop === 'S'}
                onChange={(e) =>
                  formik.setFieldValue('moraavop', e.target.checked ? 'S' : 'N')
                }
                onBlur={formik.handleBlur}
              />
              <label htmlFor="moraavop">Pai</label>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 px-2 mb-4">
              <input
                type="checkbox"
                id="morairm"
                name="morairm"
                disabled={action === 'show'}
                className="mr-2"
                checked={formik.values.morairm === 'S'}
                onChange={(e) =>
                  formik.setFieldValue('morairm', e.target.checked ? 'S' : 'N')
                }
                onBlur={formik.handleBlur}
              />
              <label htmlFor="morairm">Irmão</label>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 px-2 mb-4">
              <input
                type="checkbox"
                id="moraavop"
                name="moraavop"
                disabled={action === 'show'}
                className="mr-2"
                checked={formik.values.moraavop === 'S'}
                onChange={(e) =>
                  formik.setFieldValue('moraavop', e.target.checked ? 'S' : 'N')
                }
                onBlur={formik.handleBlur}
              />
              <label htmlFor="moraavop">Avô</label>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 px-2 mb-4">
              <input
                type="checkbox"
                id="morapad"
                disabled={action === 'show'}
                name="morapad"
                className="mr-2"
                checked={formik.values.morapad === 'S'}
                onChange={(e) =>
                  formik.setFieldValue('morapad', e.target.checked ? 'S' : 'N')
                }
                onBlur={formik.handleBlur}
              />
              <label htmlFor="morapad">Padrasto</label>
            </div>
          </div>
        </div>
      </form>
      {(action === 'edit' || action === 'new') && (
        <div className="w-full px-3">
          <div className="flex justify-end">
            <button
              type="submit"
              className="mt-4 bg-blue-500 text-white font-bold py-2 px-4 rounded"
              onClick={handleAddressDataValidation}
            >
              Validar dados de Endereço
            </button>
          </div>
          {!formik.isValid && (
            <div className="text-red-500 font-bold mt-2 flex justify-end">
              Preencha os campos obrigatórios.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CreateFormAddress;
