import styled from 'styled-components';
import { animated } from '@react-spring/web';

export const ErrorSpan = styled(animated.span)`
  --mainColor: #c53030;
  display: flex;
  column-gap: 7px;
  position: absolute;
  padding: 0 7px;
  left: 0px;

  height: 22px;
  overflow: hidden;

  background: var(--mainColor);
  /* border: 1px solid var(--mainColor); */
  border-radius: 5px 5px 0 0;
  z-index: 0;
`;
