import React, { useState, useCallback, useEffect } from 'react';

import { CKEditor } from '@ckeditor/ckeditor5-react';

import type { EventInfo } from '@ckeditor/ckeditor5-utils';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import ClassicEditor from 'utils/ck5editor/ckeditor';
const ClassicEditor = require('utils/ck5editor/ckeditor');

interface EditorProps {
  content?: string;
  submitHandler?: any;
  enableSaveButton?: boolean;
  getEditorContent?: (content?: any) => any;
  hasError?: boolean;
}

const editorConfiguration = {
  removePlugins: ['Heading', 'Title'],
  toolbar: {
    items: [
      'undo',
      'redo',
      '|',
      'bold',
      'italic',
      'alignment',
      'link',
      '|',
      'fontFamily',
      'fontSize',
      'fontColor',
      'selectAll',
      'removeFormat',
      '|',
      'superscript',
      'subscript',
      '|',
      // 'insertTable',
      // 'tableColumn',
      // 'tableRow',
      // 'mergeTableCells',
      // 'tableProperties',
      // '|',
      'findAndReplace',
      'specialCharacters',
    ],
  },
};

// const editorConfiguration = {
//   toolbar: [
//     'undo',
//     'redo',
//     '|',
//     'bold',
//     'italic',
//     '|',
//     'link',
//     'insertTable',
//     '|',
//   ],
// };

function Editor({
  content,
  submitHandler,
  enableSaveButton,
  getEditorContent,
  hasError,
}: EditorProps) {
  const [data, setData] = useState(content);
  const [originalData, setOriginalData] = useState(content);
  const handleReady = useCallback((editor: any) => {
    // console.log('Editor is ready to use!', editor);
  }, []);

  const displayCurrentData = useCallback(() => {
    getEditorContent(data);
  }, [data]);

  const handleChanges = useCallback(
    (ev: EventInfo, editor: any) => {
      const changes = editor.getData();
      setData(changes);
    },
    [setData],
  );

  const handleBlur = useCallback(
    (ev: EventInfo, editor: any) => {
      const changes = editor.getData();
      setData(changes);
    },
    [setData],
  );

  const handleFocus = useCallback((ev: EventInfo, editor: any) => {
    // const changes = editor.getData();
    // setData(changes);
  }, []);

  const onSubmit = useCallback(() => {
    submitHandler(data);
  }, [data]);

  useEffect(() => {
    window.addEventListener('submitEditor', onSubmit);
    window.addEventListener('displayEditorContent', displayCurrentData);

    return () => {
      window.removeEventListener('submitEditor', onSubmit);
      window.removeEventListener('displayEditorContent', displayCurrentData);
    };
  }, [data]);

  return (
    <div style={{ width: '100vw', maxWidth: '700px', margin: '0 auto' }}>
      <CKEditor
        editor={ClassicEditor}
        data={content}
        config={editorConfiguration}
        onReady={handleReady}
        onChange={handleChanges}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      {hasError ? (
        <p style={{ color: '#c53030', fontStyle: 'italic', fontSize: '14px' }}>
          * Este campo deve conter informações
        </p>
      ) : null}

      {enableSaveButton ? (
        <button
          type="button"
          onClick={onSubmit}
          disabled={JSON.stringify(originalData) === JSON.stringify(data)}
        >
          Save
        </button>
      ) : null}
    </div>
  );
}

Editor.defaultProps = {
  content: '',
  submitHandler: null,
  getEditorContent: null,
  enableSaveButton: true,
  hasError: false,
};

export default Editor;
