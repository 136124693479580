import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';

import { ComboProps } from 'interfaces';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Label, RadioFieldset, Error } from './styles';

interface CustomProps {
  name: string;
  label?: string;
  selected?: string;
  disabled?: boolean;
  options: ComboProps[];
  containerstyle?: object;
}

type InputProps = JSX.IntrinsicElements['input'] & CustomProps;

function FormRadio({
  name,
  label,
  options,
  selected,
  containerstyle,
  disabled,
}: InputProps) {
  const [defaultOption, setDefaultOption] = useState(null);
  const radioRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: radioRefs.current,
      getValue(refs) {
        const checked = refs.find((ref) => ref.checked);

        return checked ? checked.value : null;
      },
      setValue(refs, value) {
        const item = refs.find((ref) => ref.value === value);

        if (item) {
          item.checked = true;
        }
      },
    });
  }, [fieldName, registerField]);

  const handleClick = useCallback((ev) => {
    if (disabled) {
      return;
    }
    const { value } = ev.target;
    setDefaultOption(value);
  }, []);

  useEffect(() => {
    if (defaultOption) {
      return;
    }
    setDefaultOption(selected);
  }, [defaultOption, selected]);

  return (
    <RadioFieldset error={!!error} style={containerstyle}>
      <legend>{label}</legend>
      {options.map((item, index) => (
        <Label
          htmlFor={name + item.value.toString()}
          key={name + item.value.toString()}
          selected={item.value === defaultOption}
          disabled={disabled}
        >
          <input
            ref={(elRef) => (radioRefs.current[index] = elRef)}
            type="radio"
            name={name}
            onClick={handleClick}
            value={item.value}
            id={name + item.value.toString()}
            defaultChecked={item.value === defaultOption}
          />
          {item.label}
        </Label>
      ))}
      {error && (
        <Error title={error}>
          <FaExclamationTriangle color="#c53030" size={20} />
        </Error>
      )}
    </RadioFieldset>
  );
}

FormRadio.defaultProps = {
  label: null,
  selected: '',
  disabled: false,
  containerstyle: {},
};

export default FormRadio;
