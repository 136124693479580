import React from 'react';
import { AppProps } from 'interfaces';

import { ComponentWrapper } from './styles';

interface ComponentWrapperProps extends AppProps {
  sectionName?: string;
}

function ColaboradorComponentWrapper({
  children,
  sectionName,
}: ComponentWrapperProps) {
  return (
    <ComponentWrapper>
      {/* {sectionName ? <h3>{sectionName}</h3> : null} */}
      {children}
    </ComponentWrapper>
  );
}

ColaboradorComponentWrapper.defaultProps = {
  sectionName: null,
};

export default ColaboradorComponentWrapper;
