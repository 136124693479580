import React, { useState } from 'react';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import { AiOutlineDelete } from 'react-icons/ai';
import InputMask from 'react-input-mask';
import { useToast } from 'hooks/toast';

const CreateFormFamily = ({ action, formData, setFormData }) => {
  const { addToast } = useToast();
  const validationSchema = Yup.object({
    conjuge: Yup.object({
      nome: Yup.string().required('Nome é obrigatório'),
      sobrenome: Yup.string().required('Sobrenome é obrigatório'),
      dtnasc: Yup.date().required('Data de nascimento é obrigatória'),
      rg: Yup.string().required('RG é obrigatório'),
      cpf: Yup.string().required('CPF é obrigatório'),
      //   cin: Yup.string().required('CIN é obrigatório'),
    }),
    parentes: Yup.array().of(
      Yup.object({
        nome: Yup.string(),
        tipo: Yup.string().when('nome', {
          is: (val: string) => val && val.trim() !== '',
          then: (schema) =>
            schema.required('Tipo é obrigatório quando nome está preenchido'),
          otherwise: (schema) => schema,
        }),
        idade: Yup.number().when('nome', {
          is: (val: string) => val && val.trim() !== '',
          then: (schema) =>
            schema
              .required('Idade é obrigatória quando nome está preenchido')
              .positive()
              .integer(),
          otherwise: (schema) => schema,
        }),
        dtnasc: Yup.date().when('nome', {
          is: (val: string) => val && val.trim() !== '',
          then: (schema) =>
            schema.required(
              'Data de nascimento é obrigatória quando nome está preenchido',
            ),
          otherwise: (schema) => schema,
        }),
        escolaridade: Yup.string().when('nome', {
          is: (val: string) => val && val.trim() !== '',
          then: (schema) =>
            schema.required(
              'Escolaridade é obrigatória quando nome está preenchido',
            ),
          otherwise: (schema) => schema,
        }),
        renda: Yup.number().when('nome', {
          is: (val: string) => val && val.trim() !== '',
          then: (schema) =>
            schema
              .required('Renda é obrigatória quando nome está preenchido')
              .positive(),
          otherwise: (schema) => schema,
        }),
        profissao: Yup.string().when('nome', {
          is: (val: string) => val && val.trim() !== '',
          then: (schema) =>
            schema.required(
              'Profissão é obrigatória quando nome está preenchido',
            ),
          otherwise: (schema) => schema,
        }),
      }),
    ),
  });

  const formik: any = useFormik({
    initialValues: {
      conjuge: formData.conjuge,
      parentes: formData.parentes,
      familiar: formData.familiar,
    },
    validateOnMount: true,
    validationSchema,
    onSubmit: (values) => {
      setFormData((prevData: any) => ({
        ...prevData,
        conjuge: values?.conjuge,
        parentes: values?.parentes,
        familiar: formData?.familiar,
      }));
    },
  });

  const handleSaveFamilly = () => {
    if (!formik.isValid) {
      addToast({
        type: 'info',
        title: 'Família!',
        description: 'Preencha todos os dados obrigatórios.',
        seconds: 3,
      });

      return;
    }
    setFormData((prevData: any) => ({
      ...prevData,
      conjuge: formik?.values?.conjuge,
      parentes: formik?.values?.parentes,
    }));
    addToast({
      type: 'success',
      title: 'Família!',
      description: 'Família salva com sucesso.',
      seconds: 3,
    });
  };

  React.useEffect(() => {
    //   onUpdateFormStatus(formik.isValid, formik);

    setFormData({
      conjuge: formik?.values?.conjuge,
      parentes: formik?.values?.parentes,
      familiar: formik?.values?.familiar,
    });
  }, [formik.isValid, formik.touched]);

  const handleAddParente = () => {
    formik.setFieldValue('parentes', [
      ...formik.values.parentes,
      {
        tipo: '',
        nome: '',
        idade: 0,
        dtnasc: '',
        escolaridade: '',
        renda: 0,
        profissao: '',
      },
    ]);

    setFormData((prevData: any) => ({
      ...prevData,
      conjuge: formik?.values?.conjuge,
      parentes: formik?.values?.parentes,
      familiar: formik?.values?.familiar,
    }));
  };

  const handleRemoveParente = (index) => {
    const newParentes = [...formik.values.parentes];
    newParentes.splice(index, 1);
    formik.setFieldValue('parentes', newParentes);
  };

  return (
    <div className="p-4 container-fadeIn">
      <form onSubmit={formik.handleSubmit}>
        <h2 className="text font-bold mb-4">Dados do Cônjuge</h2>
        <div className="flex space-x-4 mb-4">
          <div className="w-1/3">
            <label className="block mb-1 text-gray-700">Nome</label>
            <input
              disabled={action === 'show'}
              type="text"
              name="conjuge.nome"
              onChange={formik.handleChange}
              value={formik?.values?.conjuge?.nome}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.conjuge?.nome && formik.errors.conjuge?.nome ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.conjuge?.nome && formik.errors.conjuge?.nome ? (
              <div className="text-red-500">{formik.errors.conjuge.nome}</div>
            ) : null}
          </div>
          <div className="w-1/3">
            <label className="block mb-1 text-gray-700">Sobrenome</label>
            <input
              disabled={action === 'show'}
              type="text"
              name="conjuge.sobrenome"
              onChange={formik.handleChange}
              value={formik.values.conjuge.sobrenome}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.conjuge?.sobrenome && formik.errors.conjuge?.sobrenome ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.conjuge?.sobrenome &&
            formik.errors.conjuge?.sobrenome ? (
              <div className="text-red-500">
                {formik.errors.conjuge.sobrenome}
              </div>
            ) : null}
          </div>
          <div className="w-1/3">
            <label className="block mb-1 text-gray-700">
              Data de Nascimento
            </label>
            <input
              disabled={action === 'show'}
              type="date"
              id="conjuge.dtnasc"
              name="conjuge.dtnasc"
              onChange={formik.handleChange}
              value={formik?.values?.conjuge.dtnasc}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.conjuge?.dtnasc && formik.errors.conjuge?.dataNdtnascascimento ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.conjuge?.dtnasc && formik.errors.conjuge?.dtnasc ? (
              <div className="text-red-500">{formik.errors.conjuge.dtnasc}</div>
            ) : null}
          </div>
        </div>
        <div className="flex space-x-4 mb-4">
          <div className="w-1/4">
            <label className="block mb-1 text-gray-700">RG</label>
            <input
              disabled={action === 'show'}
              type="text"
              name="conjuge.rg"
              onChange={formik.handleChange}
              value={formik.values.conjuge.rg}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.conjuge?.rg && formik.errors.conjuge?.rg ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.conjuge?.rg && formik.errors.conjuge?.rg ? (
              <div className="text-red-500">{formik.errors.conjuge.rg}</div>
            ) : null}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label htmlFor="conjuge.cpf" className="block mb-1 text-gray-700">
              CPF
            </label>

            <InputMask
              disabled={action === 'show'}
              mask="999.999.999-99"
              value={formik?.values?.conjuge?.cpf}
              name="conjuge.cpf"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              {() => (
                <input
                  type="text"
                  name="conjuge.cpf"
                  className={`border ${formik.touched.conjuge?.cpf && formik.errors.conjuge?.cpf ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
                />
              )}
            </InputMask>

            {formik.touched.conjuge?.cpf && formik.errors.conjuge?.cpf ? (
              <div className="text-red-500">{formik.errors.conjuge.cpf}</div>
            ) : null}
          </div>

          <div className="w-1/4">
            <label className="block mb-1 text-gray-700">CIN</label>
            <input
              disabled={action === 'show'}
              type="text"
              name="conjuge.cin"
              onChange={formik.handleChange}
              value={formik.values.conjuge.cin}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.conjuge?.cin && formik.errors.conjuge?.cin ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            />
            {formik.touched.conjuge?.cin && formik.errors.conjuge?.cin ? (
              <div className="text-red-500">{formik.errors.conjuge.cin}</div>
            ) : null}
          </div>
          <div className="flex items-center mb-4 mt-8">
            <div>
              <input
                disabled={action === 'show'}
                type="checkbox"
                id="conjpai"
                value={formik.values.conjuge.conjpai}
                name="conjpai"
                checked={formik.values.conjuge.conjpai === 'S'}
                onChange={(e) =>
                  formik.setFieldValue(
                    'conjuge.conjpai',
                    e.target.checked ? 'S' : 'N',
                  )
                }
                onBlur={formik.handleBlur}
                className={`border ${formik.touched.conjpai && formik.errors.conjpai ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
              />
            </div>
            <div className="ml-3">
              <label htmlFor="conjpai" className="block mb-1 text-gray-700">
                Marcar se o cônjugue NÃO é o pai da(s) criança(s)
              </label>
            </div>
            {formik.touched.conjpai && formik.errors.conjpai ? (
              <div className="text-red-500 text">
                {String(formik.errors.conjpai)}
              </div>
            ) : null}
          </div>
        </div>

        <h2 className="text font-bold mb-4">Parentes</h2>
        {formik?.values?.parentes?.map((parente: any, index) => {
          return (
            <div key={index} className="mb-4 p-4 border rounded-lg bg-gray-50">
              <div className="flex space-x-4 mb-4">
                <div className="w-1/4">
                  <label className="block mb-1 text-gray-700">Tipo</label>
                  <select
                    disabled={action === 'show'}
                    name={`parentes[${index}].tipo`}
                    onChange={formik.handleChange}
                    value={parente.tipo}
                    className={`border ${formik.touched?.parentes?.tipo && formik.errors.parentes?.tipo ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
                  >
                    <option value="">Selecione</option>
                    <option value="pai">Pai</option>
                    <option value="filho">Filho</option>
                    <option value="avo">Avô</option>
                    <option value="tio">Tio</option>
                    {/* outros tipos... */}
                  </select>
                  {formik.touched.parentes?.[index]?.tipo &&
                    formik.errors.parentes?.[index]?.tipo && (
                      <div className="text-red-500">
                        {formik.errors.parentes[index].tipo}
                      </div>
                    )}
                </div>
                <div className="w-1/4">
                  <label className="block mb-1 text-gray-700">Nome</label>
                  <input
                    disabled={action === 'show'}
                    type="text"
                    name={`parentes[${index}].nome`}
                    onChange={formik.handleChange}
                    value={parente.nome}
                    className={`border ${formik.touched.parentes?.nome && formik.errors.parentes?.nome ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
                  />
                  {formik.touched.parentes?.[index]?.nome &&
                    formik.errors.parentes?.[index]?.nome && (
                      <div className="text-red-500">
                        {formik.errors.parentes[index].nome}
                      </div>
                    )}
                </div>
                <div className="w-1/4">
                  <label className="block mb-1 text-gray-700">Idade</label>
                  <input
                    disabled={action === 'show'}
                    type="number"
                    name={`parentes[${index}].idade`}
                    onChange={formik.handleChange}
                    value={parente.idade}
                    className={`border ${formik.touched.parentes?.idade && formik.errors.parentes?.idade ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
                  />
                  {formik.touched.parentes?.[index]?.idade &&
                    formik.errors.parentes?.[index]?.idade && (
                      <div className="text-red-500">
                        {formik.errors.parentes[index].idade}
                      </div>
                    )}
                </div>
                <div className="w-1/4">
                  <label className="block mb-1 text-gray-700">
                    Data de Nascimento
                  </label>
                  <input
                    disabled={action === 'show'}
                    type="date"
                    name={`parentes[${index}].dataNascimento`}
                    onChange={formik.handleChange}
                    value={parente.dataNascimento}
                    className={`border ${formik.touched.parentes?.dataNascimento && formik.errors.parentes?.dataNascimento ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
                  />
                  {formik.touched.parentes?.[index]?.dataNascimento &&
                    formik.errors.parentes?.[index]?.dataNascimento && (
                      <div className="text-red-500">
                        {formik.errors.parentes[index].dataNascimento}
                      </div>
                    )}
                </div>
              </div>
              <div className="flex space-x-4 mb-4">
                <div className="w-1/3">
                  <label className="block mb-1 text-gray-700">
                    Escolaridade
                  </label>
                  <input
                    disabled={action === 'show'}
                    type="text"
                    name={`parentes[${index}].escolaridade`}
                    onChange={formik.handleChange}
                    value={parente.escolaridade}
                    className={`border ${formik.touched.parentes?.escolaridade && formik.errors.parentes?.escolaridade ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
                  />
                  {formik.touched.parentes?.[index]?.escolaridade &&
                    formik.errors.parentes?.[index]?.escolaridade && (
                      <div className="text-red-500">
                        {formik.errors.parentes[index].escolaridade}
                      </div>
                    )}
                </div>
                <div className="w-1/3">
                  <label className="block mb-1 text-gray-700">Renda</label>
                  <input
                    disabled={action === 'show'}
                    type="number"
                    name={`parentes[${index}].renda`}
                    onChange={formik.handleChange}
                    value={parente.renda}
                    className={`border ${formik.touched.parentes?.renda && formik.errors.parentes?.renda ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
                  />
                  {formik.touched.parentes?.[index]?.renda &&
                    formik.errors.parentes?.[index]?.renda && (
                      <div className="text-red-500">Preencha a renda</div>
                    )}
                </div>
                <div className="w-1/3">
                  <label className="block mb-1 text-gray-700">Profissão</label>
                  <input
                    disabled={action === 'show'}
                    type="text"
                    name={`parentes[${index}].profissao`}
                    onChange={formik.handleChange}
                    value={parente.profissao}
                    className={`border ${formik.touched.parentes?.profissao && formik.errors.parentes?.profissao ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
                  />
                  {formik.touched.parentes?.[index]?.profissao &&
                    formik.errors.parentes?.[index]?.profissao && (
                      <div className="text-red-500">
                        {formik.errors.parentes[index].profissao}
                      </div>
                    )}
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  disabled={action === 'show'}
                  type="button"
                  onClick={() => handleRemoveParente(index)}
                  className="bg-red-500 text-white rounded-md px-4 py-2 flex items-center space-x-2 hover:bg-red-600"
                >
                  <AiOutlineDelete />
                  <span>Remover</span>
                </button>
              </div>
            </div>
          );
        })}

        <div className="flex justify-between">
          <div className="flex items-center">
            <input
              disabled={action === 'show'}
              type="checkbox"
              id="refcras"
              value={formik?.values?.familiar?.refcras}
              name="refcras"
              checked={formik?.values?.familiar?.refcras === 'S'}
              onChange={(e) =>
                formik.setFieldValue(
                  'familiar.refcras',
                  e.target.checked ? 'S' : 'N',
                )
              }
              onBlur={formik.handleBlur}
              className={`border bg-gray-100 rounded-md mr-2`}
            />
            <label htmlFor="refcras" className="text-gray-700">
              Referenciado CRAS
            </label>
          </div>

          <div className="flex items-center">
            <input
              disabled={action === 'show'}
              type="checkbox"
              id="familiar.refcreas"
              value={formik?.values?.familiar?.refcreas}
              name="familiar.refcreas"
              checked={formik?.values?.familiar?.refcreas === 'S'}
              onChange={(e) =>
                formik.setFieldValue(
                  'familiar.refcreas',
                  e.target.checked ? 'S' : 'N',
                )
              }
              onBlur={formik.handleBlur}
              className={`border bg-gray-100 rounded-md mr-2`}
            />
            <label htmlFor="familiar.refcreas" className="text-gray-700">
              Referenciado CREAS
            </label>
          </div>

          <div className="flex items-center">
            <input
              disabled={action === 'show'}
              type="checkbox"
              id="vt"
              value={formik?.values?.familiar?.vt}
              name="vt"
              checked={formik?.values?.familiar?.vt === 'S'}
              onChange={(e) =>
                formik.setFieldValue(
                  'familiar.vt',
                  e.target.checked ? 'S' : 'N',
                )
              }
              onBlur={formik.handleBlur}
              className={`border bg-gray-100 rounded-md mr-2`}
            />
            <label htmlFor="vt" className="text-gray-700">
              Vale Transporte
            </label>
          </div>

          <div className="flex items-center">
            <input
              disabled={action === 'show'}
              type="checkbox"
              id="familiar.cesta"
              value={formik?.values?.familiar?.cesta}
              name="familiar.cesta"
              checked={formik?.values?.familiar?.cesta === 'S'}
              onChange={(e) =>
                formik.setFieldValue(
                  'familiar.cesta',
                  e.target.checked ? 'S' : 'N',
                )
              }
              onBlur={formik.handleBlur}
              className={`border bg-gray-100 rounded-md mr-2`}
            />
            <label htmlFor="familiar.cesta" className="text-gray-700">
              Vale Transporte
            </label>
          </div>
        </div>

        <div className="mt-4 p-4 border rounded-lg bg-white shadow-md">
          <h3 className="text-lg font-bold mb-4">Benefícios Sociais</h3>
          <div className="flex justify-between mb-4">
            <div className="flex items-center">
              <input
                disabled={action === 'show'}
                type="checkbox"
                id="benbol"
                value={formik?.values?.familiar?.benbol}
                name="benbol"
                checked={formik?.values?.familiar?.benbol === 'S'}
                onChange={(e) =>
                  formik.setFieldValue(
                    'familiar.benbol',
                    e.target.checked ? 'S' : 'N',
                  )
                }
                onBlur={formik.handleBlur}
                className={`border bg-gray-100 rounded-md mr-2`}
              />
              <label htmlFor="benbol" className="text-gray-700">
                Bolsa Família
              </label>
            </div>

            <div className="flex items-center">
              <input
                disabled={action === 'show'}
                type="checkbox"
                id="benloas"
                value={formik?.values?.familiar?.benloas}
                name="benloas"
                checked={formik?.values?.familiar?.benloas === 'S'}
                onChange={(e) =>
                  formik.setFieldValue(
                    'familiar.benloas',
                    e.target.checked ? 'S' : 'N',
                  )
                }
                onBlur={formik.handleBlur}
                className={`border bg-gray-100 rounded-md mr-2`}
              />
              <label htmlFor="benloas" className="text-gray-700">
                BCP/LOAS
              </label>
            </div>

            <div className="flex items-center">
              <input
                disabled={action === 'show'}
                type="checkbox"
                id="benalu"
                value={formik?.values?.familiar?.benalu}
                name="benalu"
                checked={formik?.values?.familiar?.benalu === 'S'}
                onChange={(e) =>
                  formik.setFieldValue(
                    'familiar.benalu',
                    e.target.checked ? 'S' : 'N',
                  )
                }
                onBlur={formik.handleBlur}
                className={`border bg-gray-100 rounded-md mr-2`}
              />
              <label htmlFor="auxilioAluguel" className="text-gray-700">
                Auxílio Aluguel
              </label>
            </div>

            <div className="flex items-center">
              <input
                disabled={action === 'show'}
                type="checkbox"
                id="benpen"
                value={formik?.values?.familiar?.benpen}
                name="benpen"
                checked={formik?.values?.familiar?.benpen === 'S'}
                onChange={(e) =>
                  formik.setFieldValue(
                    'familiar.benpen',
                    e.target.checked ? 'S' : 'N',
                  )
                }
                onBlur={formik.handleBlur}
                className={`border bg-gray-100 rounded-md mr-2`}
              />
              <label htmlFor="benpen" className="text-gray-700">
                Pensão
              </label>
            </div>

            <div className="flex items-center">
              <input
                disabled={action === 'show'}
                type="checkbox"
                id="benrec"
                value={formik?.values?.familiar?.benrec}
                name="benrec"
                checked={formik?.values?.familiar?.benrec === 'S'}
                onChange={(e) =>
                  formik.setFieldValue(
                    'familiar.benrec',
                    e.target.checked ? 'S' : 'N',
                  )
                }
                onBlur={formik.handleBlur}
                className={`border bg-gray-100 rounded-md mr-2`}
              />
              <label htmlFor="benrec" className="text-gray-700">
                Auxílio Reclusão
              </label>
            </div>

            <div className="flex items-center">
              <input
                disabled={action === 'show'}
                type="checkbox"
                id="benout"
                value={formik?.values?.familiar?.benout}
                name="benout"
                checked={formik?.values?.familiar?.benout === 'S'}
                onChange={(e) =>
                  formik.setFieldValue(
                    'familiar.benout',
                    e.target.checked ? 'S' : 'N',
                  )
                }
                onBlur={formik.handleBlur}
                className={`border bg-gray-100 rounded-md mr-2`}
              />
              <label htmlFor="benout" className="text-gray-700">
                Outros
              </label>
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:justify-start">
            <div className="mb-4 md:mb-0 md:mr-4 md:w-1/2">
              <label
                htmlFor="familiar.local"
                className="block text-gray-700 mb-1"
              >
                Local
              </label>
              <input
                disabled={action === 'show'}
                type="text"
                id="familiar.local"
                name="familiar.local"
                value={formik?.values?.familiar?.local}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`border bg-gray-100 rounded-md px-4 py-2 w-full`}
              />
            </div>

            <div className="mb-4 md:mb-0 md:w-1/2">
              <label
                htmlFor="familiar.especifique"
                className="block text-gray-700 mb-1"
              >
                Especifique
              </label>
              <input
                disabled={action === 'show'}
                type="text"
                id="familiar.especifique"
                name="familiar.especifique"
                value={formik?.values?.familiar?.especifique}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`border bg-gray-100 rounded-md px-4 py-2 w-full`}
              />
            </div>
          </div>
        </div>

        <div className="mt-4 p-4 border rounded-lg bg-white shadow-md">
          <h3 className="text-lg font-bold mb-4">Despesas</h3>
          <div className="flex justify-between">
            <div className="mr-4 w-1/4">
              <label
                htmlFor="familiar.despalim"
                className="block text-gray-700 mb-1"
              >
                Alimentação
              </label>
              <input
                disabled={action === 'show'}
                type="text"
                id="familiar.despalim"
                name="familiar.despalim"
                value={formik?.values?.familiar?.despalim}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`border bg-gray-100 rounded-md px-4 py-2 w-full`}
              />
            </div>

            <div className="mr-4 w-1/4">
              <label
                htmlFor="familiar.despagua"
                className="block text-gray-700 mb-1"
              >
                Água
              </label>
              <input
                disabled={action === 'show'}
                type="text"
                id="familiar.despagua"
                name="familiar.despagua"
                value={formik?.values?.familiar?.despagua}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`border bg-gray-100 rounded-md px-4 py-2 w-full`}
              />
            </div>

            <div className="mr-4 w-1/4">
              <label
                htmlFor="familiar.despener"
                className="block text-gray-700 mb-1"
              >
                Energia
              </label>
              <input
                disabled={action === 'show'}
                type="text"
                id="familiar.despener"
                name="familiar.despener"
                value={formik?.values?.familiar?.despener}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`border bg-gray-100 rounded-md px-4 py-2 w-full`}
              />
            </div>

            <div className="w-1/4">
              <label
                htmlFor="familiar.despout"
                className="block text-gray-700 mb-1"
              >
                Outros
              </label>
              <input
                disabled={action === 'show'}
                type="text"
                id="familiar.despout"
                name="familiar.despout"
                value={formik?.values?.familiar?.despout}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`border bg-gray-100 rounded-md px-4 py-2 w-full`}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-start mb-4 mt-4">
          <div className="flex items-center">
            <div>
              <label
                htmlFor="familiar.totbenfam"
                className="block text-gray-700 mb-1"
              >
                Valor total dos Benefícios:{' '}
              </label>
            </div>
            <div className="ml-4">
              <input
                disabled={action === 'show'}
                type="text"
                id="familiar.totbenfam"
                name="familiar.totbenfam"
                value={formik?.values?.familiar?.totbenfam}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`border bg-gray-100 rounded-md px-4 py-2 w-full`}
              />
            </div>
          </div>
          <div className="flex items-center ml-4 mb-4">
            <div className="ml-4">
              <label
                htmlFor="familiar.totrendafam"
                className="block text-gray-700 mb-1"
              >
                Renda Total Familiar:{' '}
              </label>
            </div>
            <div className="ml-4">
              <input
                disabled={action === 'show'}
                type="text"
                id="familiar.totrendafam"
                name="familiar.totrendafam"
                value={formik?.values?.familiar?.totrendafam}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`border bg-gray-100 rounded-md px-4 py-2 w-full`}
              />
            </div>
          </div>
        </div>

        {(action === 'edit' || action === 'new') && (
          <div className="flex justify-start mb-5 mt-5">
            <button
              disabled={action === 'show'}
              onClick={handleSaveFamilly}
              type="submit"
              className="bg-green-500 text-white rounded-md px-4 py-2 hover:bg-green-600 mr-4"
            >
              Salvar Familiares
            </button>
            <button
              disabled={action === 'show'}
              type="button"
              onClick={handleAddParente}
              className="bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-600"
            >
              Adicionar Parente
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default CreateFormFamily;
