import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchAssist,
  fetchAssistById,
} from 'pages/Assist/shared/state/assistThunk';

interface AssistState {
  list: any[];
  individual: any | null;
}

const initialState: AssistState = {
  list: [],
  individual: null,
};

const assistSlice = createSlice({
  name: 'assist',
  initialState,
  reducers: {
    // outras ações...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssist.fulfilled, (state, action: PayloadAction<any[]>) => {
        state.list = action.payload;
      })
      .addCase(
        fetchAssistById.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.individual = action.payload;
        },
      );
  },
});

export default assistSlice.reducer;
