import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useWindow, useAuth, useEncoder } from 'hooks';

import api from 'services/api';

import FullLoading from 'components/Loading';

import NotSupported from 'components/NotSupported';
import IncludeButton from 'components/IncludeButton';
import Header from 'components/Header';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';

import { useLocation, useNavigate } from 'react-router-dom';

import { ContainerProps } from 'interfaces';
import ScreenBanner from 'components/ScreenBanner';

import { Wrapper, Content } from './styles';

const customStyle = {
  visibility: {
    desktop: {
      xOffset: '15',
      yOffset: '35',
      position: 'br',
    },
  },
};

function Container({
  children,
  hideNavbar,
  showIncludeButton,
  buttonAction,
  screenBanner,
  preventgoback,
  gobackclosebehaviour,
}: ContainerProps) {
  const { user } = useAuth();
  const { width } = useWindow();
  const { et64 } = useEncoder();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {}, []);

  return (
    <Wrapper>
      <FullLoading />
      {width < 800 ? (
        <NotSupported />
      ) : (
        <>
          <Header />
          <Navbar
            hidden={hideNavbar}
            preventgoback={preventgoback}
            gobackclosebehaviour={gobackclosebehaviour}
          />
          <Content>
            {screenBanner ? <ScreenBanner>{screenBanner}</ScreenBanner> : null}
            {children}
          </Content>
          <IncludeButton
            isVisible={showIncludeButton}
            buttonAction={buttonAction}
          />
          <Footer />
        </>
      )}
    </Wrapper>
  );
}

Container.defaultProps = {
  hideNavbar: false,
  showIncludeButton: false,
  buttonAction: null,
  screenBanner: null,
  preventgoback: false,
  gobackclosebehaviour: false,
};

export default Container;
