import React from 'react';
import { Wrapper } from './styles';

function NotSupported() {
  return (
    <Wrapper>
      <span>Não é suportado a utilização do sistema nesta resolução.</span>
    </Wrapper>
  );
}

export default NotSupported;
