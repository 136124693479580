import React from 'react';
import { useTransition, animated } from '@react-spring/web';

import { ToastMessage } from 'hooks/toast';
import { useScrolling } from 'hooks';
import Toast from './Toast';
import { Container } from './styles';

interface ToastContainerProps {
  messages: ToastMessage[];
}
function ToastContainer({ messages }: ToastContainerProps) {
  const { scrolled } = useScrolling();
  const transitions = useTransition(messages, {
    from: { right: '-120%', opacity: 0, transform: 'scale(0.1)' },
    enter: { right: '0%', opacity: 1, transform: 'scale(1)' },
    leave: { right: '-120%', opacity: 0, transform: 'scale(0.1)' },
  });

  return (
    <Container>
      {transitions((style, item) => (
        <Toast message={item} style={style} />
      ))}
    </Container>
  );
}

export default ToastContainer;
