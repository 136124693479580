import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #2c3e50;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #e6e6e6;

  span {
    display: flex;
    justify-content: center;
    padding: 3px 7px;
    text-align: center;
  }
`;
