import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth, useEncoder, useLoading, useToast } from 'hooks';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';

const ForgotPassword = () => {
  const shape = yup.object().shape({
    cpf: yup
      .string()
      .trim()
      .required('Campo obrigatório')
      .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido')
      .min(14, 'Quantidade mínima de caracteres não atingida (14)')
      .max(14, 'Quantidade máxima de caracteres excedida (14)'),
  });

  const { toggleLoading, loading } = useLoading();
  const { addToast, errorToast } = useToast();
  const navigate = useNavigate();
  const { et64 } = useEncoder();
  const [submitting, setSubmitting] = useState(false);

  const handleForgotPassword = useCallback(async (data) => {
    toggleLoading();
    try {
      const send = et64(data);

      const response = await api.post(
        '/common/email_forgot_password.php',
        send,
      );
      const { status, message } = response.data;

      if (status) {
        errorToast('err02.3', message);
        return;
      }

      addToast({
        type: 'info',
        title: 'Email enviado',
        description: 'Um email foi enviado para o endereço cadastrado.',
      });

      navigate('/signin', { replace: true });
    } catch (err) {
      errorToast('err02.4');
    } finally {
      toggleLoading();
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(shape),
  });

  return (
    <form
      onSubmit={handleSubmit(handleForgotPassword)}
      className="flex flex-col space-y-4"
    >
      <InputMask
        mask="999.999.999-99"
        {...register('cpf', { required: true })}
        placeholder="CPF"
        disabled={submitting}
        className={`rounded-md border ${errors.cpf ? 'border-red-500' : 'border-gray-300'} p-2 focus:outline-none focus:border-blue-500`}
      />
      {errors.cpf && (
        <p className="text-red-500 font-bold">{errors.cpf.message}</p>
      )}

      <button
        type="submit"
        disabled={submitting}
        className="rounded-md bg-blue-500 text-white py-2 px-4"
      >
        Enviar
      </button>
    </form>
  );
};

export default ForgotPassword;
