import React from 'react';

import { AppProps } from 'interfaces';
import { Container } from './styles';

interface TooltipProps extends AppProps {
  title: string;
  className?: string;
  errorStyle?: object;
}

function Tooltip({ children, title, className, errorStyle }: TooltipProps) {
  return (
    <Container className={className} style={errorStyle}>
      {children}
      <span>{title}</span>
    </Container>
  );
}

Tooltip.defaultProps = {
  className: '',
  errorStyle: {},
};

export default Tooltip;
