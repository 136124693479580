import { openDB } from 'idb';

const dbPromise = openDB('imperium-db', 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains('assisted')) {
      db.createObjectStore('assisted');
    }
    if (!db.objectStoreNames.contains('productTypes')) {
      db.createObjectStore('productTypes');
    }
    if (!db.objectStoreNames.contains('clients')) {
      db.createObjectStore('clients');
    }
  },
});

export const setItem = async (storeName, key, value) => {
  const db = await dbPromise;
  const tx = db.transaction(storeName, 'readwrite');
  const store = tx.objectStore(storeName);
  await store.put(value, key);
  await tx.done;
};

export const getItem = async (storeName, key) => {
  const db = await dbPromise;
  const tx = db.transaction(storeName, 'readonly');
  const store = tx.objectStore(storeName);
  const result = await store.get(key);
  await tx.done;
  return result;
};

export const deleteItem = async (storeName, key) => {
  const db = await dbPromise;
  const tx = db.transaction(storeName, 'readwrite');
  const store = tx.objectStore(storeName);
  await store.delete(key);
  await tx.done;
};

export const getAllItems = async (storeName: 'assisted' | 'collaborators') => {
  const db = await dbPromise;
  const tx = db.transaction(storeName, 'readonly');
  const store = tx.objectStore(storeName);
  const result = await store.getAll();
  await tx.done;
  return result;
};
