import React, { useCallback } from 'react';

import { useAuth } from 'hooks';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { FaPowerOff, FaWhatsapp } from 'react-icons/fa6';
import { HiHome } from 'react-icons/hi';
import { FiChevronsLeft } from 'react-icons/fi';

import {
  NavbarWrapper,
  NavContent,
  Menu,
  LogoutContainer,
  HomeContainer,
  GoBack,
} from './styles';

interface NavbarProps {
  hidden: boolean;
  preventgoback: boolean;
  gobackclosebehaviour: boolean;
}

function Navbar({ hidden, preventgoback, gobackclosebehaviour }: NavbarProps) {
  const { signOut, user } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, []);

  const closeWindow = useCallback(() => {
    window.open('about:blank', '_self');
    window.close();
  }, []);

  return (
    <NavbarWrapper>
      {pathname !== '/home' && !preventgoback ? (
        <GoBack
          type="button"
          onClick={gobackclosebehaviour ? closeWindow : handleGoBack}
        ></GoBack>
      ) : null}
      {!hidden ? (
        <NavContent>
          <HomeContainer>
            <Link to={{ pathname: '/home' }}>
              <HiHome />
            </Link>
          </HomeContainer>
          {user.navigation.length > 0
            ? user.navigation.map((mod) => {
                if (mod.content.length > 0) {
                  return (
                    <Menu
                      key={`${mod.id}-module`}
                      quantity={mod.content.length}
                    >
                      <h3>{mod.module}</h3>
                      <span>
                        {mod.content.map((func) => (
                          <Link
                            key={`anchor${mod.id}-${func.fid}`}
                            to={{
                              pathname: `/${mod.base_path}/${func.base_path}`,
                            }}
                            state={{ read: func.read, write: func.write }}
                          >
                            {func.fname}
                          </Link>
                        ))}
                      </span>
                    </Menu>
                  );
                }
                return null;
              })
            : null}
          {user?.adm ? (
            <Menu key="adm-module" quantity={1}>
              <h3>Admin</h3>
              <span>
                <Link
                  to={{
                    pathname: '/admin/hu3',
                  }}
                  state={{ read: true, write: true }}
                >
                  Upload PHP (404)
                </Link>
              </span>
            </Menu>
          ) : null}
        </NavContent>
      ) : null}

      <LogoutContainer>
        <a
          href={`https://api.whatsapp.com/send?phone=+5511916102310&text=Olá!%0aSou ${user.name}%0a da Área de: ${user.area}%0a e minha função dentro do sistema é de: ${user.function}. %0a%0aEstou entrando em contato para falarmos sobre: [Motivo do Contato]`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FaWhatsapp />
        </a>
        <FaPowerOff onClick={signOut} title="Encerrar sessão" />
      </LogoutContainer>
    </NavbarWrapper>
  );
}

Navbar.defautlProps = {
  hidden: false,
  preventgoback: false,
  gobackclosebehaviour: false,
};

export default Navbar;
