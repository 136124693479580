import axios, { AxiosInstance } from 'axios';
import Cookies from 'js-cookie';
const token = Cookies.get(`${process.env.REACT_APP_TOKEN}`);

const api: AxiosInstance = axios.create({
  // baseURL: process.env.REACT_APP_API,
  baseURL: 'https://imperium.dev.pazinatoti.com.br/api',
  headers: {
    authorization: token,
    app_version: process.env.REACT_APP_VERSION,
  },
});

export const get = async <T>(endpoint: string): Promise<T> => {
  try {
    const response = await api.get<T>(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default api;
