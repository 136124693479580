import styled, { keyframes } from 'styled-components';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';

interface DContentProps {
  quantity: number;
}

const appearFromRight = keyframes`
  from {
    width: 0px;
    box-shadow: 0px 0px 0px 0px #004d1a;
    transform: translateX(15px);
    pointer-events: none;
  } to {
    width: 45px;
    box-shadow: 0px 5px 10px 2px #004d1a;
    transform: translateX(10px);
    pointer-events: all;
  }
`;

export const Wrapper = styled.div`
  cursor: pointer;

  z-index: 2;
  position: fixed;
  top: 145px;
  right: 0px;
  height: 40px;

  display: flex;
  align-items: center;
  padding-left: 5px;

  border: 2px solid #00802b;
  border-radius: 5px 0 0 5px;
  background: #00802b;
  overflow: hidden;

  pointer-events: none;
  width: 0px;

  box-shadow: 0px 0px 0px 0px #004d1a;
  transform: translateX(15px);

  transition: transform 0.5s ease, box-shadow 0.5s ease, width 0.5s ease;

  animation: ${appearFromRight} 0.5s ease forwards;
  a,
  button {
    background: transparent;
    border: 0;
    height: 100%;
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #efefef;
  }

  p {
    margin-left: 10px;
  }
  svg {
    border-radius: 50%;
    height: 30px;
    width: 30px;
    color: #efefef;
    transition: all 0.25s ease;
    box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0);
  }

  &:hover {
    svg {
      color: #fff;
      box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0.75);
      filter: brightness(120%);
    }
  }
`;

// export const DDialog = styled(Dialog)`
//   margin: 0 auto;
// `;

// export const DActions = styled(DialogActions)`
//   background: #c53030;
//   button {
//     font-size: 16px;
//     width: 100px;
//     padding: 3px;

//     border: 2px solid #fff;
//     border-radius: 5px;

//     background: #fff;
//     color: #c53030;

//     font-family: 'Roboto Slab', serif;

//     transition: background-color 0.35s ease, color 0.35s ease;

//     &:hover {
//       background-color: transparent;
//       color: #fff;
//     }
//   }
// `;

// export const DContent = styled(DialogContent)<DContentProps>`
//   height: calc(${(props) => props.quantity} * 100px);
//   max-width: calc(${(props) => props.quantity} * 155px);
//   display: flex;
//   flex-direction: column;
//   align-items: center;

//   > h2 {
//     margin: 10px 0;
//   }

//   > form {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;

//     button {
//       margin-top: 15px;
//     }
//   }

//   ::-webkit-scrollbar {
//     width: 2px;
//   }

//   ::-webkit-scrollbar-track {
//     background: #e6e6e6;
//   }

//   ::-webkit-scrollbar-thumb {
//     background: #8a0002;

//     &:hover {
//       background: #c53030;
//     }
//   }
// `;

// export const DContentText = styled(DialogContentText)``;

// export const DTitle = styled(DialogTitle)`
//   background: #c53030;

//   color: #efefef;
//   font-family: 'Roboto Slab', serif;
//   h2 {
//     font-size: 24px;
//   }
// `;
