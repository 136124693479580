import React, { createContext, useContext, useCallback, useState } from 'react';

import { v4 } from 'uuid';

import { AppProps } from 'interfaces';
import { useLocation } from 'react-router-dom';
import ToastContainer from '../components/ToastContainer';

export interface ToastMessage {
  id: string;
  type?: 'success' | 'error' | 'info';
  title: string;
  description?: string;
  seconds?: number;
  showIcon?: boolean;
  // setter?: string;
}

interface ToastHookData {
  addToast(message: Omit<ToastMessage, 'id'>, setter?: string): void;
  errorToast(errorCode: string, customMessage?: string): void;
  removeToast(ev): void;
}

const ToastHook = createContext<ToastHookData>({} as ToastHookData);
function ToastProvider({ children }: AppProps) {
  const { pathname } = useLocation();
  const [messages, setMessages] = useState<ToastMessage[]>([]);
  const soundSuccess = new Audio(
    `${process.env.REACT_APP_NOTIFICATION_SOUNDS}/success.mp3`,
  );
  const soundError = new Audio(
    `${process.env.REACT_APP_NOTIFICATION_SOUNDS}/error.mp3`,
  );

  const addToast = useCallback(
    (
      {
        type,
        title,
        description,
        seconds = 5,
        showIcon = false,
      }: Omit<ToastMessage, 'id'>,
      setter = 'none',
    ) => {
      const id = v4();

      const toast = {
        id,
        type,
        title,
        description,
        seconds,
        showIcon,
      };

      if (type === 'error') {
        if (setter === 'none') {
          soundError.volume = 0.3;
          soundError.play();
        }
      } else {
        soundSuccess.volume = 0.3;
        soundSuccess.play();
      }
      setMessages((state) => [...state, toast]);
    },

    [],
  );

  const removeToast = useCallback((ev) => {
    const id = typeof ev === 'string' ? ev : ev.currentTarget.dataset.id;
    setMessages((state) => state.filter((message) => message.id !== id));
  }, []);

  const errorToast = useCallback(
    (errorCode: string, customMessage?: string) => {
      const message =
        customMessage ||
        'Entre em contato com o suporte e informe o código acima';
      addToast({
        type: 'error',
        title: `Erro na requisição (cod. ${errorCode})`,
        description: message,
      });
    },
    [],
  );

  return (
    <ToastHook.Provider value={{ addToast, removeToast, errorToast }}>
      {children}
      <ToastContainer messages={messages} />
    </ToastHook.Provider>
  );
}

function useToast(): ToastHookData {
  const context = useContext(ToastHook);

  if (!context) {
    throw Error('useToast must be used within ToastProvider');
  }

  return context;
}

export { ToastProvider, useToast };
