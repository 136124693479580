import Auth from 'pages/common/auth';
import Home from 'pages/common/home';

const commonRoutes = [
  { path: '/admsignin', component: Auth },
  { path: '/signin', component: Auth },
  { path: '/forgot-password', component: Auth },
  { path: '/first-access', component: Auth },
  { path: '/reset-password/:token', component: Auth },
  { path: '/home', component: Home },
];
export default commonRoutes;
