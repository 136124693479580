import React from 'react';

import { useAuth } from 'hooks';

import { Navigate, useLocation, useNavigate } from 'react-router-dom';

interface PrivateRouteProps {
  outlet: JSX.Element;
  adminOnly?: boolean;
}

function PrivateRoute({ outlet, adminOnly }: PrivateRouteProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user } = useAuth();
  const ADMauthenticationPath = '/admsignin';
  const authenticationPath = '/signin';
  const forgotPasswordPath = '/forgot-password';
  const resetPasswordPath = 'reset-password';
  const basePath = '/home';
  const firstAccessPath = '/first-access';

  if (
    !user &&
    [authenticationPath, forgotPasswordPath, ADMauthenticationPath].indexOf(
      pathname,
    ) < 0 &&
    !pathname.includes(resetPasswordPath)
  ) {
    // navigate(authenticationPath, { replace: true });
    return <Navigate to={{ pathname: authenticationPath }} replace />;
  }
  if (!!user && pathname === '/signin') {
    // navigate(basePath, { replace: true });
    return <Navigate to={{ pathname: basePath }} replace />;
  }
  if (!!user && !!user.passchange && pathname !== firstAccessPath) {
    // navigate(firstAccessPath, { replace: true });
    return <Navigate to={{ pathname: firstAccessPath }} replace />;
  }
  if (adminOnly && !!user && !user.adm) {
    // navigate(basePath, { replace: true });
    return <Navigate to={{ pathname: basePath }} replace />;
  }
  return outlet;
}

PrivateRoute.defaultProps = {
  adminOnly: false,
};
export default PrivateRoute;
