import React, { useRef, useEffect, SelectHTMLAttributes } from 'react';

import { useField } from '@unform/core';

// import 'styles/select.css';

import { ComboProps } from 'interfaces';

import { SelectWrapper } from './styles';

interface CustomProps {
  name: string;
  content?: any;
  initial?: string;
  containerstyle?: object;
  labelStyle?: object;
  selectStyle?: object;
  dropdownStyle?: object;
  caretStyle?: object;
  disabled?: boolean;
  label?: string;
}

type SelectProps = JSX.IntrinsicElements['select'] & CustomProps;

function Select({
  name,
  content,
  initial,
  containerstyle,
  labelStyle,
  selectStyle,
  dropdownStyle,
  caretStyle,
  disabled,
  label,
  ...rest
}: SelectProps) {
  const selectRef = useRef<HTMLSelectElement>(null);
  const { fieldName, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value',
    });
  }, [registerField, fieldName]);

  return (
    <SelectWrapper style={containerstyle} error={!!error}>
      {label ? <p style={labelStyle}>{label}</p> : null}

      <select
        name={name}
        ref={selectRef}
        style={selectStyle}
        {...rest}
        disabled={content.length === 0 || disabled}
      >
        <option value="" style={{ display: 'none' }}>
          Selecione
        </option>

        {content.map((item: ComboProps) => (
          <option
            key={item.value}
            value={item.value}
            selected={!!initial && item.value === initial}
          >
            {item.label}
          </option>
        ))}
      </select>
    </SelectWrapper>
  );
}

Select.defaultProps = {
  content: [],
  initial: null,
  containerstyle: {},
  labelStyle: {},
  selectStyle: {},
  dropdownStyle: {},
  caretStyle: {},
  disabled: false,
  label: null,
};

export default Select;
