import './style.css';
const ManagmentDelete = ({content, onCreateDelete} ) => {

    const handleDelete = (e) => {
        e.preventDefault();
        console.log('deletar')
        onCreateDelete();
    }

    return (<>
        <div>{content}</div>
        <div className="mx-auto mt-8 btn-delete" >
        <button type="submit" onClick={handleDelete} className="bg-red-500 text-white rounded-md px-4 py-2">Deletar</button>
        </div>
       
    </>)
}

export default ManagmentDelete;