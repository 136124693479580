import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

const calendarURL = `${process.env.REACT_APP_IMPERIUM_ASSETS}/misc/calendar-86e595d0f0d74baba94558655a198684.png`;

interface WrapperProps {
  hasError: boolean;
  ishidden: boolean;
  labelpresent: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  border-radius: 5px;
  padding: 3px 7px;
  background: #f6f6f6;
  height: 25px;
  width: 350px;
  margin: 0;
  ${(props) =>
    props.labelpresent &&
    css`
      margin: 30px 0;
    `}

  display: flex;
  align-items: center;

  color: #c6c6c6;
  border: 2px solid #a6a6a6;

  input[type='date']::-webkit-calendar-picker-indicator,
  input[type='datetime-local']::-webkit-calendar-picker-indicator {
    cursor: pointer;
    background: url(${calendarURL}) no-repeat;
    display: block;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 1.2em;
  }

  input {
    flex: 1;
    border: 0;
    background-color: transparent;
    color: #332e2e;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    outline: none;

    &::placeholder,
    &:disabled {
      color: #a6a6a6;
    }

    &:disabled {
      cursor: not-allowed;
      border-color: #a6a6a6;
    }
  }

  ${(props) =>
    props.hasError &&
    css`
      border-color: #c53030;
      background: #ffebee;
    `};

  label {
    color: #464646;
    font-weight: 700;
    position: absolute;
    top: -25px;
    left: 0;
  }

  > svg {
    margin-right: 16px;
    width: 15px;
    height: 15px;
  }

  ${(props) =>
    props.ishidden &&
    css`
      height: 0px;
      border: 0;
      background: transparent;
      padding: 0;
      margin: 0;
      overflow: hidden;
    `};
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  svg {
    margin: 0;
  }

  span {
    /* background: #c53030; */
    color: #fff;

    &::before {
      /* border-color: #c53030 transparent; */
    }
  }
`;

export const ViewPass = styled.div`
  height: 100%;

  display: flex;
  align-items: center;
  margin-left: 16px;

  svg {
    cursor: pointer;
    margin: 0px;
  }
`;
