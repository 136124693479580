import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from 'hooks';
import InputMask from 'react-input-mask';

const SignInPage = () => {

    const shape = yup.object().shape({
        cpf: yup
            .string()
            .trim()
            .required('Campo obrigatório')
            .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido')
            .min(14, 'Quantidade mínima de caracteres não atingida (14)')
            .max(14, 'Quantidade máxima de caracteres excedida (14)'),

        password: yup.string().trim().required('Senha obrigatória'),
    });

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(shape),
    });

    const { signIn } = useAuth();
    const [submitting, setSubmitting] = useState(false);
    const handleSign = useCallback(async (formData) => {
        try {
            const { cpf, password } = formData;
            setSubmitting(true);
            await signIn({ cpf, pass: password });
        } catch (error) {
            console.error('Erro ao realizar login:', error);
        } finally {
            setSubmitting(false);
        }
    }, [signIn]);


    return (
        <form onSubmit={handleSubmit(handleSign)} className="flex flex-col space-y-4">
          <InputMask
            mask="999.999.999-99" 
            {...register("cpf", { required: true })}
            placeholder="CPF"
            disabled={submitting}
            className={`rounded-md border ${errors.cpf ? 'border-red-500' : 'border-gray-300'} p-2 focus:outline-none focus:border-blue-500`}
          />
          {errors.cpf && <p className="text-red-500 font-bold">{errors.cpf.message}</p>}
      
          <input
            name="password"
            type="password"
            {...register("password", { required: true })}
            placeholder="Senha"
            disabled={submitting}
            className={`rounded-md border ${errors.password ? 'border-red-500' : 'border-gray-300'} p-2 focus:outline-none focus:border-blue-500`}
          />
          {errors.password && <p className="text-red-500 font-bold">{errors.password.message}</p>}
      

          <button type="submit" disabled={submitting} className="rounded-md bg-blue-500 text-white py-2 px-4">
            Enviar
          </button>
        </form>
      );
}

export default SignInPage;
