import { Actions } from 'styles';
import './style.css';

import Action from 'components/Action';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'hooks';

const AssistList = ({ assists }) => {
  const { user } = useAuth();
  const { state, pathname } = useLocation();

  return (
    <div className="card-list flex flex-wrap justify-center">
      {assists?.map((assist) => (
        <div key={assist.id} className="card-list-item relative m-4 w-80">
          {/* Avatar */}
          <div className="avatar flex items-center justify-center bg-white border rounded-full overflow-hidden absolute top-0 left-1/2 transform -translate-x-1/2">
            <img
              style={{ height: '25px' }}
              className="rounded-full object-cover"
              src={`${process.env.REACT_APP_AVATAR}/${assist.avatar}`}
              alt="assist.name"
            />
          </div>

          {/* Card */}
          <div className="card bg-white rounded-lg shadow-lg relative z-10 w-full h-50">
            {/* Content */}
            <div className="p-6">
              <h2 className="font-bold text-xl mb-2 mt-4">
                {assist.nomecompl}
              </h2>
              <p className="mb-2">
                <span className="font-semibold text-xl mr-1">CPF:</span>
                <span className="text-xl">{assist.cpf}</span>
              </p>
              <p className="text-xl italic">
                {assist.descgest || 'Chão de fábrica'}
              </p>
            </div>

            {/* Footer */}
            <div className="flex justify-center p-4 border-t border-gray-200">
              <Actions>
                <Action
                  type="show"
                  link={{
                    pathname: `${pathname}/${assist.id}`,
                    state: { ...state },
                    newtab: true,
                  }}
                />
                {user?.adm || state.write ? (
                  <Action
                    type="edit"
                    link={{
                      pathname: `${pathname}/${assist.id}`,
                      state: { ...state },
                    }}
                  />
                ) : null}
              </Actions>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AssistList;
