import React, { useState } from 'react';
import { CompanyInformation, FooterWrapper } from './styles';

function Footer() {
  const [message, setMessage] = useState(() => {
    const year = new Date().getUTCFullYear();

    return year === 2023 ? `${year}` : `2023 - ${year}`;
  });

  return (
    <FooterWrapper>
      <CompanyInformation>
        <p>
          {message} © Copyright - Todos os direitos reservados a{' '}
          <a
            href="https://pazinatoti.com.br"
            rel="noopener noreferrer"
            target="_blank"
          >
            Pazinato TI
          </a>
          .
        </p>
      </CompanyInformation>
    </FooterWrapper>
  );
}

export default Footer;
