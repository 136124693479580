import React, { createContext, useContext, useState, useCallback } from 'react';
import { AppProps } from 'interfaces';

interface ModalHookData {
  toggleModal: ((ev?: any) => void) | null;
  modalConfig: ((ev?: any) => void) | null;
  modalContent?: JSX.Element;
  active: boolean;
  modalData?: object;
  confirmReference?: string;
  cancelReference?: string;
  dialogType?: 'info' | 'insert' | 'update' | 'delete';
  eventDetails?: object;
}

const ModalHook = createContext<ModalHookData>({} as ModalHookData);

function ModalProvider({ children }: AppProps) {
  const [modalData, setModalData] = useState<object>({} as any);
  const [active, setActive] = useState(false);
  const [dialogType, setDialogType] = useState<
    'info' | 'insert' | 'update' | 'delete'
  >('info');
  const [content, setContent] = useState(null);
  const [confirmReference, setConfirmReference] = useState(null);
  const [cancelReference, setCancelReference] = useState(null);
  const [eventDetails, setEventDetails] = useState({} as any);

  const toggleModal = useCallback((ev) => {
    // if (ev) {
    //   setModalData({ ...ev.detail });
    // } else {
    //   setModalData({});
    // }
    setActive((state) => !state);
  }, []);

  const modalConfig = useCallback((ev) => {
    setContent(ev.detail.content);
    setConfirmReference(ev.detail.confirmActionReference);
    setCancelReference(ev.detail.cancelActionReference);
    setDialogType(ev.detail.dialogType);
    setEventDetails(ev.detail.eventDetails);

    const newEvent = new CustomEvent('toggleModal');
    toggleModal(newEvent);
  }, []);

  return (
    <ModalHook.Provider
      value={{
        toggleModal,
        active,
        modalData,
        modalConfig,
        modalContent: content,
        confirmReference,
        cancelReference,
        dialogType,
        eventDetails,
      }}
    >
      {children}
    </ModalHook.Provider>
  );
}

function useModal(): ModalHookData {
  const context = useContext(ModalHook);

  if (!context) {
    throw new Error('useModal must be used within an ModalHookProvider');
  }

  return context;
}
export { ModalProvider, useModal };
