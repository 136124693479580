import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Container } from 'components';

import { useLocation } from 'react-router-dom';

import { useLoading } from 'hooks';

import { Tabs, TabList, TabPanel } from 'react-tabs';
import CustomTab, { CustomTabErrors } from 'components/CustomTab';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import 'styles/tabs.css';

import api from 'services/api';

import * as C from '../components';

import {
  Colaborator,
  lsKey,
  generateColaborator,
  ColaboratorFamilyMembers,
} from '../colaborator';

function ColaboradorShow() {
  const formRef = useRef<FormHandles>(null);
  const { toggleLoading, loading } = useLoading();
  const location = useLocation();
  const [data, setData] = useState<any>({} as any);

  const [currentTab, setCurrentTab] = useState(0);

  const getColaborator = useCallback(async () => {
    toggleLoading();
    try {
      const splitted = location.pathname.split('/');
      const colaboratorID = splitted[3];

      const response = await api.get(
        `/system/colaborador_info.php?data=${JSON.stringify({
          id: colaboratorID,
        })}`,
      );

      const received = response.data as any;
      setData({ ...generateColaborator(received) });
    } catch (err) {
      console.error(err);
    } finally {
      toggleLoading();
    }
  }, []);

  useEffect(() => {
    console.clear();
    getColaborator();
  }, []);

  const handleCurrentTab = useCallback((tab, previous) => {
    console.clear();

    setTimeout(() => {
      setCurrentTab(tab);
    }, 10);
  }, []);

  return (
    <Container
      gobackclosebehaviour
      hideNavbar
      screenBanner={<div>Consulta de Colaborador</div>}
    >
      {Object.keys(data).length > 0 ? (
        <Form
          ref={formRef}
          onSubmit={null}
          style={{ margin: '0 auto' }}
          placeholder=""
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <Tabs onSelect={handleCurrentTab} selectedIndex={currentTab}>
            <TabList>
              <CustomTab errorAmount={0}>Dados Pessoais</CustomTab>
              <CustomTab errorAmount={0}>Endereço</CustomTab>
              <CustomTab errorAmount={0}>Familiares</CustomTab>
              <CustomTab errorAmount={0}>Escolaridade</CustomTab>
              <CustomTab errorAmount={0}>Função</CustomTab>
            </TabList>
            <TabPanel>
              <C.Personal onDataChange={null} data={data.person} mode="show" />
            </TabPanel>
            <TabPanel>
              <C.Address data={data.address} mode="show" />
            </TabPanel>
            <TabPanel>
              <C.Family
                familyInfo={
                  {
                    maritalStatus: data.person.marital_status,
                  } as ColaboratorFamilyMembers
                }
                data={data.family}
                mode="show"
              />
            </TabPanel>
            <TabPanel>
              <C.Education data={data.education} mode="show" />
            </TabPanel>
            <TabPanel>
              <C.Role data={data.role} mode="show" />
            </TabPanel>
          </Tabs>
        </Form>
      ) : null}
    </Container>
  );
}

export default ColaboradorShow;
