import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';

export const fetchAssist = createAsyncThunk<any>('assist/fetch', async () => {
  const response = await api.get('system/assistida_list.php?tipo=A');
  return response.data.xassist;
});

export const fetchAssistById = createAsyncThunk<any, string>(
  'assist/fetchById',
  async (id: string) => {
    const response = await api.get(
      `system/assistida.php?funcao=consulta&pessoa=${id}`,
    );
    return response.data;
  },
);

export const changeAssistById = createAsyncThunk<
  any,
  { id: string; assistida: any }
>('assist/changeById', async ({ id, assistida }) => {
  const response = await api.put(
    `system/assistida.php?funcao=alteracao&pessoa=${id}`,
    assistida,
  );
  return response.data;
});

export const saveAssist = createAsyncThunk<any, any>(
  'assist/saveAssist',
  async (assistida) => {
    const response = await api.post(
      'system/assistida.php?funcao=inclusao',
      assistida,
    );
    return response.data;
  },
);
