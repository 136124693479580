import Assist from 'pages/Assist';
import CreateAssist from 'pages/Assist/Create';

const socialRoutes = [
  { path: '/assistidas', component: Assist },
  { path: '/assistidas/:id/edit', component: CreateAssist },
  { path: '/assistidas/:id/show', component: CreateAssist },
  { path: '/assistidas/new', component: CreateAssist },
].map((item) => {
  return { ...item, path: `/social${item.path}` };
});
export default socialRoutes;
