import Colaborador from 'pages/personal/Colaborador/main';
import ColaboradorInsert from 'pages/personal/Colaborador/insert';
import ColaboradorUpdate from 'pages/personal/Colaborador/update';
import ColaboradorShow from 'pages/personal/Colaborador/show';

import Acesso from 'pages/personal/Acesso';

const personalRoutes = [
  { path: '/colaboradores', component: Colaborador },
  { path: '/colaboradores/new', component: ColaboradorInsert },
  { path: '/colaboradores/:id/edit', component: ColaboradorUpdate },
  { path: '/colaboradores/:id/show', component: ColaboradorShow },
  { path: '/acessos', component: Acesso },
].map((item) => {
  return { ...item, path: `/pessoal${item.path}` };
});
export default personalRoutes;
