import styled, { css } from 'styled-components';
import { lighten } from 'polished';

import { TableWrapper } from 'styles';

export const FilterWrapper = styled.div`
  --borderColor: #0054a6;
  --fontColor: #004080;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  margin: 0 auto;
  width: 100%;
  /* background: #c53030; */

  input {
    position: relative;
    border-radius: 3px;
    padding: 3px 7px;
    background: #f6f6f6;
    /* height: 25px; */
    width: 175px;
    margin: 0;
    color: var(--fontColor);
    border: 2px solid var(--borderColor);
    font-size: 14px;
    &::placeholder {
      font-style: italic;
      color: #bfbfbf;
    }
  }

  input[type='search']::-webkit-search-decoration:hover,
  input[type='search']::-webkit-search-cancel-button:hover {
    cursor: pointer;
  }

  select {
    cursor: pointer;
    width: 175px;
    border: 2px solid var(--borderColor);
    border-radius: 3px;

    color: var(--fontColor);
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 15px;
  margin: 20px 0;
  /* background: #c53030; */
`;

export const ModTableWrapper = styled(TableWrapper)`
  table {
    td {
      padding: 7px 5px;

      input {
        height: 25px;
        width: 25px;
      }
    }
  }
`;

export const CheckboxContainer = styled.span``;
export const CheckboxLabel = styled.label``;
