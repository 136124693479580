import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import assist from '../pages/Assist/shared/state/assistReducer';
import common from '../pages/common/store/commonReducer';

const store = configureStore({
  reducer: { assist, common },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export default store;
