import React, { useEffect, useState } from 'react';

import ChildForm from 'pages/Assist/Create-Form-Children/ChildForm';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCombosPersonal } from 'pages/common/store/commonThunk';

const CreateFormChildren = ({
  action,
  handleTabChange,
  formData,
  setFormData,
  onUpdateFormStatus,
}) => {
  const [children, setChildren] = useState(formData || []);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCombosPersonal());
  }, [dispatch]);
  const { ethnicity, gender } = useSelector((state: any) => state?.common);

  const handleAddChild = () => {
    setChildren([
      ...children,
      {
        nome: '',
        sobrenome: '',
        dataNascimento: '',
        hora: '',
        sexo: '',
        etnia: '',
        rg: '',
        cpf: '',
        cin: '',
        tamNas: '',
        pesoNasc: '',
        tamAtual: '',
        pesoAtual: '',
        ocorrencias: '',
        vacinas: [],
      },
    ]);
  };
  useEffect(() => {
    onUpdateFormStatus(true, children);
    setFormData(children);
  }, [children]);

  const handleRemoveChild = (index) => {
    const newChildren = children.filter((_, i) => i !== index);
    setChildren(newChildren);
  };

  const handleChildUpdate = (index, updatedChild) => {
    const newChildren = [...children];
    newChildren[index] = updatedChild;
    setChildren(newChildren);
  };

  const handleChildrenDataValidation = (formik) => {
    const isValid = children.every((child) => child.nome && child.sobrenome);

    if (!isValid) {
      alert('Nome e Sobrenome são obrigatórios para todas as crianças.');
    } else {
      setFormData((prevData) => ({
        ...prevData,
        criancas: children,
      }));
      setTimeout(() => {
        handleTabChange(7);
      }, 1000);
    }
  };

  return (
    <div>
      {children?.map((child, index) => (
        <ChildForm
          action={action}
          key={index}
          index={index}
          child={child}
          onRemove={() => handleRemoveChild(index)}
          onUpdate={(updatedChild) => handleChildUpdate(index, updatedChild)}
          ethnicity={ethnicity}
          gender={gender}
        />
      ))}
      {(action === 'edit' || action === 'new') && (
        <div className="flex justify-start mt-1">
          <button
            onClick={handleAddChild}
            className="bg-blue-500 text-white px-4 py-2 rounded mt-4 mr-4"
          >
            Nova Criança
          </button>
          <button
            onClick={handleChildrenDataValidation}
            className="bg-green-500 text-white px-4 py-2 rounded mt-4"
          >
            Validar e Prosseguir
          </button>
        </div>
      )}
      {/* <br /><br />
      <h3>Crianças Cadastradas</h3>
      <pre>{JSON.stringify(children, null, 2)}</pre> */}
    </div>
  );
};

export default CreateFormChildren;
