import React, { useCallback, useEffect, useState } from 'react';
import { AppProps } from 'interfaces';

import { useAuth, useModal } from 'hooks';
import { createPortal } from 'react-dom';

import { useSpring } from '@react-spring/web';
import { AiOutlineCloseCircle } from 'react-icons/ai'; // Importando o ícone

import {
  ModalContainer,
  ModalBodyWrapper,
  ModalHeader,
  ModalContent,
  ModalContentChildren,
  ModalFooter,
  CancelButton,
  ConfirmButton,
} from './styles';

const modalRoot = document.getElementById('modal-root');

function Modal() {
  const { user } = useAuth();
  const {
    active,
    toggleModal,
    modalContent,

    dialogType,

  } = useModal();

  const styledModal = useSpring({
    opacity: active ? 1 : 0,

  });



  const handleCancelAction = useCallback(() => {
    toggleModal();
  }, []);

  return createPortal(
    <ModalContainer
      id="modal-container"
      style={{ ...styledModal, pointerEvents: active ? 'all' : 'none' }}
    >
      <ModalBodyWrapper dialogtype={dialogType}>
        <ModalHeader>
          {dialogType === 'insert'
            ? 'Novo'
            : dialogType === 'update'
            ? 'Editar'
            : 'Excluir'}
          &nbsp;registro
          <button className="absolute top-0 right-0 mr-2 mt-2" onClick={handleCancelAction}>
            <AiOutlineCloseCircle size={24} />
          </button>
        </ModalHeader>
        <ModalContent>
          {active ? (
            <>
              {dialogType === 'delete' ? (
                <p style={{ margin: '0 0 20px 0' }}>
                  Você está prestes a excluir&nbsp;
                  <strong style={{ color: '#c53030' }}>permanentemente</strong>
                  &nbsp;o registro:
                </p>
              ) : null}
              <ModalContentChildren>{modalContent}</ModalContentChildren>
            </>
          ) : null}
        </ModalContent>
        <ModalFooter>
        </ModalFooter>
      </ModalBodyWrapper>
    </ModalContainer>,
    modalRoot,
  );
}

export default Modal;
