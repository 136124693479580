import api from 'services/api';
import {
  setCombosAddress,
  setCombosEducation,
  setCombosPersonal,
} from './commonAction';

export const fetchCombosPersonal = (): any => async (dispatch) => {
  try {
    const response = await api.get('combos/colaborador_dados_pessoais.php');
    dispatch(setCombosPersonal(response.data));
  } catch (error) {
    console.error('Erro ao buscar os combos', error);
  }
};

export const fetchCombosAddress = (): any => async (dispatch) => {
  try {
    const response = await api.get('combos/colaborador_endereco.php');
    dispatch(setCombosAddress(response.data));
  } catch (error) {
    console.error('Erro ao buscar os combos', error);
  }
};

export const fetchCombosEducation = (): any => async (dispatch) => {
  try {
    const response = await api.get('combos/colaborador_escolaridade.php');
    dispatch(setCombosEducation(response.data));
  } catch (error) {
    console.error('Erro ao buscar os combos', error);
  }
};
