import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Wrapper, WrapperContent, MessageContainer } from './styles';

function NotFound() {
  const navigate = useNavigate();
  const handleGoBack = useCallback(() => {
    navigate('/signin', { replace: true });
  }, []);

  return (
    <Wrapper>
      <WrapperContent>
        <MessageContainer>
          <h2>[404]</h2>
          <span>
            <p>A página que você procura não existe...</p>
            <p>...e tudo bem, isso pode acontecer.</p>
          </span>
          <button type="button" onClick={handleGoBack}>
            Voltar para o Imperium®
          </button>
        </MessageContainer>
      </WrapperContent>
    </Wrapper>
  );
}

export default NotFound;
