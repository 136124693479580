import React, { useCallback, useEffect, useState } from 'react';
import { useLocalStorage, useLoading } from 'hooks';
import { FormDateInput, FormInput, FormSelect, FormTextarea } from 'components';

import api from 'services/api';

import { ComboProps } from 'interfaces';
import ComponentWrapper from '../wrapper';
import { FinishButton } from './styles';
import { lsKey } from '../../colaborator';

interface RoleProps {
  mode?: 'insert' | 'update' | 'show';
  data?: any;
}

function ColaboradorRole({ mode, data }: RoleProps) {
  const { getLocalStorage } = useLocalStorage();
  const { toggleLoading } = useLoading();
  const [cbos, setCBOs] = useState<ComboProps[]>([]);
  const [bonds, setBonds] = useState<ComboProps[]>([]);
  const [personTypes, setPersonTypes] = useState<ComboProps[]>([]);
  const [prefixed, setPrefixed] = useState<any>({} as any);

  const handleGetCombos = useCallback(async () => {
    toggleLoading();
    try {
      const response = await api.get('/combos/colaborador_funcao.php');
      setCBOs(response.data.cbo);
      setBonds(response.data.bond);
      setPersonTypes(
        response.data.person_type.filter((item) => item.value !== 'A'),
      );
    } catch (err) {
      console.error(err);
    } finally {
      toggleLoading();
    }
  }, []);

  useEffect(() => {
    handleGetCombos();
    const locallyStored: any = getLocalStorage(lsKey);
    if (Object.keys(data).length > 0) {
      if (mode === 'update') {
        setPrefixed({ ...locallyStored });
      } else {
        setPrefixed({ ...data });
      }
    } else {
      if (locallyStored) {
        setPrefixed({ ...locallyStored });
      }
    }
  }, [handleGetCombos]);

  return (
    <ComponentWrapper sectionName="Função">
      <div>
        <FormSelect
          name="cbo"
          label="CBO"
          content={cbos}
          initial={prefixed.cbo || null}
          disabled={mode === 'show'}
        />
        <FormInput
          name="role"
          placeholder="Função na empresa"
          label="Função na Empresa"
          defaultValue={prefixed.role || null}
          disabled={mode === 'show'}
        />
        <FormInput
          name="area"
          placeholder="Área de atuação"
          label="Área de Atuação"
          defaultValue={prefixed.area || null}
          disabled={mode === 'show'}
        />
      </div>
      <div>
        <FormTextarea
          altHeight={75}
          label="Atividades na Função"
          name="activities"
          placeholder="Informação das atividades na função"
          defaultValue={prefixed.activities || null}
          disabled={mode === 'show'}
        />
        <FormSelect
          name="bond"
          label="Vínculo"
          content={bonds}
          initial={prefixed.bond || null}
          containerstyle={{ padding: '0px', marginTop: '6px' }}
          disabled={mode === 'show'}
        />
        <FormSelect
          name="person_type"
          label="Tipo"
          content={personTypes}
          initial={prefixed.person_type || null}
          containerstyle={{ padding: '0px', marginTop: '6px' }}
          disabled={mode === 'show'}
        />
      </div>
      <div>
        <FormInput
          name="register_num"
          placeholder="Registro"
          label="Registro"
          defaultValue={prefixed.register_num || null}
          disabled={mode === 'show'}
        />

        <FormDateInput
          name="job_start"
          label="Data Entrada"
          picked={prefixed.job_start}
          disabled={mode === 'show'}
        />

        {mode !== 'insert' ? (
          <FormDateInput
            name="job_end"
            label="Data Saída"
            picked={prefixed.job_end}
            disabled={mode === 'show'}
          />
        ) : null}
      </div>

      {mode !== 'show' ? (
        <FinishButton type="submit">Salvar</FinishButton>
      ) : null}
    </ComponentWrapper>
  );
}

ColaboradorRole.defaultProps = {
  mode: 'insert',
  data: {},
};

export default ColaboradorRole;
