import React, { useState, useEffect, useCallback } from 'react';
import { useLoading, useCredentials } from 'hooks';
import { Button, Container } from 'components';
import { useLocation } from 'react-router-dom';
import { Table } from 'styles';
import api from 'services/api';

import { ButtonContainer, FilterWrapper, ModTableWrapper } from './styles';

function Acesso() {
  const { state } = useLocation();
  const { handlePermission, errorHandling } = useCredentials();
  const { toggleLoading } = useLoading();
  const [availableColaborators, setAvailableColaborators] = useState([]);
  const [colaborators, setColaborators] = useState([]);
  const [hasSelection, setHasSelection] = useState(false);
  const [currentSelection, setCurrentSelection] = useState([]);
  const [access, setAccess] = useState([]);

  const getColaborators = useCallback(async () => {
    toggleLoading();
    try {
      const response = await api.get(
        '/system/acesso_actions.php?get-colaboradores',
      );
      setColaborators(response.data);
      setAvailableColaborators(
        response.data
          .filter(
            (obj, index, self) =>
              index === self.findIndex((o) => o.id === obj.id),
          )
          .map((element) => ({
            value: element.id,
            label: element.name,
          })),
      );
    } catch (err) {
      errorHandling(err);
    } finally {
      toggleLoading();
    }
  }, [errorHandling]);

  useEffect(() => {
    if (state) {
      handlePermission(state);
    }
    getColaborators();
  }, []);

  const handleColaboratorSelection = useCallback(
    (ev) => {
      toggleLoading();
      const selected = ev.currentTarget.value;
      const filtered = colaborators.filter((item) => item.id === selected);
      setAccess([]);

      setHasSelection(ev.currentTarget.value !== '');
      setCurrentSelection(filtered); // será usado para atualizar lado backend;
      setTimeout(() => {
        setAccess(filtered);
        toggleLoading();
      }, 100);
    },
    [colaborators, toggleLoading],
  );

  const handleChange = useCallback(
    (ev) => {
      const { stringed } = ev.currentTarget.dataset;

      const splitted = stringed.split('-');
      const module = splitted[0];
      const func = splitted[1];
      const voice = splitted[2];

      const { checked } = ev.currentTarget;

      setCurrentSelection((ex) => {
        const index = ex.findIndex(
          (item) => item.moduleId === module && item.funcId === func,
        );

        const currentInteraction = { ...ex[index] };

        switch (voice) {
          case 'read':
            currentInteraction.read = checked;
            break;
          case 'write':
            currentInteraction.write = checked;
            break;
          case 'gen':
            currentInteraction.gen = checked;
            break;
          case 'sen':
            currentInteraction.sen = checked;
            break;
          default:
            break;
        }
        ex[index] = { ...currentInteraction };
        return [...ex];
      });
    },
    [currentSelection],
  );

  const handleCancel = useCallback(() => {
    const { id } = currentSelection[0];
    const filtered = colaborators.filter((item) => item.id === id);
    setAccess(filtered);
    setHasSelection(false);
    setTimeout(() => {
      setHasSelection(true);
    }, 100);
  }, [currentSelection, colaborators]);

  const handleSave = useCallback(async () => {
    toggleLoading();
    try {
      const send = new FormData();
      send.append(
        'data',
        JSON.stringify({
          id: currentSelection[0].id,
          name: currentSelection[0].name,
          content: currentSelection,
        }),
      );
      send.append('save', 'save');
      await api.post('/system/acesso_actions.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setAccess((prev) => {
        const temp = [...prev];
        currentSelection.forEach((item) => {
          const index = temp.findIndex(
            (el) =>
              el.moduleId === item.moduleId &&
              el.funcId === item.funcId &&
              el.id === item.id,
          );

          temp[index] = { ...item };
        });
        console.table(prev);
        console.table(temp);
        return [...temp];
      });

      setColaborators((prev) => {
        const temp = [...prev];
        currentSelection.forEach((item) => {
          const index = temp.findIndex(
            (el) =>
              el.moduleId === item.moduleId &&
              el.funcId === item.funcId &&
              el.id === item.id,
          );

          temp[index] = { ...item };
        });
        console.table(prev);
        console.table(temp);
        return [...temp];
      });
    } catch (err) {
      console.log(err);
    } finally {
      toggleLoading();
    }
  }, [currentSelection, getColaborators]);

  return (
    <Container screenBanner={<div>Gestão de Acessos</div>}>
      <FilterWrapper>
        <select
          style={{ width: '360px' }}
          onChange={handleColaboratorSelection}
          data-filterby="colaborator"
        >
          <option value="">Selecione um usuário</option>
          {availableColaborators.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </FilterWrapper>

      {hasSelection ? (
        <fieldset disabled={!state.write}>
          <ButtonContainer>
            <Button bgcolor="#c53030" onClick={handleCancel}>
              Cancelar
            </Button>
            <Button bgcolor="#1b5e20" onClick={handleSave}>
              Salvar
            </Button>
          </ButtonContainer>
          <ModTableWrapper>
            <Table>
              <thead>
                <tr>
                  <td>Módulo</td>
                  <td>Funcionalidade</td>
                  <td>Consulta</td>
                  <td>Edição</td>
                  <td>Dados Sensíveis</td>
                </tr>
              </thead>
              <tbody>
                {access.map((item) => (
                  <tr key={`${item.id}::${item.moduleId}::${item.funcId}`}>
                    <td>{item.module}</td>
                    <td>{item.functionality}</td>
                    <td>
                      <input
                        type="checkbox"
                        defaultChecked={item.read}
                        data-stringed={`${item.moduleId}-${item.funcId}-read`}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        defaultChecked={item.write}
                        data-stringed={`${item.moduleId}-${item.funcId}-write`}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      {item.hassen ? (
                        <input
                          type="checkbox"
                          defaultChecked={item.sen}
                          data-stringed={`${item.moduleId}-${item.funcId}-sen`}
                          onChange={handleChange}
                        />
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ModTableWrapper>
        </fieldset>
      ) : null}
    </Container>
  );
}

Acesso.defaulProps = {};

export default Acesso;
