import React, { useCallback, useEffect, useState } from 'react';
import { Container } from 'components';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAssist } from 'pages/Assist/shared/state/assistThunk';
import { useLoading } from 'hooks';
import AssistList from 'pages/Assist/List';
import { Wrapper } from 'components/Container/styles';
import AssistFilter from 'pages/Assist/Filter';

import { useAuth } from 'hooks';
import { Assist as AssistInterface } from 'pages/Assist/shared/interfaces/assist';
import services from 'services';
import useAssistedStore from 'stores/useAssistedStore';

const Assist: React.FC = () => {
  const { state } = useLocation();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const assistedStore = useAssistedStore();

  // const assists = useSelector((state: any) => state.assist);
  const { toggleLoading, loading } = useLoading();
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('');

  const [assists, setAssists] = useState<any>();

  /* useEffect(() => {
    const fetchData = async () => {
      try {
        toggleLoading();
        await dispatch(fetchAssist() as any);
      } catch (error) {
        console.error('Error fetching ', error);
      } finally {
        toggleLoading();
      }
    };

    fetchData();
  }, [dispatch]); */

  const handleLoadItems = useCallback(async () => {
    try {
      toggleLoading();
      const assisteds = await assistedStore.find();
      console.log(assisteds);
      setAssists(assisteds);
    } catch (error) {
      console.error('Error fetching ', error);
    } finally {
      toggleLoading();
    }
  }, []);

  useEffect(() => {
    handleLoadItems();
  }, [handleLoadItems]);

  const filteredAssists = assists?.filter((assist: AssistInterface) => {
    const matchesSearch = assist.name
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const matchesStatus = !statusFilter || assist.situacao === statusFilter;
    return matchesSearch && matchesStatus;
  });

  return (
    <>
      <Container
        screenBanner={<div>Relação das Assistidas</div>}
        showIncludeButton={user?.adm || state.write}
      >
        <Wrapper>
          <AssistFilter
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
          />

          <AssistList assists={filteredAssists} />
        </Wrapper>
      </Container>
    </>
  );
};

export default Assist;
