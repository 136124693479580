import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from 'hooks';
import { Link } from 'react-router-dom';
import {
  HeaderWrapper,
  UserInfo,
  UserInfoDiv,
  Photo,
  Initials,
  HoverOverlay,
  PencilIcon,
} from './styles';

function Header() {
  const { user } = useAuth();
  const [selectedImage, setSelectedImage] = useState(
    `${process.env.REACT_APP_AVATAR}/${user.avatar}`,
  );
  const inputFileRef = useRef(null);

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append('avatar', file);

      try {
        const previewUrl = URL.createObjectURL(file);
        setSelectedImage(previewUrl);

        console.log(formData);
      } catch (error) {
        console.error('Erro ao atualizar o avatar:', error);
      }
    }
  };

  const handleClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const nameParts = user.name.split(' ');
  const initials = `${nameParts[0][0]}${nameParts[nameParts.length - 1][0]}`;

  return (
    <HeaderWrapper>
      <UserInfo>
        <UserInfoDiv>
          <h3>{user.name}</h3>
          <p>
            Área:&nbsp;<strong>{user.area}</strong>
          </p>
          <p>
            Função:&nbsp;<strong>{user.function}</strong>
          </p>
        </UserInfoDiv>
        <UserInfoDiv>
          <Photo>
            {selectedImage ? (
              <>
                <img src={`${selectedImage}`} alt={user.name} />
                <HoverOverlay onClick={handleClick}>
                  <PencilIcon />
                  <input
                    type="file"
                    ref={inputFileRef}
                    onChange={handleImageUpload}
                    style={{ display: 'none' }}
                    accept="image/*"
                  />
                </HoverOverlay>
              </>
            ) : (
              <Initials>{initials}</Initials>
            )}
          </Photo>
        </UserInfoDiv>
      </UserInfo>
    </HeaderWrapper>
  );
}

export default Header;
