import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  nivel: Yup.number().required('Nível de escolaridade é obrigatório'),
});

const CreateFormEducation = ({
  action,
  formData,
  setFormData,
  onUpdateFormStatus,
  handleTabChange,
  educationLevel,
  educationStatus,
  educationPeriod,
}) => {
  const [isStatusBlocked, setIsStatusBlocked] = useState([]);
  const [checkStatus, setCheckStatus] = useState('');

  const formik = useFormik({
    initialValues: formData,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setFormData((prevData) => ({
        ...prevData,
        educationData: values,
      }));
      // tem q verificar a questao do familiar
      // handleTabChange(4);
    },
  });

  const handleEducationDataValidation = () => {
    const requiredFields = ['nivel'];

    const emptyFields = requiredFields.filter((field) => !formik.values[field]);

    if (emptyFields.length > 0) {
      emptyFields.forEach((field) => {
        formik.setFieldError(field, 'Campo obrigatório');
        formik.setFieldTouched(field, true);
      });
    }
  };

  useEffect(() => {
    onUpdateFormStatus(formik.isValid, formik);
    setFormData(formik.values);
  }, [formik.isValid, formik.touched]);

  const handleEducationLevelChange = (e) => {
    const value = parseInt(e.target.value);
    formik.setFieldValue('nivel', value);
    formik.setFieldValue('situacao', '');
    setCheckStatus('');

    //	0 -cursando 1 - concludo 2- Incompleto

    if (value === 1) {
      setIsStatusBlocked([0, 1, 2]);
    } else if (value === 2 || value === 8 || value === 9) {
      setIsStatusBlocked([1]);
    } else if (
      value === 3 ||
      value === 4 ||
      value === 6 ||
      value === 7 ||
      value === 10
    ) {
      setIsStatusBlocked([0, 2]);
      setCheckStatus('Concluído');
      handleCheckboxChange('situacao', '1');
    } else if (value === 5) {
      setIsStatusBlocked([1]);
    }
  };

  const handleCheckboxChange = (name: string, value) => {
    formik.setFieldValue(name, value);
    handleEducationDataValidation();
  };

  const renderRadioFieldsetOther = (name, options) => {
    return (
      <div className="border border-gray-300 rounded-md p-4">
        <div className="flex flex-row">
          {options?.map((option) => {
            return (
              <label
                key={option.value}
                className="inline-flex items-center mt-2 ml-2 mr-3"
              >
                <input
                  disabled={action === 'show'}
                  type="radio"
                  id="extra"
                  name={name}
                  value={option.value}
                  checked={formik.values[name] === option.value}
                  onChange={() => handleCheckboxChange('extra', option.value)}
                  className="form-radio h-4 w-4 text-blue-600"
                />
                <span className="ml-2">{option.label}</span>
              </label>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="container-fadeIn">
      <form
        id="educationForm"
        onSubmit={formik.handleSubmit}
        className="space-y-4"
      >
        <div className="flex flex-wrap -mx-3  items-center">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 relative">
            <label className="block mb-1 text-gray-700">
              Nível de Escolaridade
            </label>
            <select
              disabled={action === 'show'}
              name="nivel"
              value={formik.values.nivel}
              onChange={handleEducationLevelChange}
              onBlur={formik.handleBlur}
              className={`border ${formik.touched.nivel && formik.errors.nivel ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
            >
              <option value="" label="Selecione o nível" />
              {educationLevel?.map((level) => (
                <option
                  key={level.value}
                  value={level.value}
                  label={level.label}
                />
              ))}
            </select>
            {formik.touched.nivel && formik.errors.nivel && (
              <div className="text-red-500 text">
                {String(formik.errors.nivel)}
              </div>
            )}
          </div>

          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 relative">
            <label className="block mb-1 text-gray-700">Situação</label>
            <div className="border border-gray-300 rounded-md p-4">
              <div className="flex flex-row">
                <label className="inline-flex items-center mt-2 ml-2 mr-3">
                  <input
                    type="radio"
                    name="situacao"
                    id="cursando"
                    value="0"
                    checked={
                      formik.values.situacao === '0' ||
                      checkStatus === 'Cursando'
                    }
                    onChange={(e) => handleCheckboxChange('situacao', '0')}
                    disabled={isStatusBlocked.includes(0) || action === 'show'}
                    className="form-radio h-4 w-4 text-blue-600"
                  />
                  <span className="ml-2">Cursando</span>
                </label>
                <label className="inline-flex items-center mt-2 ml-2 mr-3">
                  <input
                    type="radio"
                    name="situacao"
                    id="concluido"
                    value="1"
                    checked={
                      formik.values.situacao === '1' ||
                      checkStatus === 'Concluído'
                    }
                    onChange={(e) => handleCheckboxChange('situacao', '1')}
                    disabled={isStatusBlocked.includes(1) || action === 'show'}
                    className="form-radio h-4 w-4 text-blue-600"
                  />
                  <span className="ml-2">Concluído</span>
                </label>
                <label className="inline-flex items-center mt-2 ml-2 mr-3">
                  <input
                    type="radio"
                    name="situacao"
                    id="incompleto"
                    value="2"
                    checked={
                      formik.values.situacao === '2' ||
                      checkStatus === 'Incompleto'
                    }
                    onChange={(e) => handleCheckboxChange('situacao', '2')}
                    disabled={isStatusBlocked.includes(2) || action === 'show'}
                    className="form-radio h-4 w-4 text-blue-600"
                  />
                  <span className="ml-2">Incompleto</span>
                </label>
              </div>
            </div>
            {formik.touched.situacao && formik.errors.situacao && (
              <div className="text-red-500 text">
                {String(formik.errors.situacao)}
              </div>
            )}
          </div>
        </div>
        <div className="col-span-1">
          <label className="block mb-1 text-gray-700">
            Nome da Última (ou Atual) Instituição de Ensino
          </label>
          <input
            disabled={action === 'show'}
            type="text"
            name="ultinstit"
            placeholder="Nome da Instituição"
            value={formik.values.ultinstit}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`border ${formik.touched.ultinstit && formik.errors.ultinstit ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
          />
          {formik.touched.ultinstit && formik.errors.ultinstit && (
            <div className="text-red-500 text">
              {String(formik.errors.ultinstit)}
            </div>
          )}
        </div>

        <div className="col-span-1">
          <label className="block mb-1 text-gray-700">
            Período das Aulas Escolares
          </label>
          <div className="w-full md:w-1/2 mb-6 md:mb-0 relative">
            <div className="border border-gray-300 rounded-md p-4">
              <div className="flex flex-row">
                {educationPeriod?.map((option) => (
                  <label
                    key={option.value}
                    className="inline-flex items-center mt-2 ml-2 mr-3"
                  >
                    <input
                      disabled={action === 'show'}
                      type="radio"
                      name="periodo"
                      id={`periodo-${option.value}`}
                      value={option.value}
                      checked={formik.values.periodo === option.value}
                      onChange={(e) =>
                        handleCheckboxChange('periodo', option.value)
                      }
                      className="form-radio h-4 w-4 text-blue-600"
                    />
                    <span className="ml-2">{option.label}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-1">
          <label className="block mb-1 text-gray-700">Curso Extra</label>
          <textarea
            disabled={action === 'show'}
            name="dadosextra"
            rows={4}
            placeholder="Descrição do Curso Extra"
            value={formik.values.dadosextra}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`border ${formik.touched.dadosextra && formik.errors.dadosextra ? 'border-red-500' : 'border-black'} bg-gray-100 rounded-md px-4 py-2 w-full`}
          />
          {formik.touched.dadosextra && formik.errors.dadosextra && (
            <div className="text-red-500 text">
              {String(formik.errors.dadosextra)}
            </div>
          )}
        </div>

        <div className="col-span-1">
          <label className="block mb-1 text-gray-700">
            Situação do Curso Extra
          </label>
          {renderRadioFieldsetOther('extra', educationStatus)}
        </div>

        {(action === 'edit' || action === 'new') && (
          <div className="flex justify-end mt-4">
            <button
              type="submit"
              className="mt-4 bg-blue-500 text-white font-bold py-2 px-4 rounded mr-3"
              onClick={handleEducationDataValidation}
            >
              Validar Dados de Escolaridade
            </button>
          </div>
        )}
        {!formik.isValid && (
          <div className="text-red-500 font-bold mt-2 flex justify-end">
            Preencha os campos obrigatórios.
          </div>
        )}
      </form>
    </div>
  );
};

export default CreateFormEducation;
