import styled, { css } from 'styled-components';

interface ContentProps {
  disableLinks?: boolean;
}

interface MenuProps {
  quantity?: number;
  adm?: boolean;
}

export const NavbarWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 100vw;
  background-color: #2d517a;
  background-color: #0054a6;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
`;

export const NavContent = styled.section<ContentProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;

  column-gap: 35px;
`;

export const Menu = styled.div<MenuProps>`
  --baseColor: #2d517a;
  --baseColor: #0054a6;
  z-index: 152;
  cursor: pointer;
  display: flex;
  align-items: center;
  min-height: 35px;
  max-height: 35px;

  padding: 0 5px;
  position: relative;
  transition: background-color 0.35s;

  h3 {
    cursor: pointer;
    color: #efefef;

    transition: color 0.35s;
  }

  span {
    position: absolute;
    top: 35px;
    left: -2px;
    height: 0px;
    width: 350px;

    overflow-x: hidden;
    overflow-y: auto;

    visibility: hidden;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */

    opacity: 0;
    /* opacity: 1; */
    pointer-events: none;
    background: #efefef;

    /* overflow: hidden; */
    transition: all 0.35s;
    border: 2px solid transparent;
    border-top: 0px;
    border-radius: 0 0 5px 5px;

    ${(props) =>
      props.quantity === 0 &&
      css`
        border-width: 0px;
      `}

    ${(props) =>
      props.adm &&
      css`
        @media screen and (max-width: 1205px) {
          width: 200px;
        }
      `}

    button {
      border: 0;
      background: transparent;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }

    a,
    button {
      line-height: 25px;
      padding: 0 5px;
      text-decoration: none;
      width: auto;

      color: var(--baseColor);

      transition: color 0.25s ease, background-color 0.25s, border-color 0.5s,
        visibility 0s;
      & + a,
      & + button {
        margin-top: 3px;
      }

      &:hover {
        opacity: 1;
        background: var(--baseColor);
        color: #fff;
      }
    }
  }

  &:hover {
    background: #efefef;

    h3 {
      color: var(--baseColor);
    }
    span {
      visibility: visible;
      border-color: var(--baseColor);
      height: calc(${(props) => props.quantity} * 28px);
      max-height: 510px;
      opacity: 1;
      pointer-events: all;
    }
  }
`;

export const LogoutContainer = styled.div`
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  column-gap: 25px;

  a {
    height: 100%;
    display: flex;
    align-items: center;
  }
  svg {
    cursor: pointer;
    opacity: 0.85;
    color: #efefef;
    height: 30px;
    width: 30px;

    transition: all 0.25s ease;

    &:hover {
      opacity: 1;
      filter: brightness(1.1);
      color: #fff;
    }
  }
`;

export const HomeContainer = styled.div`
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: absolute; */
  top: 50%;
  left: 10px;
  /* transform: translateY(-50%); */
  column-gap: 25px;

  a {
    height: 100%;
    display: flex;
    align-items: center;
  }
  svg {
    cursor: pointer;
    opacity: 0.85;
    color: #efefef;
    height: 30px;
    width: 30px;

    transition: all 0.25s ease;

    &:hover {
      opacity: 1;
      filter: brightness(1.1);
      color: #fff;
    }
  }
`;
export const GoBack = styled.button`
  position: absolute;
  top: 0;
  left: 15px;
  background: transparent;
  color: #efefef;
  font-weight: bold;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  will-change: color;
  transition: color 0.35s ease;

  svg {
    position: absolute;
    top: 50%;
    left: 1px;
    transform: translate(0, -45%);

    height: 20px;
    width: 20px;
    will-change: transform;
    transition: transform 0.35s ease;
  }

  &:hover {
    color: #fff;
    svg {
      transform: translate(-5px, -45%);
    }
  }
`;
