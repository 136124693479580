import styled, { css } from 'styled-components';
import { lighten } from 'polished';

interface LabelProps {
  customchecked?: boolean;
  isdisabled?: boolean;
}

const checkedColor = '#388e3c';
export const CheckboxLabel = styled.label<LabelProps>`
  --checkedColor: ${checkedColor};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 2px solid #332e2e;
  margin: 0 auto;

  border-radius: 3px;

  height: 20px;
  width: 20px;
  max-height: 20px;
  max-width: 20px;

  transition: background-color 0.35s ease;

  svg {
    color: white;
    opacity: 0;
    transform: scale(0);
    transition: all 0.35s ease;
  }
  input {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    width: 0px;
    height: 0px;

    &:disabled {
      cursor: not-allowed;
    }
  }

  &:hover {
    background-color: #dedede;
  }

  ${(props) =>
    props.customchecked &&
    css`
      background-color: var(--checkedColor);
      border-color: var(--checkedColor);
      &:hover {
        background-color: ${lighten(0.05, `${checkedColor}`)};
      }

      svg {
        opacity: 1;
        transform: scale(1);
      }
    `}

  ${(props) =>
    props.isdisabled &&
    css`
      cursor: not-allowed;
      filter: grayscale(0.9);
    `}
`;
