import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from 'react';

import { useCredentials, useToast } from 'hooks';
import { useLocation } from 'react-router-dom';

import api from 'services/api';
import { AppProps } from 'interfaces';

import { useServiceWorker } from '../serviceWorkerRegistrationHandler';

interface VersionContextData {
  checkVersion(): void;
}

const VersionContext = createContext<VersionContextData>(
  {} as VersionContextData,
);

function VersionProvider({ children }: AppProps) {
  const { addToast } = useToast();
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const { pathname } = useLocation();
  const { errorHandling } = useCredentials();

  const checkVersion = useCallback(async () => {
    try {
      const response = await api.get('/version/version.php');
    } catch (err) {
      errorHandling(err);
    }
  }, []);

  useEffect(() => {
    if (showReload && waitingWorker) {
      addToast({
        type: 'info',
        title: 'Nova versão disponível',
        description: 'A página será atualizada.',
        seconds: 3,
      });
      setTimeout(() => {
        reloadPage();
      }, 3000);
    }
  }, [waitingWorker, showReload]);

  useEffect(() => {
    checkVersion();
  }, [pathname]);

  return (
    <VersionContext.Provider
      value={{
        checkVersion,
      }}
    >
      {children}
    </VersionContext.Provider>
  );
}

function useVersion(): VersionContextData {
  const context = useContext(VersionContext);

  if (!context) {
    throw new Error('useCredentials must be used within an CredentialProvider');
  }

  return context;
}

export { VersionProvider, useVersion };
