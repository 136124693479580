import './style.css';


const AssistFilter = ({ searchQuery, setSearchQuery, statusFilter, setStatusFilter }) => {
  
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
      };
    
      const handleStatusChange = (event) => {
        setStatusFilter(event.target.value);
      };
    return (
        <div className="flex justify-center items-center mt-2">
       
          <div className="mr-8">
            <label htmlFor="search" className="mr-4">Pesquisar:</label>
            <input
              type="text"
              id="search"
              placeholder="Digite o nome"
              className="input-pesquisar border border-blue-500 rounded-md px-4 py-2"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
    
          <div>
            <label htmlFor="status" className="mr-4">Situação:</label>
            <select
              id="status"
              className="input-status border border-blue-500 rounded-md px-4 py-2"
              value={statusFilter}
              onChange={handleStatusChange}
            >
              <option value="">Todos</option>
              <option value="A">Ativos</option>
              <option value="T">Internas</option>
              <option value="I">Inativos</option>
            </select>
          </div>
        </div>
      );
}

export default AssistFilter;