import { create, StoreApi, UseBoundStore } from 'zustand';
import { persist } from 'zustand/middleware';
import services from '../services';

interface IAssistedStore {
  assisteds: any[];
  find: () => Promise<any[]>;
  findOne: (assistedId: string) => any;
}

const useAssistedStore: UseBoundStore<StoreApi<IAssistedStore>> = create(
  persist(
    (set, get) => ({
      assisted: {},
      assisteds: [],
      find: async () => {
        const cachedAssisteds =
          await services.indexedDB.getAllItems('assisted');

        if (cachedAssisteds.length > 0) {
          set({ assisteds: cachedAssisteds });
          return cachedAssisteds;
        }

        const { data } = await services.api.get('/system/assistida_list.php', {
          headers: {
            tipo: 'A',
          },
        });

        set({ assisteds: data });

        data?.xassist?.forEach((assisted) =>
          services.indexedDB.setItem('assisted', assisted.id, assisted),
        );

        return data.xassist;
      },
      findOne: (assistedId) => {
        const assisted =
          get().assisteds.find((assisted) => assisted.id === assistedId) ?? {};
        return assisted;
      },
    }),
    {
      name: 'assisted-store',
    },
  ),
);

export {};

export default useAssistedStore;
