export function convertSpecialChars(text: string): string {
  let code = text;
  code = code.replace(/#/gi, 'hashtag');
  code = code.replace(/%/gi, 'percent');
  code = code.replace(/&/gi, 'ecommercial');
  code = code.replace(/\$/gi, 'dolarsign');
  code = code.replace(/\\/gi, 'barrainvertida');
  code = code.replace(/>/gi, 'greaterthan');
  code = code.replace(/</gi, 'lessthan');
  code = code.replace(/'/gi, 'singlequote');
  code = code.replace(/`/gi, 'crase');
  code = code.replace(/~/gi, 'acentotil');
  code = code.replace(/"/gi, 'doublequote');
  code = code.replace(/;/gi, 'pontovirgula');
  code = code.replace(/:/gi, 'doispontos');
  return code;
}

export function deconvertSpecialChars(text: string): string {
  let code = text;

  code = code.replace(/hashtag/gi, '#');
  code = code.replace(/percent/gi, '%');
  code = code.replace(/ecommercial/gi, '&');
  code = code.replace(/dolarsign/gi, '$');
  code = code.replace(/barrainvertida/gi, '\\');
  code = code.replace(/greaterthan/gi, '>');
  code = code.replace(/lessthan/gi, '<');
  code = code.replace(/crase/gi, '`');
  code = code.replace(/acentotil/gi, '~');
  code = code.replace(/singlequote/gi, "'");
  code = code.replace(/doublequote/gi, '"');
  code = code.replace(/pontovirgula/gi, ';');
  code = code.replace(/doispontos/gi, ':');
  code = code.replace(/§/gi, ',');

  return code;
}

export function removeAcento(text: string): string {
  let code = text;
  code = code.replace(/[ÁÀÃÂ]/gi, 'a');
  code = code.replace(/[ÉÈÊ]/gi, 'e');
  code = code.replace(/[ÍÌÎ]/gi, 'i');
  code = code.replace(/[ÓÒÔÕ]/gi, 'o');
  code = code.replace(/[ÚÙÛÜ]/gi, 'u');
  code = code.replace(/[Ç]/gi, 'c');

  return code;
}

export function checkAddress(text: string): string {
  let address = text;

  address = removeAcento(address).trim().toLowerCase().replace(/\s/gi, '');

  return address;
}

export function sortIt(array: any[], key: string): any[] {
  return array.sort((a, b) =>
    removeAcento(a[key]).toLowerCase() > removeAcento(b[key]).toLowerCase()
      ? 1
      : removeAcento(b[key]).toLowerCase() > removeAcento(a[key]).toLowerCase()
      ? -1
      : 0,
  );
}
