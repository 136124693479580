import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import * as serviceWorkerRegistration from './serviceWorkerRegistrationHelper';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>
);

// serviceWorkerRegistration.register();
// document.addEventListener('invokeUnregister', (e) => {
//   alert('Uma nova versão está disponível.\nA página será atualizada.');
serviceWorkerRegistration.unregister();
// });
