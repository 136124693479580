import React from 'react';
import { Navigate, Route, Routes as ReactRoutes } from 'react-router-dom';

import NotFound from 'pages/common/404';

import {
  common,
  environment,
  finances,
  management,
  personal,
  social,
  storage,
  admin,
} from './routes';

import PrivateRoute from './PrivateRoute';

function Routes() {
  const routes = [
    ...common,
    ...environment,
    ...finances,
    ...management,
    ...personal,
    ...social,
    ...storage,
    ...admin,
  ];
  return (
    <ReactRoutes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Navigate replace to="/home" />} />
      {routes.map(({ path, component: Component, adminOnly }) => (
        <Route
          key={path}
          path={path}
          element={
            <PrivateRoute outlet={<Component />} adminOnly={adminOnly} />
          }
        />
      ))}
    </ReactRoutes>
  );
}

export default Routes;
