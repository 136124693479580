import React, { useCallback, useEffect, useState } from 'react';
import { MessageProps } from 'interfaces';

import { useAuth, useLoading, useCredentials } from 'hooks';
import { Container, ScreenBanner } from 'components';
import api from 'services/api';

import { Message, MessagesWrapper } from './styles';

interface HomeMessageProps extends Omit<MessageProps, 'status'> {
  fromTime: string;
  dueTime: string;
}

function Home() {
  const { user } = useAuth();
  const { errorHandling } = useCredentials();
  const { toggleLoading, loading } = useLoading();
  const [messages, setMessages] = useState<HomeMessageProps[]>([]);

  const handleGetList = useCallback(async () => {
    toggleLoading();
    try {
      setMessages([]);
      const response = await api.get('/system/mensagem_list.php');
      setMessages(response.data);
    } catch (err) {
      errorHandling(err);
    } finally {
      toggleLoading();
    }
  }, [errorHandling]);

  useEffect(() => {
    handleGetList();
  }, [handleGetList]);
  return (
    <Container screenBanner={<div>Mensagens</div>}>
      {loading ? null : messages.length === 0 ? (
        <h3 style={{ margin: '0 auto' }}>Não há mensagens no momento</h3>
      ) : (
        <MessagesWrapper>
          {messages?.map((item) => (
            <Message key={item.id}>
              <h3>
                {item.fromFormat} às {item.fromTime}
              </h3>
              <span
                dangerouslySetInnerHTML={{
                  __html: item.message,
                }}
              />
            </Message>
          ))}
        </MessagesWrapper>
      )}
    </Container>
  );
}

export default Home;
