import styled, { keyframes } from 'styled-components';
import { animated } from '@react-spring/web';
import { parseToRgb } from 'polished';

const loading = keyframes`

  0%{
    transform: translateY(0px) scale(1);
  }
  25%{
    transform: translateY(10px) scale(1.1);
  }
  50%{
    transform: translateY(-10px) scale(1.2);
  }
  100%{
    transform: translateY(0px) scale(1);
  }
`;

export const FullLoadingContainer = styled(animated.div)`
  --firstcolor: #666;
  --firstred: ${parseToRgb('#666').red};
  --firstgreen: ${parseToRgb('#666').green};
  --firstblue: ${parseToRgb('#666').blue};

  --secondcolor: #fff;
  --secondred: ${parseToRgb('#fff').red};
  --secondgreen: ${parseToRgb('#fff').green};
  --secondblue: ${parseToRgb('#fff').blue};

  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10004;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);

  h3 {
    color: var(--firstcolor);
    margin: 0 0 20px 0;
  }

  background: rgba(255, 255, 255, 0.75);

  > div {
    display: flex;

    span {
      display: flex;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 2px solid var(--firstcolor);
      box-shadow: 0px 0px 10px 2px
        rgba(var(--firstred), var(--firstgreen), var(--firstblue), 0.5);

      background-image: radial-gradient(
        rgba(255, 255, 255, 0.75),
        rgba(var(--secondred), var(--secondgreen), var(--secondblue), 0.1)
      );

      animation: ${loading} 2s infinite;

      &:nth-child(1) {
        /* animation-delay: 0.2s; */
      }
      &:nth-child(2) {
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation-delay: 0.4s;
      }
      & + span {
        margin: 0 0 0 20px;
      }
    }
  }
`;
