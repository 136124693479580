import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from 'hooks';


const AdminSignIn = () => {
    const shape = yup.object().shape({
        username: yup
            .string()
            .trim()
            .required('Campo obrigatório'),
        password: yup.string().trim().required('Senha obrigatória'),
    });

    const { signIn } = useAuth();
    const [submitting, setSubmitting] = useState(false);
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(shape),
    });
    const handleADMSign = useCallback(async (data) => {
        signIn({ cpf: data.username, pass: data.password, who: 'adm' });
    }, [signIn]);



    return (
        <form onSubmit={handleSubmit(handleADMSign)} className="flex flex-col space-y-4">
          <input
            {...register("username", { required: true })}
            placeholder="Usuário"
            disabled={submitting}
            className={`rounded-md border ${errors.username ? 'border-red-500' : 'border-gray-300'} p-2 focus:outline-none focus:border-blue-500`}
          />
          {errors.username && <p className="text-red-500 font-bold">{errors.username.message}</p>}
      
   
          <input
            name="password"
            type="password"
            {...register("password", { required: true })}
            placeholder="Senha"
            disabled={submitting}
            className={`rounded-md border ${errors.password ? 'border-red-500' : 'border-gray-300'} p-2 focus:outline-none focus:border-blue-500`}
          />
          {errors.password && <p className="text-red-500 font-bold">{errors.password.message}</p>}
      
          <button type="submit" disabled={submitting} className="rounded-md bg-blue-500 text-white py-2 px-4">
            Enviar
          </button>
        </form>
      );
}

export default AdminSignIn;