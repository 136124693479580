import styled, { css } from 'styled-components';

interface WrapperProps {
  error?: boolean;
}

export const SelectWrapper = styled.div<WrapperProps>`
  padding: 6px 0 0 0;
  height: 35px;
  width: 350px;

  p {
    min-height: 21px;
    margin: 0 0 3px 0;
    color: #464646;
    font-weight: 700;
  }

  select {
    display: flex;
    background: #f6f6f6;

    border: 2px solid #a6a6a6;
    border-radius: 5px;
    color: #332e2e;

    cursor: pointer;
    width: 100%;
    height: 100%;
    padding: 5px 5px;
    overflow: visible;

    font-family: 'Roboto Slab', serif;
    font-weight: 700;
    font-size: 14px;

    option {
      height: 40px;
      margin: 10px 0;
      font-family: 'Roboto Slab', serif;
      font-size: 16px;
      font-weight: 500;
      color: #332e2e;
      background: #efefef;

      &:checked {
        background: #3f80ab;
        color: #fff;
      }
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  ${(props) =>
    props.error &&
    css`
      select {
        border-color: #ff0000;
        background: #ffebee;
      }
    `}
`;
