import styled, { keyframes } from 'styled-components';
import { parseToRgb } from 'polished';

const BG = `${process.env.REACT_APP_IMPERIUM_ASSETS}/Imperium_Background_1920x1080.jpg`;

const logo = keyframes`
  from { opacity: 0; } to { opacity: 1; }
`;
const logoImage = keyframes`
  0%{
    transform: scale(0);
    filter: brightness(0.5);
  } 50% {
    transform: scale(1);
    filter: brightness(1);
  } 65% {
    transform: scale(1.1);
    filter: brightness(1.5);
  } 100% {
    transform: scale(1);
    filter: brightness(1);
  }
`;

export const Container = styled.div`
  position: relative;
  background: url(${BG});
  background-position: 50%;

  width: 100vw;
  height: 100vh;
  overflow: hidden;
  @media screen and (max-width: 500px) {
    /* Adjust styles for smaller screens */
  }
`;

export const Logo = styled.div`
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  /* position: relative; */
  width: 225px;
  height: 225px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.35s ease;
  overflow: hidden;
  margin: 0 auto 0 auto;

  animation: ${logo} 1s ease forwards;

  > span {
    filter: brightness(3.75);
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 1) 45%,
      rgba(255, 255, 255, 0) 70%
    );
  }

  @media (max-width: 500px) {
    margin: 100px auto 0 auto;
  }
`;

export const LogoImage = styled.img`
  z-index: 2;
  width: 110px;
  object-fit: contain;

  transform: scale(0);
  filter: brightness(1) blur(1px);

  animation: ${logoImage} 1s ease forwards;
  animation-delay: 0.3s;
`;

export const InfoContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0 20px 0;
  margin: 20px auto;
  width: 100vw;
  max-width: 350px;
  row-gap: 15px;
  /* height: 170px; */
  /* border: 5px solid #accbe0; */
  background-color: transparent;

  --red: ${parseToRgb('#464646').red};
  --green: ${parseToRgb('#464646').green};
  --blue: ${parseToRgb('#464646').blue};

  /* border-style: outset; */
  /* box-shadow: 3px 2px 10px 3px rgba(var(--red), var(--green), var(--blue), 0.75); */

  transition: border-radius 0.35s ease;

  > span {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    /* background: rgba(255, 255, 255, 0.5); */
    background: transparent;
    border-radius: 20px;
    /* border: 5px solid #fff; */
  }

  a,
  > p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    text-decoration: none;
    color: #fff;
    font-size: 20px;
  }

  a {
    bottom: 35px;
  }
  > p {
    bottom: -15px;
    font-size: 14px;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 0 auto;
  }

  @media screen and (max-width: 500px) {
    border-radius: 0px;
  }
`;

export const Banner = styled.div`
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translate(-50%, -50%);
  /* margin: 100px auto 0; */
  display: flex;
  width: 100vw;
  max-width: 500px;
  background: white;
  align-items: center;
  justify-content: center;
  background: transparent;

  text-shadow: 0px 0px 1px #1d4482;
  color: #fff;
  /* color: #00183a; */
  border-radius: 5px;
  /* background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.25),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.25),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  ); */
`;

export const ForgotButtonContainer = styled.div`
  display: flex;
  margin: 0 auto;

  button {
    border: 2px solid transparent;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: transparent;
    color: #d6d6d6;
    transition: 0.35s ease;
    &:hover {
      color: #fff;
      border-color: #fff;
    }
  }
`;
