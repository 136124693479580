import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import { IconBaseProps } from 'react-icons';
import { FaExclamationTriangle, FaEye, FaEyeSlash } from 'react-icons/fa';
import { Wrapper, Error, ViewPass } from './styles';

import * as M from './masks';

interface CustomProps {
  name: string;
  label?: string;
  containerstyle?: object;
  inputstyle?: object;
  ispass?: boolean;
  isdate?: boolean;
  istime?: boolean;
  ishidden?: boolean;
  ischeckbox?: boolean;
  disableerroricon?: boolean;
  icon?: React.ComponentType<IconBaseProps>;
  mask?:
    | 'phone'
    | 'cellphone'
    | 'currency'
    | 'cnpj'
    | 'cpf'
    | 'cep'
    | 'number'
    | 'onlyNumbers'
    | 'allowZero'
    | 'name'
    | 'alpha'
    | 'alphaExtra'
    | 'none';
}

type InputProps = JSX.IntrinsicElements['input'] & CustomProps;

function FormInput({
  name,
  label,
  containerstyle,
  inputstyle,
  disableerroricon,
  icon,
  ispass,
  isdate,
  istime,
  ishidden,
  ischeckbox,
  mask,
  ...rest
}: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [visible, setIsVisible] = useState(false);
  const [maskError, setMaskError] = useState('');

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = '';
      },
    });

    setIsFilled(!!inputRef.current?.value);
  }, [fieldName, registerField]);

  const handlePasswordVisibility = useCallback(() => {
    const check = inputRef.current;

    if (check) {
      if (check.type === 'password') {
        check.type = 'text';
      } else {
        check.type = 'password';
      }
    }

    setIsVisible(!visible);
  }, [visible]);

  const handleFocus = useCallback(() => {
    setMaskError('');

    // const check = inputRef.current;

    // if (check && isdate) {
    //   check.type = 'date';
    //   // check.click();
    // }

    // if (check && istime) {
    //   check.type = 'time';
    //   // check.click();
    // }

    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(async () => {
    const check = inputRef.current;
    setIsFocused(false);

    if (check) {
      if ((isdate || istime) && !check.value) {
        check.type = 'text';
      }
    }

    setIsFilled(!!inputRef.current?.value);
    const input = inputRef.current;

    if (input) {
      let message = '';
      if (input.value === '') {
        setMaskError('');
        return;
      }
      if (mask === 'cep') {
        if (input.value.length !== 9 && !!input.value) {
          input.value = '';
          message = 'É necessário os 8 digitos do CEP';
        }
      }

      if (mask === 'cnpj') {
        if (input.value.length < 14 && !!input.value) {
          input.value = '';
          message = 'É necessário os 18 digitos do CNPJ';
        }
      }

      if (mask === 'phone') {
        if (input.value.length < 14 && !!input.value) {
          input.value = '';
          message = 'Número de telefone inválido';
        }
      }

      setMaskError(message);
    }
  }, [mask, isdate, istime]);

  const maskIt = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (mask === 'none') {
        return;
      }

      if (mask === 'phone') {
        M.phoneMasking(e);
      }

      if (mask === 'currency') {
        M.currencyMasking(e);
      }

      if (mask === 'cnpj') {
        M.cnpjMasking(e);
      }

      if (mask === 'cpf') {
        M.cpfMasking(e);
      }

      if (mask === 'cep') {
        M.cepMasking(e);
      }

      if (mask === 'number') {
        M.numberMasking(e);
      }

      if (mask === 'onlyNumbers') {
        M.onlyNumbers(e);
      }

      if (mask === 'allowZero') {
        M.allowZeroMasking(e);
      }

      if (mask === 'name') {
        M.nameMasking(e);
      }

      if (mask === 'alpha') {
        M.alphaMasking(e);
      }

      if (mask === 'alphaExtra') {
        M.alphaMaskingExtra(e);
      }
    },
    [mask],
  );

  return (
    <Wrapper
      hasError={!!error}
      isFocused={isFocused}
      isFilled={isFilled}
      style={containerstyle}
      ishidden={ishidden}
      labelpresent={!!label}
    >
      {label && <label htmlFor={fieldName}>{label}</label>}
      <input
        style={inputstyle}
        name={name}
        // onFocus={handleFocus}
        // onBlur={handleBlur}
        defaultValue={defaultValue}
        ref={inputRef}
        onKeyUp={maskIt}
        {...rest}
      />
      {ispass && (
        <ViewPass>
          {visible ? (
            <FaEyeSlash size={20} onClick={handlePasswordVisibility} />
          ) : (
            <FaEye size={20} onClick={handlePasswordVisibility} />
          )}
        </ViewPass>
      )}
      {!disableerroricon ? (
        <>
          {error && (
            <Error title={error}>
              <FaExclamationTriangle color="#c53030" size={20} />
            </Error>
          )}
          {!error && maskError && (
            <Error title={maskError}>
              <FaExclamationTriangle color="#c53030" size={20} />
            </Error>
          )}
        </>
      ) : null}
    </Wrapper>
  );
}

FormInput.defaultProps = {
  label: null,
  containerstyle: {},
  inputstyle: {},
  mask: 'none',
  ischeckbox: false,
  ispass: false,
  isdate: false,
  istime: false,
  ishidden: false,
  disableerroricon: false,
  icon: null,
};
export default FormInput;
