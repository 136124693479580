import styled, { keyframes } from 'styled-components';
import { darken, lighten, parseToRgb } from 'polished';

const n404URL = `${process.env.REACT_APP_IMPERIUM_ASSETS}/misc/404-8cfda3b8e8c54ad48c34381dfca477f7.png`;

const moveBG = keyframes`
  from{
    background-position: 0px 0px;
  } to {
    background-position: 1920px 1080px;
  }
`;

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-image: url(${n404URL});
  animation: ${moveBG} 25s linear infinite;
`;

export const WrapperContent = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MessageContainer = styled.div`
  --bg: #bbdefb;
  --bgred: ${parseToRgb('#bbdefb').red};
  --bggreen: ${parseToRgb('#bbdefb').green};
  --bgblue: ${parseToRgb('#bbdefb').blue};

  --border: #e65100;
  --button: #0d47a1;
  /* bgcolor = parseToRgb('#efefef'); */
  /* position: relative;
  display: flex;
  flex-direction: column;
  place-items: center; */
  /* justify-content: center; */

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  background-color: rgba(var(--bgred), var(--bggreen), var(--bgblue), 0.5);
  height: 350px;
  width: 100%; /* Adjusted width for responsiveness */
  max-width: 800px; /* Added max-width for larger screens */
  margin: 0 auto; /* Center the container */

  border: 5px solid var(--border);
  border-radius: 5px;
  box-shadow: 0px 0px 2px 2px var(--border), inset 0px 0px 2px 2px var(--border);
  padding: 20px 30px;

  color: #fff;

  h2 {
    font-size: 72px;
  }

  h2,
  p {
    text-shadow: 2px 0 10px #ff9800;
  }

  span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      font-size: 26px;
      &:nth-child(1) {
        font-size: 40px;
      }
    }
  }

  button {
    /* margin-top: 20px; */
    color: #efefef;
    padding: 10px 35px;
    font-size: 22px;

    background: var(--button);
    border: 2px solid var(--button);
    border-radius: 5px;
    transition: all 0.25s ease-in-out;

    &:hover {
      background: ${darken(0.2, '#0d47a1')};
      border-color: ${darken(0.2, '#0d47a1')};
      color: var(--border);
    }
  }
`;
